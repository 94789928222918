/* @partial node-pages */

.node-unpublished { /* Unpublished nodes */
  /* background-color: #fff4f4; */ /* Drupal core uses a #fff4f4 background */

  p.unpublished { /* The word "Unpublished" displayed underneath the content. */
    @include unpublished-div;
  }
}

.field-name-field-pc-tagline{
  text-transform: uppercase;
}
.field-name-body{
  margin: $vert-gutter / 2 0px;
}

