$diameter-large: 150px;
$diameter-inner-large: 142px;
$padding-large: 50px;
$margin-large: 20px;
$offset: 3px;

$diameter-small: 75px;
$diameter-inner-small: 67px;
$padding-small: 30px;
$margin-small: 10px;

.front{
  #solutions{
    min-height: 500px;
    overflow: hidden;
    background: none;

    .copy{
      max-width: 800px;
      margin: 0px auto;
      padding: 0px;

      .title,
      .caption{
        padding: 0px $vert-gutter 0px $vert-gutter;
      }
    }
    .title{
      h1{
        @include breakpoint($phone-min){
          line-height: 55px;

          &:after{
            content: ' ';
            position: absolute;
            width: 50%;
            height: 0px;
            box-shadow: black 0px 0px 100px 15px;
            z-index: -1;
            left: 25%;
          }
        }
      }
    }

    .links{
      width: 300px;
      margin: 0px auto;
      
      @include breakpoint($tablet-small-min){
        width: 580px;
      }

      li{
        float: left;
        width: $diameter-small;
        height: $diameter-small;
        margin: $margin-small;

        @include breakpoint($tablet-small-min){
          width: $diameter-large;
          height: $diameter-large;
          margin: $margin-large;
        }

        @include border-radius(50%);
        display: block;
        position: relative;
        border: 1px solid rgba(255, 255, 255, 0.7);

        &.first{
          border: 1px solid rgba(232, 179, 60, 0.7);

          a{
            background: rgba(232, 179, 60, 0.7);
            &:hover{
              background: rgba(232, 179, 60, 0.9);
            }
          }
        }

        a{
          width: $diameter-inner-small;
          height: $diameter-inner-small;
          top: $offset;
          left: $offset;

          @include breakpoint($tablet-small-min){
            width: $diameter-inner-large;
            height: $diameter-inner-large;
          }

          @include border-radius(50%);
          @include single-transition(background, 0.2s, ease-in);

          position: absolute;
          display: table;
          text-align: center;
          margin: 0px;
          color: $body-font-color;
          background: rgba(255, 255, 255, 0.7);
          line-height: 20px;
          
          &:hover{
            background: rgba(255, 255, 255, 0.9);
          }

          div{
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
