$breakpoint-video-slider-desktop: $tablet-portrait-min;
$breakpoint-video-slider-mobile:  $tablet-portrait-max;

.home-video-slider {
    position: relative;
    color: $yellow;
    margin: 0;

    .block {
        margin: 0;
    }

    video {
        display: block;
    }

    .field-name-field-industry-home-description {
        color: #fff;
    }
}

.industry-video {
    @include intrinsicRatioContainer(1280px, 720px); // 16x9 resolution
    video {
        @include intrinsicRatioElement();
    }
}

/////////////////////////////////////////////////////////////////////
// Menu
/////////////////////////////////////////////////////////////////////

.industries-menu {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 5%;
    width: 25%;
    bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.industries-menu 
.industries-slogan p {
    font-size: 16px;
}

.industries-menu-list {
    margin: 0;
    padding: 0;
    
    a {
        @include border-style-button($border-color: #ffffff, $font-color: $yellow, $font-size: 13px, $icon-font-size: 20px);
        width: 100%;

        background: linear-gradient(to right, white 50%, transparent 50%);
        background-size: 200% 100%;
        background-position: right bottom;
        transition: none;
        font-size: 16px;
        font-family: "TradeGothicLTStd-Extended";
        padding: 12px 80px 8px 12px;
        background-color: rgba(0,0,0,0.3);
        &:after {
            top: 7px;
        }
        &.is-active-video {
            transition: all 0.2s ease-out;
            @include breakpoint($breakpoint-video-slider-mobile) { // Mirror in JS for sliders mobile presentation
                background-color: #ffffff;
            }

            @include breakpoint($breakpoint-video-slider-desktop) { // Mirror in JS for sliders mobile presentation
                transition-duration: 5s;
                background-position:left bottom;
            }
        }
    }
    
    li {
        list-style-type: none;
        list-style-image: none;
    }

    a {

    }
}

.industries-slogan {
    color: #ffffff;
}

/////////////////////////////////////////////////////////////////////
// Description & Video slide
/////////////////////////////////////////////////////////////////////

.industry-description {
    position: absolute;
    top: 0;
    left: 5%;
    width: 50%;
    bottom: 100px;
    display: flex;
    align-items: center;
    z-index: 1;

    h2 {
        margin: 0;
        font-size: 25px;
    }

    a {
        @include border-style-button;
        font-size: 16px;
        padding: 10px 80px 8px 12px;
        margin-top: 30px;
        font-family: "TradeGothicLTStd-Extended";
        &:after {
            top: 4px;
        }
    }

    .field-name-field-industry-home-description {
        text-transform: uppercase;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 15px;
        margin-top: 15px;
    }
}

.industry-description-inner {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 50px;
}

.industry-background {
    width: 100%;
}

.home-video-slider {
    background-color: #000000;

    .field-name-field-industry-home-fallback-img {
        @include breakpoint($breakpoint-video-slider-desktop) { // Mirror in JS for sliders mobile presentation
            display: none;
        }
    }

    video {
        display: none;
        @include breakpoint($breakpoint-video-slider-desktop) { // Mirror in JS for sliders mobile presentation
            display: block;
        }
    }
}

.industries-list-item {
    // display: none;

    // &:nth-child(5) {
    //     display: block; 
    // }
}
