#autocomplete {
  border-color: #cfcfcf;

  ul {
    background: #ebebeb;
    padding: 0.5em 0;
  }

  li {
    background: none;
    color: #000000;
    padding: $input-padding;
  }
}
