

.header {
    position: relative;
    .block {
        margin-bottom: 0;
    }
}


.header-wrapper {
  background-color: #ffffff;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
  padding: $vert-gutter 0;

  a {
    color: $body-font-color;

    &:hover{
      color: $link-color;
    }
  }
  .search-activate {
     right: 24px;
  }

  .header-title-wrapper{
    .header-title,
    .tagline{
      text-align: center;
    }

    .tagline{
      font-size: $h2-font-size * 0.8;
      color: $white;
      font-weight: 100;
    }
  }
}

.logo {
  display: block;
  margin: 0 auto;
  width: 170px;


  img {
    width: 100%;

    &.inverted {
      display: none;
    }
  }
}

.header-image {
  overflow: hidden;
  height: 400px;
  &.header-image-long{
    height: 500px;
  }

  background: #333;
  $svg-gradient-shim-threshold: 1 !global;
  @include background-image(linear-gradient(120deg, #ccc, #333));

  img {
    /* Set rules to fill background */
    min-height: 100%;

    /* Set up proportionate scaling */
    width: 100%;
    height: auto;

    /* Seet up positioning */
    position: relative;
    top: 0;
    left: 0;
    min-width: 480px;
    left: 50%;
    margin-left: -240px;   /* 50% */
  }

  .dont-use-yet{
    @media screen and (min-width: 481px) and (max-width: 768px) { /* Specific to this particular image */
      img{
        min-width: 768px;
        left: 50%;
        margin-left: -384px;   /* 50% */
      }
    }
    @media screen and (min-width: 769px) and (max-width: 980px) { /* specific to this particular image */
      img{
        min-width: 980px;
        left: 50%;
        margin-left: -490px;   /* 50% */
      }
    }
    @media screen and (min-width: 981px) and (max-width: 1400px) { /* specific to this particular image */
      img{
        min-width: 1400px;
        left: 50%;
        margin-left: -700px;   /* 50% */
      }
    }
  }
  @media screen and (max-width: 1400px) { /* specific to this particular image */
    img{
      min-width: 1400px;
      left: 50%;
      margin-left: -700px;   /* 50% */
    }
  }

}

.secondary-menu {
  float: left;
}

#action-menus-wrapper{
  position: relative;
  z-index: 20;
  @include single-transition(opacity, 0.3s, ease-in-out);

  .show-search &{
    @include opacity(0);
  }

  #action-menus{
    position: absolute;
    left: 0px;
    top: 0;
  }
}

.menu-secondary-links {
    position: absolute;
    right: 24px;
    top: 0;

    .menu {
        li {
            display: inline-block;
            list-style-type: none;
            text-transform: uppercase;

            &:first-child:after {
                content: '|';
                display: inline-block;
                margin: 0 1em;
            }
        }
        a {
            @include theme-link($inactive-color: $black, $active-color: $yellow);
        }
    }
}

.secondary-menu .links a {
    @include theme-link($inactive-color: $black, $active-color: $yellow);
}

.show-nav .secondary-menu .links a {
  color: $yellow;
}

@media (max-width: 1140px){
    #action-menus-wrapper #action-menus {
      left: 24px;
    }
}

#search-button {
  .icon {
    font-size: 24px;
    color: $yellow;
  }
}

#search-close {
  position: absolute;
  right: 30px;
  top: 30px;
  border: none;
  padding: 0;
  background: rgba(255,255,255,0.75);
  font-size: 32px;
  border-radius: 50%;
  
  @include breakpoint($phone-min) {
    font-size: 36px;
  }
  @include breakpoint($tablet-small-min) {
    font-size: 40px;
  }
  @include breakpoint($desktop-min) {
    font-size: 46px;
  }
}