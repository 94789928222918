.footer-wrapper {
    background: #ffffff url('../images/armstrong-footer-bg-logo.jpg') no-repeat left top;
    background-size: contain; 
    border-top: 1px solid #f0f0f0;
    color: $black;
    padding: ($vert-gutter*2) 0;
    overflow: hidden;
    margin-top: $vert-gutter * 2;

    .front & {
        margin-top: 0px;
    }
}

.footer {

    .block-1 {
        // color: #ccc;
    //   @include breakpoint($tablet-portrait-min){
    //     @include float-span(6);
    //   }
    }

    .menu-menu-footer-menu{
        ul.menu {
            li {
                // @include rem('font-size', 22px);
                // // line-height: 30px;
                text-align: center;

                &.last {
                    margin-top: 50px;
                    // @include rem('font-size', 14px);
                }

                @include breakpoint($tablet-portrait-min){
                    text-align: left;
                    @include rem('font-size', 22px);
                    // line-height: 30px;

                    &.last {
                        margin-top: 50px;
                        @include rem('font-size', 14px);
                    }
                }
            }
        }
    }

    .copy-copyright {
        margin-bottom: $vert-gutter/4;
    }

    a {
        color: $yellow;
        text-transform: uppercase;
    }

    .block {
        margin: 0px;
    }
}


.region-footer-secondary {
    @include breakpoint($tablet-portrait-min){
        @include float-span(10);
    }
}

.region-footer-tertiary {
    @include breakpoint($tablet-portrait-min){
        @include float-span(14);
    }
    .region-inner {
        @include breakpoint($tablet-portrait-min){
            padding-left: 120px; 
        }    
    }
}

.slogan {
    margin-bottom: $vert-gutter;
}

.menu-menu-social-media {
    .menu {
        li {
            display: inline-block;
            margin-right: 8px;
        }
    }

    .icon-replace {
        font-size: 0;
        display: inline-block;
        border: 1px solid $black;
        padding: 4px;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        line-height: 28px;
        text-align: center;

        &:before {
            color: $black;
        }

        &:hover, &:focus, &:active {
            text-decoration: underline;
            color: $yellow;
            border-color: $yellow;

            &:before {
                color: $yellow;
            }
        }
    }
}


.footer-logo {
  margin-bottom: $vert-gutter/2;
}