/* @partial page-layout */

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}
  
*,
*:before,
*:after {
  @include box-sizing(border-box);
}

#header,
#main,
.footer,
#action-menus-wrapper,
.region-content-top,
.region-navigation,
.region-content-bottom,
.region-bottom,
.layout-container {
  margin-left: auto;
  margin-right: auto;
  max-width: $layout-max;
}

#page {
  min-width: $layout-min;
}

#header,
#content,
.region-content-bottom,
.region-content-top,
.region-sidebar-first,
.region-sidebar-second,
.footer,
.layout-container {
  padding-left: $horiz-gutter/2;
  padding-right: $horiz-gutter/2;
}

.block {
  margin-bottom: $vert-gutter;
  margin-top: 0;
}

/*
 * Containers for grid items and flow items.
 */

#header,
#main,
.region-content-bottom,
.region-content-top,
.region-top,
.region-highlighted {
  @include micro-clearfix;
}

.region-highlighted {
  position: relative;
  margin-bottom: $vert-gutter;

  .not-front {
    padding-top: 200px;
  }
}



.region-content-top,
.region-content-bottom{
  clear: both;
}

.region-sidebar-first,
.region-sidebar-second { 
  display: none;
}

 /* PHONE (min-width: 480px)
    ========================================================================== */

@include breakpoint($phone-min) {
}


/* TABLET (min-width: 600px to 960px)
   ========================================================================== */


@include breakpoint($tablet-small-min $desktop-max) {
  .region-sidebar-first,
  .region-sidebar-second { 
    display: block;
  }

  /*
   * The layout when there is only one sidebar, the left one.
   */

  .sidebar-first {
    #content {
      @include grid-span(16, 9);
    }

    .region-sidebar-first { 
      @include grid-span(8, 1);
    }
  }

  /*
   * The layout when there is only one sidebar, the right one.
   */

  .sidebar-second {
    #content { 
     @include grid-span(16, 1);
    }

    .region-sidebar-second {
      @include grid-span(8, 17);
    }
  }

  /*
   * The layout when there are two sidebars.
   */

  .two-sidebars {
    #content { 
      @include grid-span(16, 9);
    }

    .region-sidebar-first { 
      @include grid-span(8, 1);
    }

    .region-sidebar-second { 
      @include grid-span(24, 1);
    }
  }
}


/* DESKTOP (min-width: 960px+)
  ========================================================================== */


@include breakpoint($desktop-min) {
  .region-sidebar-first,
  .region-sidebar-second { 
    display: block;
  }
  
  /*
   * The layout when there is only one sidebar, the left one.
   */

  .sidebar-first {
    #content { 
      @include grid-span(18, 7);
    }

    .region-sidebar-first { 
      @include grid-span(4, 1);
    }
  }

  /*
   * The layout when there is only one sidebar, the right one.
   */

  .sidebar-second {
    #content { 
      @include grid-span(18, 1);
    }

    .region-sidebar-second { 
      @include grid-span(4, 21);
    }
  }

  /*
   * The layout when there are two sidebars.
   */

  .two-sidebars {
    #content { 
      @include grid-span(12, 7);
    }

    .region-sidebar-first { 
      @include grid-span(4, 1);

    }

    .region-sidebar-second { 
      @include grid-span(4, 21);
    }
  }
}
