@charset "UTF-8";
/* Warning: ie8 sees this code with respond.js. Add ie8 specific 1x images back in.
Example with conditional class found on html element (html.tpl)
@include retina-query {
  .logo {
    background-image: url( ....@2x.png);
    background-size: XXpx XXpx;
  }
  .ie-8 .logo {
    background-image: url( ....@1x.png);
  }
}
 */
/* @partial normalize */
/* HTML5 display definitions
   ========================================================================== */
/*
 * Corrects block display not defined in IE6/7/8/9 & FF3
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}

/*
 * Corrects inline-block display not defined in IE6/7/8/9 & FF3
 */
audio,
canvas,
video {
  display: inline-block;
}

/*
 * Prevents modern browsers from displaying 'audio' without controls
 * Remove excess height in iOS5 devices
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/*
 * Addresses styling for 'hidden' attribute not present in IE7/8/9, FF3, S4
 * Known issue: no IE6 support
 */
[hidden] {
  display: none;
}

/* Address styling not present in IE 8/9, Safari 5, and Chrome. */
abbr[title] {
  border-bottom: 1px dotted;
}

/* Address style set to `bolder` in Firefox 4+, Safari 5, and Chrome. */
b,
strong {
  font-weight: bold;
}

/* Address styling not present in Safari 5 and Chrome. */
dfn {
  font-style: italic;
}

/* Address differences between Firefox and other browsers. */
hr {
  box-sizing: content-box;
  height: 0;
  border: 1px solid #666666;
  padding-bottom: -1px;
  margin: 30px 0;
}

/* Address styling not present in IE 8/9. */
mark {
  background: #ffff00;
  color: #000000;
}

/* Correct font family set oddly in Safari 5 and Chrome. */
code,
kbd,
pre,
samp,
tt,
var {
  font-family: monospace, "Courier New", "DejaVu Sans Mono", sans-serif;
  font-size: 1em;
  line-height: 1.3846153846em;
}

/* Improve readability of pre-formatted text in all browsers. */
pre {
  white-space: pre-wrap;
}

/* Set consistent quote types. */
q {
  quotes: "“" "”" "‘" "’";
}

/* Address inconsistent and variable font size in all browsers. */
small {
  font-size: 80%;
}

/* Prevent `sub` and `sup` affecting `line-height` in all browsers. */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Base
   ========================================================================== */
/*
 * Addresses margins handled incorrectly in IE6/7
 */
body {
  margin: 0;
  padding: 0;
  color: #3c3b3b;
  line-height: 1.3846;
}

/* Links
   ========================================================================== */
/*
 * The order of link states are based on Eric Meyer's article:
 * http://meyerweb.com/eric/thoughts/2007/06/11/who-ordered-the-link-states
 */
a {
  color: #e8b33c;
  text-decoration: none;
  -webkit-transition-property: compact(color, false, false, false, false, false, false, false, false, false);
  -moz-transition-property: compact(color, false, false, false, false, false, false, false, false, false);
  -o-transition-property: compact(color, false, false, false, false, false, false, false, false, false);
  transition-property: compact(color, false, false, false, false, false, false, false, false, false);
  -webkit-transition-duration: compact(0.1s, false, false, false, false, false, false, false, false, false);
  -moz-transition-duration: compact(0.1s, false, false, false, false, false, false, false, false, false);
  -o-transition-duration: compact(0.1s, false, false, false, false, false, false, false, false, false);
  transition-duration: compact(0.1s, false, false, false, false, false, false, false, false, false);
  -webkit-transition-timing-function: compact(ease-in, false, false, false, false, false, false, false, false, false);
  -moz-transition-timing-function: compact(ease-in, false, false, false, false, false, false, false, false, false);
  -o-transition-timing-function: compact(ease-in, false, false, false, false, false, false, false, false, false);
  transition-timing-function: compact(ease-in, false, false, false, false, false, false, false, false, false);
}

a:hover {
  color: #e5aa25;
  text-decoration: none;
  /* ie8 */
}

a:focus {
  color: #d89d19;
  text-decoration: none;
  /* ie8 */
}

a:active {
  color: #d89d19;
  text-decoration: none;
}

/*
 * Addresses outline displayed oddly in Chrome
 */
a:focus {
  outline: thin dotted;
}

/*
 * Addresses outline displayed oddly in Chrome
 */
a:focus {
  outline: thin dotted;
}

/*
 * Improves readability when focused and also mouse hovered in all browsers
 * people.opera.com/patrickl/experiments/keyboard/test
 */
a:hover,
a:active {
  outline: 0;
}

/* Input placeholder color
  ========================================================================== */
::-webkit-input-placeholder {
  color: #999999;
}

::-webkit-input-placeholder * {
  -webkit-transition: compact(compact(all 0.15s ease, false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(all 0.15s ease, false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(all 0.15s ease, false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(all 0.15s ease, false, false, false, false, false, false, false, false, false);
}

::-webkit-input-placeholder:focus {
  color: #fff;
}

:focus::-webkit-input-placeholder {
  color: #fff;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #999999;
}

:-moz-placeholder:focus {
  color: #fff;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #999999;
}

::-moz-placeholder:focus {
  color: #fff;
}

:-ms-input-placeholder {
  color: #999999;
}

:-ms-input-placeholder:focus {
  color: #fff;
}

/* Text highlight color
  ========================================================================== */
::selection {
  background: #e8b33c;
  /* Safari */
  color: white;
}

::-moz-selection {
  background: #e8b33c;
  /* Firefox */
  color: white;
}

/* Lists
   ========================================================================== */
/*
 * Addresses margins set differently in IE6/7
 */
dl,
menu,
ol,
ul {
  margin: 30px 0;
}

ul.inline {
  /* List of links generated by theme_links(). @Drupal override */
  display: inline;
  padding: 0;
}

ul.inline li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
  /* LTR */
}

.item-list ul li {
  /* @Drupal override */
  margin: 0;
}

dd {
  margin: 0 0 0 27px;
  /* LTR */
}

ul ul,
ul ol,
ol ul,
ol ol,
.item-list ul ul,
.item-list ul ol,
.item-list ol ul,
.item-list ol ol {
  margin: 0;
  /* Turn off margins on nested lists. */
}

/*
 * Addresses paddings set differently in IE6/7
 */
menu,
ol,
ul,
.item-list ul {
  padding: 0 0 0 27px;
  /* LTR */
}

/*
 * Corrects list images handled incorrectly in IE7
 */
nav ul,
nav ol, .menu-block-wrapper, ul.links, ul.menu {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

/* Embedded content
   ========================================================================== */
/**
 * Embedded content and figures
 *
 * @todo Look into adding responsive embedded video.
 */
img {
  /* Remove border when inside `a` element in IE 8/9. */
  border: 0;
  /* Suppress the space beneath the baseline */
  vertical-align: bottom;
  /* Responsive images */
  max-width: 100%;
  height: auto;
}

.ie8 img {
  max-width: none;
  /* ie8 needs "width: auto;" and "max-width: none;" for ie8 only */
  width: auto;
}

/* Responsive video embed. Youtube / Vimeo */
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*
 * Corrects overflow displayed oddly in IE9
 */
svg:not(:root) {
  overflow: hidden;
}

/* Figures
   ========================================================================== */
/*
 * Addresses margin not present in IE6/7/8/9, S5, O11
 */
figure {
  margin: 0;
}

/* Accessibility features
 ========================================================================== */
/* element-invisible as defined by http://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
.element-invisible,
.element-focusable {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

/* Turns off the element-invisible effect. */
.element-focusable:active, .element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

/* Touch specific styles
   ========================================================================== */
a, input, button {
  -ms-touch-action: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Behavior
   ========================================================================= */
.js #navigation {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: compact(compact(compact(opacity 0.3s ease-in false), false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(compact(opacity 0.3s ease-in false), false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(compact(opacity 0.3s ease-in false), false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(compact(opacity 0.3s ease-in false), false, false, false, false, false, false, false, false, false);
  visibility: hidden;
}

.show-nav #navigation {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  visibility: visible;
}

#toggle-nav {
  position: absolute;
  top: 40px;
  left: 0px;
  z-index: 10;
}

#toggle-nav .bar {
  background: #3c3b3b;
  -webkit-transition: compact(compact(background 0.3s ease-in, false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(background 0.3s ease-in, false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(background 0.3s ease-in, false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(background 0.3s ease-in, false, false, false, false, false, false, false, false, false);
}

.show-nav #toggle-nav .bar,
#toggle-nav:hover .bar {
  background: #e8b33c;
}

#toggle-nav .text {
  color: #e7b820;
  -webkit-transition: compact(compact(color 0.3s ease-in, false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(color 0.3s ease-in, false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(color 0.3s ease-in, false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(color 0.3s ease-in, false, false, false, false, false, false, false, false, false);
  text-transform: uppercase;
  color: #3c3b3b;
}

.show-nav #toggle-nav .text,
#toggle-nav:hover .text {
  color: #e8b33c;
}

/* Theme
   ========================================================================= */
/*
 * Toggle Button
 */
.nav-button {
  display: block;
  cursor: pointer;
}

.nav-button {
  background-color: transparent;
  color: #e8b33c;
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  -webkit-border-radius: 1.499994px;
  -moz-border-radius: 1.499994px;
  -ms-border-radius: 1.499994px;
  -o-border-radius: 1.499994px;
  border-radius: 1.499994px;
  line-height: 18px;
  margin-bottom: 0;
  text-align: center;
}

.nav-button:hover, .nav-button:active {
  color: #f3d797;
}

.nav-button:hover .toggle-bar, .nav-button:active .toggle-bar {
  background-color: #f3d797;
}

.nav-button .bar {
  background-color: #e8b33c;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  -ms-border-radius: 1px;
  -o-border-radius: 1px;
  border-radius: 1px;
  display: block;
  margin-top: 0;
  height: 3px;
  line-height: 14px;
  vertical-align: text-top;
}

.navigation {
  padding: 0px 10px 0px 10px;
  visibility: hidden;
}

.navigation .region-navigation {
  overflow: hidden;
  padding-bottom: 30px;
  padding-top: 30px;
}

.navigation .region-navigation .block-title,
.navigation .region-navigation a {
  color: #ffffff;
}

.navigation .region-navigation .block-menu-block {
  float: none;
  text-align: center;
}

@media (min-width: 61.25em) {
  .navigation .region-navigation .block-menu-block {
    text-align: left;
    float: left;
    padding-right: 15px;
    max-width: 200px;
  }
}

.navigation .region-navigation .block-menu-block .menu-block-wrapper {
  display: none;
}

@media (min-width: 61.25em) {
  .navigation .region-navigation .block-menu-block .menu-block-wrapper {
    display: block;
  }
}

.nav-button .bar + .bar {
  margin-top: 4px;
}

#toggle-nav .text {
  font-weight: normal;
  font-size: 10px;
  color: #e7b820;
}

.nav-button .bars {
  width: 28px;
}

.nav-button .bar {
  height: 3px;
  width: 28px;
  background: #1a1a1b;
}

.nav-button .bar.second {
  width: 18px;
  margin-left: auto;
  margin-right: auto;
}

.header {
  position: relative;
}

.header .block {
  margin-bottom: 0;
}

.header-wrapper {
  background-color: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding: 30px 0;
}

.header-wrapper a {
  color: #3c3b3b;
}

.header-wrapper a:hover {
  color: #e8b33c;
}

.header-wrapper .search-activate {
  right: 24px;
}

.header-wrapper .header-title-wrapper .header-title,
.header-wrapper .header-title-wrapper .tagline {
  text-align: center;
}

.header-wrapper .header-title-wrapper .tagline {
  font-size: 19.2px;
  color: #ffffff;
  font-weight: 100;
}

.logo {
  display: block;
  margin: 0 auto;
  width: 170px;
}

.logo img {
  width: 100%;
}

.logo img.inverted {
  display: none;
}

.header-image {
  overflow: hidden;
  height: 400px;
  background: #333;
  background-image: -owg(compact(linear-gradient(120deg, #ccc, #333), false, false, false, false, false, false, false, false, false));
  background-image: -webkit(compact(linear-gradient(120deg, #ccc, #333), false, false, false, false, false, false, false, false, false));
  background-image: -moz(compact(linear-gradient(120deg, #ccc, #333), false, false, false, false, false, false, false, false, false));
  background-image: -o(compact(linear-gradient(120deg, #ccc, #333), false, false, false, false, false, false, false, false, false));
  background-image: compact(linear-gradient(120deg, #ccc, #333), false, false, false, false, false, false, false, false, false);
}

.header-image.header-image-long {
  height: 500px;
}

.header-image img {
  /* Set rules to fill background */
  min-height: 100%;
  /* Set up proportionate scaling */
  width: 100%;
  height: auto;
  /* Seet up positioning */
  position: relative;
  top: 0;
  left: 0;
  min-width: 480px;
  left: 50%;
  margin-left: -240px;
  /* 50% */
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .header-image .dont-use-yet {
    /* Specific to this particular image */
  }
  .header-image .dont-use-yet img {
    min-width: 768px;
    left: 50%;
    margin-left: -384px;
    /* 50% */
  }
}

@media screen and (min-width: 769px) and (max-width: 980px) {
  .header-image .dont-use-yet {
    /* specific to this particular image */
  }
  .header-image .dont-use-yet img {
    min-width: 980px;
    left: 50%;
    margin-left: -490px;
    /* 50% */
  }
}

@media screen and (min-width: 981px) and (max-width: 1400px) {
  .header-image .dont-use-yet {
    /* specific to this particular image */
  }
  .header-image .dont-use-yet img {
    min-width: 1400px;
    left: 50%;
    margin-left: -700px;
    /* 50% */
  }
}

@media screen and (max-width: 1400px) {
  .header-image {
    /* specific to this particular image */
  }
  .header-image img {
    min-width: 1400px;
    left: 50%;
    margin-left: -700px;
    /* 50% */
  }
}

.secondary-menu {
  float: left;
}

#action-menus-wrapper {
  position: relative;
  z-index: 20;
  -webkit-transition: compact(compact(compact(opacity 0.3s ease-in-out false), false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(compact(opacity 0.3s ease-in-out false), false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(compact(opacity 0.3s ease-in-out false), false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(compact(opacity 0.3s ease-in-out false), false, false, false, false, false, false, false, false, false);
}

.show-search #action-menus-wrapper {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
}

#action-menus-wrapper #action-menus {
  position: absolute;
  left: 0px;
  top: 0;
}

.menu-secondary-links {
  position: absolute;
  right: 24px;
  top: 0;
}

.menu-secondary-links .menu li {
  display: inline-block;
  list-style-type: none;
  text-transform: uppercase;
}

.menu-secondary-links .menu li:first-child:after {
  content: '|';
  display: inline-block;
  margin: 0 1em;
}

.menu-secondary-links .menu a {
  color: #3b3c3c;
}

.menu-secondary-links .menu a:hover, .menu-secondary-links .menu a:active, .menu-secondary-links .menu a:focus {
  color: #e7b820;
}

.secondary-menu .links a {
  color: #3b3c3c;
}

.secondary-menu .links a:hover, .secondary-menu .links a:active, .secondary-menu .links a:focus {
  color: #e7b820;
}

.show-nav .secondary-menu .links a {
  color: #e7b820;
}

@media (max-width: 1140px) {
  #action-menus-wrapper #action-menus {
    left: 24px;
  }
}

#search-button .icon, #search-button .region-highlighted #home-slides .slide-copy a:after, .region-highlighted #home-slides .slide-copy #search-button a:after, #search-button .industries-menu-list a:after, .industries-menu-list #search-button a:after, #search-button .industry-description a:after, .industry-description #search-button a:after {
  font-size: 24px;
  color: #e7b820;
}

#search-close {
  position: absolute;
  right: 30px;
  top: 30px;
  border: none;
  padding: 0;
  background: rgba(255, 255, 255, 0.75);
  font-size: 32px;
  border-radius: 50%;
}

@media (min-width: 30em) {
  #search-close {
    font-size: 36px;
  }
}

@media (min-width: 37.5em) {
  #search-close {
    font-size: 40px;
  }
}

@media (min-width: 61.25em) {
  #search-close {
    font-size: 46px;
  }
}

#header-wrapper {
  position: fixed;
  width: 100%;
  height: 169px;
  transition: height 0.5s ease-in-out;
  background: #ffffff;
  z-index: 100;
}

.scale-container {
  padding-top: 169px;
}

.logo {
  will-change: width;
  transition: width 0.5s ease-in-out;
}

#navigation #block-block-2 {
  display: none;
}

.show-nav #header-wrapper {
  height: auto;
}

.show-nav .logo img {
  display: none;
}

.show-nav .logo img.inverted {
  display: block;
}

.show-nav #header-wrapper {
  background-color: black;
}

.scrolled #header-wrapper {
  height: 134px;
}

.scrolled .scale-container {
  padding-top: 134px;
}

.scrolled .logo {
  width: 115px;
}

.scrolled #navigation {
  top: 134px;
  z-index: 101;
}

.scrolled.show-nav #header-wrapper {
  height: auto;
}

.footer-wrapper {
  background: #ffffff url("../images/armstrong-footer-bg-logo.jpg") no-repeat left top;
  background-size: contain;
  border-top: 1px solid #f0f0f0;
  color: #3b3c3c;
  padding: 60px 0;
  overflow: hidden;
  margin-top: 60px;
}

.front .footer-wrapper {
  margin-top: 0px;
}

.footer .menu-menu-footer-menu ul.menu li {
  text-align: center;
}

.footer .menu-menu-footer-menu ul.menu li.last {
  margin-top: 50px;
}

@media (min-width: 48em) {
  .footer .menu-menu-footer-menu ul.menu li {
    text-align: left;
    font-size: 22px;
    font-size: 1.6923076923rem;
  }
  .footer .menu-menu-footer-menu ul.menu li.last {
    margin-top: 50px;
    font-size: 14px;
    font-size: 1.0769230769rem;
  }
}

.footer .copy-copyright {
  margin-bottom: 7.5px;
}

.footer a {
  color: #e7b820;
  text-transform: uppercase;
}

.footer .block {
  margin: 0px;
}

@media (min-width: 48em) {
  .region-footer-secondary {
    width: 41.6666666667%;
    clear: right;
    float: left;
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (min-width: 48em) {
  .region-footer-tertiary {
    width: 58.3333333333%;
    clear: right;
    float: left;
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (min-width: 48em) {
  .region-footer-tertiary .region-inner {
    padding-left: 120px;
  }
}

.slogan {
  margin-bottom: 30px;
}

.menu-menu-social-media .menu li {
  display: inline-block;
  margin-right: 8px;
}

.menu-menu-social-media .icon-replace, .menu-menu-social-media .flex-direction-nav a, .flex-direction-nav .menu-menu-social-media a {
  font-size: 0;
  display: inline-block;
  border: 1px solid #3b3c3c;
  padding: 4px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
}

.menu-menu-social-media .icon-replace:before, .menu-menu-social-media .flex-direction-nav a:before, .flex-direction-nav .menu-menu-social-media a:before {
  color: #3b3c3c;
}

.menu-menu-social-media .icon-replace:hover, .menu-menu-social-media .flex-direction-nav a:hover, .flex-direction-nav .menu-menu-social-media a:hover, .menu-menu-social-media .icon-replace:focus, .menu-menu-social-media .flex-direction-nav a:focus, .flex-direction-nav .menu-menu-social-media a:focus, .menu-menu-social-media .icon-replace:active, .menu-menu-social-media .flex-direction-nav a:active, .flex-direction-nav .menu-menu-social-media a:active {
  text-decoration: underline;
  color: #e7b820;
  border-color: #e7b820;
}

.menu-menu-social-media .icon-replace:hover:before, .menu-menu-social-media .flex-direction-nav a:hover:before, .flex-direction-nav .menu-menu-social-media a:hover:before, .menu-menu-social-media .icon-replace:focus:before, .menu-menu-social-media .flex-direction-nav a:focus:before, .flex-direction-nav .menu-menu-social-media a:focus:before, .menu-menu-social-media .icon-replace:active:before, .menu-menu-social-media .flex-direction-nav a:active:before, .flex-direction-nav .menu-menu-social-media a:active:before {
  color: #e7b820;
}

.footer-logo {
  margin-bottom: 15px;
}

#autocomplete {
  border-color: #cfcfcf;
}

#autocomplete ul {
  background: #ebebeb;
  padding: 0.5em 0;
}

#autocomplete li {
  background: none;
  color: #000000;
  padding: 8px 16px;
}

/* @partial typography */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 * 3. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units. 
 */
html {
  font-family: "ProximaNovaA-Regular";
  /* 1 */
  font-size: 81.25%;
  /* 3 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  line-height: 1.3846153846em;
}

/* =============================================================================
   Typography

   To achieve a pleasant vertical rhythm, we use Compass' Vertical Rhythm mixins
   so that the line height of our base font becomes the basic unit of vertical
   measurement. We use multiples of that unit to set the top and bottom margins
   for our block level elements and to set the line heights of any fonts.
   For more information, see http://24ways.org/2006/compose-to-a-vertical-rhythm
   ========================================================================== */
/*  Header Styles
   ========================================================================== */
h1, h2, h3, h4, h5, h6 {
  color: #454545;
  font-weight: bold;
  font-family: "ProximaNovaA-Bold";
  word-wrap: break-word;
}

h1 {
  font-size: 30px;
  font-size: 2.3076923077rem;
  line-height: 36px;
  margin-bottom: 18px;
  margin-bottom: 1.3846153846rem;
  margin-top: 36px;
  margin-top: 2.7692307692rem;
  text-transform: uppercase;
  color: #e8b33c;
  font-weight: 100;
}

@media (min-width: 37.5em) {
  h1 {
    font-size: 46px;
    font-size: 3.5384615385rem;
    line-height: 72px;
    margin-bottom: 18px;
    margin-bottom: 1.3846153846rem;
    margin-top: 36px;
    margin-top: 2.7692307692rem;
  }
}

h2 {
  font-size: 24px;
  font-size: 1.8461538462rem;
  line-height: 36px;
  margin-bottom: 18px;
  margin-bottom: 1.3846153846rem;
  margin-top: 36px;
  margin-top: 2.7692307692rem;
  text-transform: uppercase;
  color: #e8b33c;
  font-weight: 100;
}

h3 {
  font-size: 13px;
  font-size: 1rem;
  line-height: 18px;
  margin-bottom: 18px;
  margin-bottom: 1.3846153846rem;
  margin-top: 18px;
  margin-top: 1.3846153846rem;
  text-transform: uppercase;
}

@media (min-width: 30em) {
  h3 {
    font-size: 16px;
    font-size: 1.2307692308rem;
    line-height: 36px;
    margin-bottom: 18px;
    margin-bottom: 1.3846153846rem;
    margin-top: 18px;
    margin-top: 1.3846153846rem;
  }
}

h4, h5, h6 {
  font-size: 13px;
  font-size: 1rem;
  line-height: 18px;
  margin-bottom: 18px;
  margin-bottom: 1.3846153846rem;
  margin-top: 18px;
  margin-top: 1.3846153846rem;
}

h1, h2 {
  font-weight: normal;
  font-family: "TradeGothicLTStd-Extended";
}

/*  Drupal Titles
   ========================================================================== */
.page__title,
.node__title,
.block__title,
.comments__title,
.comments__form-title,
.comment__title {
  /* Comment title. */
  font-family: "ProximaNovaA-Bold";
  font-weight: bold;
}

.block-title {
  font-family: "ProximaNovaA-Bold";
  color: #454545;
  font-size: 13px;
  font-size: 1rem;
  line-height: 18px;
  margin-bottom: 18px;
  margin-bottom: 1.3846153846rem;
  margin-top: 18px;
  margin-top: 1.3846153846rem;
  text-transform: uppercase;
}

@media (min-width: 30em) {
  .block-title {
    font-size: 16px;
    font-size: 1.2307692308rem;
    line-height: 36px;
    margin-bottom: 18px;
    margin-bottom: 1.3846153846rem;
    margin-top: 18px;
    margin-top: 1.3846153846rem;
  }
}

.page__title {
  color: #454545;
  font-family: "ProximaNovaA-Bold";
}

.field-label {
  font-weight: bold;
  font-size: 1em;
}

h3.field-label {
  margin-bottom: .25em;
}

/*  Misc
   ========================================================================== */
/* Thematic break */
hr {
  height: 1px;
  border: 1px solid #666;
  padding-bottom: -1px;
  margin: 30px 0;
}

/*
 * Addresses styling not present in IE7/8/9, S5, Chrome
 */
abbr[title] {
  border-bottom: 1px dotted;
  cursor: help;
  white-space: nowrap;
}

/*
 * Addresses style set to 'bolder' in FF3+, S4/5, Chrome
*/
b,
strong {
  font-weight: bold;
}

blockquote {
  /* Sets 1 unit of vertical rhythm on the top and bottom margin. */
  margin: 30px 27px;
  border-left: 2.6px solid #f5dfae;
  padding: 6.5px 13px;
}

/*
 * Addresses styling not present in S5, Chrome
 */
dfn {
  font-style: italic;
}

/*
 * Addresses styling not present in IE6/7/8/9
 */
mark {
  background: #ff0;
  color: #000;
}

/*
 * Addresses margins set differently in IE6/7
 */
pre {
  /* Sets 1 unit of vertical rhythm on the top and bottom margin. */
  margin: 2.7692307692em 0;
}

p {
  margin: 0;
}

p + p {
  margin-top: 10px;
}

/*
 * Corrects font family set oddly in IE6, S4/5, Chrome
 * en.wikipedia.org/wiki/User:Davidgothberg/Test59
 */
pre,
code,
kbd,
samp,
tt,
var {
  font-family: monospace, "Courier New", "DejaVu Sans Mono", sans-serif;
  /* The $font-monospace value ends with ", serif". */
}

p pre,
p code,
p kbd,
p samp,
p tt,
p var {
  margin: 0;
  padding: 0;
  color: #3c3b3b;
  background: transparent;
}

/*
 * Improves readability of pre-formatted text in all browsers
 */
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/*
 * 1. Addresses CSS quotes not supported in IE6/7
 * 2. Addresses quote property not supported in S4
 */
/* 1 */
q {
  quotes: none;
}

/* 2 */
q:before,
q:after {
  content: '';
  content: none;
}

/* @todo Determine how big element affects vertical rhythm. */
small {
  font-size: 75%;
}

/*
 * Prevents sub and sup affecting line-height in all browsers
 * gist.github.com/413930
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* WYSIWYG / BASIC PAGE FIELD CSS */
.wys-divider {
  height: 0;
  border-bottom: 1px solid #cccccc;
  margin: 30px 0;
}

.wys-intro-title {
  font-weight: bold;
}

.wys-intro-text {
  font-weight: normal;
}

.wys-section-title {
  font-weight: bold;
}

.wys-block-title {
  font-weight: bold;
  color: #090808;
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/iconfonts/icomoon/fonts/icomoon.ttf?xrvc91") format("truetype"), url("../fonts/iconfonts/icomoon/fonts/icomoon.woff?xrvc91") format("woff"), url("../fonts/iconfonts/icomoon/fonts/icomoon.svg?xrvc91#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

.icon, .region-highlighted #home-slides .slide-copy a:after, .industries-menu-list a:after, .industry-description a:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-replace, .flex-direction-nav a {
  font-size: 0;
}

.icon-replace:before, .flex-direction-nav a:before {
  font-size: 16px;
  font-size: 1rem;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-plus:before {
  content: "\e900";
}

.icon-question:before {
  content: "\e901";
}

.icon-minus:before {
  content: "\e902";
}

.icon-music:before {
  content: "\f001";
}

.icon-search:before {
  content: "\e903";
}

.icon-envelope-o:before {
  content: "\e904";
}

.icon-heart:before {
  content: "\e905";
}

.icon-star:before {
  content: "\e906";
}

.icon-star-o:before {
  content: "\e907";
}

.icon-user:before {
  content: "\e908";
}

.icon-th-large:before {
  content: "\e909";
}

.icon-th:before {
  content: "\e90a";
}

.icon-th-list:before {
  content: "\e90b";
}

.icon-check:before {
  content: "\e90c";
}

.icon-close:before {
  content: "\e90d";
}

.icon-remove:before {
  content: "\e90d";
}

.icon-times:before {
  content: "\e90d";
}

.icon-search-plus:before {
  content: "\e90e";
}

.icon-search-minus:before {
  content: "\e90f";
}

.icon-cog:before {
  content: "\e910";
}

.icon-gear:before {
  content: "\e910";
}

.icon-trash-o:before {
  content: "\e911";
}

.icon-home:before {
  content: "\e912";
}

.icon-file-o:before {
  content: "\e913";
}

.icon-clock-o:before {
  content: "\e914";
}

.icon-download:before {
  content: "\e915";
}

.icon-arrow-circle-o-down:before {
  content: "\e916";
}

.icon-arrow-circle-o-up:before {
  content: "\e917";
}

.icon-inbox:before {
  content: "\e918";
}

.icon-play-circle-o:before {
  content: "\e919";
}

.icon-repeat:before {
  content: "\e91a";
}

.icon-rotate-right:before {
  content: "\e91a";
}

.icon-refresh:before {
  content: "\e91b";
}

.icon-list-alt:before {
  content: "\f022";
}

.icon-lock:before {
  content: "\e91c";
}

.icon-flag:before {
  content: "\f024";
}

.icon-headphones:before {
  content: "\f025";
}

.icon-volume-off:before {
  content: "\f026";
}

.icon-volume-down:before {
  content: "\f027";
}

.icon-volume-up:before {
  content: "\f028";
}

.icon-tag:before {
  content: "\e91d";
}

.icon-tags:before {
  content: "\e91e";
}

.icon-book:before {
  content: "\e91f";
}

.icon-bookmark:before {
  content: "\e920";
}

.icon-print:before {
  content: "\e921";
}

.icon-camera:before {
  content: "\e922";
}

.icon-video-camera:before {
  content: "\e923";
}

.icon-image:before {
  content: "\e924";
}

.icon-photo:before {
  content: "\e924";
}

.icon-picture-o:before {
  content: "\e924";
}

.icon-pencil:before {
  content: "\e925";
}

.icon-map-marker:before {
  content: "\e926";
}

.icon-edit:before {
  content: "\e927";
}

.icon-pencil-square-o:before {
  content: "\e927";
}

.icon-share-square-o:before {
  content: "\e928";
}

.icon-check-square-o:before {
  content: "\e929";
}

.icon-play:before {
  content: "\e92a";
}

.icon-pause:before {
  content: "\e92b";
}

.icon-stop:before {
  content: "\e92c";
}

.icon-forward:before {
  content: "\e92d";
}

.icon-fast-forward:before {
  content: "\e92e";
}

.icon-step-forward:before {
  content: "\e92f";
}

.icon-chevron-left:before {
  content: "\e930";
}

.icon-chevron-right:before {
  content: "\e931";
}

.icon-plus-circle:before {
  content: "\e932";
}

.icon-minus-circle:before {
  content: "\e933";
}

.icon-times-circle:before {
  content: "\e934";
}

.icon-check-circle:before {
  content: "\e935";
}

.icon-question-circle:before {
  content: "\e936";
}

.icon-info-circle:before {
  content: "\e937";
}

.icon-times-circle-o:before {
  content: "\e938";
}

.icon-check-circle-o:before {
  content: "\e939";
}

.icon-arrow-left:before {
  content: "\e93a";
}

.icon-arrow-right:before {
  content: "\e93b";
}

.icon-arrow-up:before {
  content: "\e93c";
}

.icon-arrow-down:before {
  content: "\e93d";
}

.icon-mail-forward:before {
  content: "\e93e";
}

.icon-share:before {
  content: "\e93e";
}

.icon-expand:before {
  content: "\e93f";
}

.icon-compress:before {
  content: "\e940";
}

.icon-exclamation-circle:before {
  content: "\e941";
}

.icon-eye:before {
  content: "\e942";
}

.icon-eye-slash:before {
  content: "\e943";
}

.icon-exclamation-triangle:before {
  content: "\e944";
}

.icon-warning:before {
  content: "\e944";
}

.icon-calendar:before {
  content: "\e945";
}

.icon-random:before {
  content: "\e946";
}

.icon-comment:before {
  content: "\e947";
}

.icon-chevron-up:before {
  content: "\e948";
}

.icon-chevron-down:before {
  content: "\e949";
}

.icon-retweet:before {
  content: "\f079";
}

.icon-shopping-cart:before {
  content: "\e94a";
}

.icon-folder:before {
  content: "\e94b";
}

.icon-folder-open:before {
  content: "\e94c";
}

.icon-bar-chart:before {
  content: "\e94d";
}

.icon-bar-chart-o:before {
  content: "\e94d";
}

.icon-twitter-square:before {
  content: "\e94e";
}

.icon-facebook-square:before {
  content: "\e94f";
}

.icon-camera-retro:before {
  content: "\e950";
}

.icon-key:before {
  content: "\e951";
}

.icon-cogs:before {
  content: "\e952";
}

.icon-gears:before {
  content: "\e952";
}

.icon-comments:before {
  content: "\e953";
}

.icon-thumbs-o-up:before {
  content: "\e954";
}

.icon-thumbs-o-down:before {
  content: "\e955";
}

.icon-linkedin-square:before {
  content: "\e956";
}

.icon-phone:before {
  content: "\e957";
}

.icon-phone-square:before {
  content: "\e958";
}

.icon-twitter:before {
  content: "\e959";
}

.icon-facebook:before {
  content: "\e95a";
}

.icon-facebook-f:before {
  content: "\e95a";
}

.icon-arrow-circle-left:before {
  content: "\e95b";
}

.icon-arrow-circle-right:before {
  content: "\e95c";
}

.icon-arrow-circle-up:before {
  content: "\e95d";
}

.icon-arrow-circle-down:before {
  content: "\e95e";
}

.icon-globe:before {
  content: "\e95f";
}

.icon-copy:before {
  content: "\e960";
}

.icon-files-o:before {
  content: "\e960";
}

.icon-paperclip:before {
  content: "\e961";
}

.icon-bars:before {
  content: "\e962";
}

.icon-navicon:before {
  content: "\e962";
}

.icon-reorder:before {
  content: "\e962";
}

.icon-list-ul:before {
  content: "\e963";
}

.icon-pinterest:before {
  content: "\e964";
}

.icon-pinterest-square:before {
  content: "\e965";
}

.icon-google-plus-square:before {
  content: "\e966";
}

.icon-google-plus:before {
  content: "\e967";
}

.icon-caret-down:before {
  content: "\e968";
}

.icon-caret-up:before {
  content: "\e969";
}

.icon-caret-left:before {
  content: "\e96a";
}

.icon-caret-right:before {
  content: "\e96b";
}

.icon-sort-desc:before {
  content: "\e96c";
}

.icon-sort-down:before {
  content: "\e96c";
}

.icon-sort-asc:before {
  content: "\e96d";
}

.icon-sort-up:before {
  content: "\e96d";
}

.icon-linkedin:before {
  content: "\e96e";
}

.icon-cloud-download:before {
  content: "\e96f";
}

.icon-cloud-upload:before {
  content: "\e970";
}

.icon-angle-double-left:before {
  content: "\e971";
}

.icon-angle-double-right:before {
  content: "\e972";
}

.icon-angle-double-up:before {
  content: "\e973";
}

.icon-angle-double-down:before {
  content: "\e974";
}

.icon-angle-left:before, .flex-direction-nav a.flex-prev:before {
  content: "\e975";
}

.icon-angle-right:before, .region-highlighted #home-slides .slide-copy a:after, .industries-menu-list a:after, .industry-description a:after, .flex-direction-nav a.flex-next:before {
  content: "\e976";
}

.icon-angle-up:before {
  content: "\e977";
}

.icon-angle-down:before {
  content: "\e978";
}

.icon-mobile:before {
  content: "\e979";
}

.icon-mobile-phone:before {
  content: "\e979";
}

.icon-folder-o:before {
  content: "\e97a";
}

.icon-folder-open-o:before {
  content: "\e97b";
}

.icon-info:before {
  content: "\e97c";
}

.icon-calendar-o:before {
  content: "\e97d";
}

.icon-ellipsis-h:before {
  content: "\e97e";
}

.icon-ellipsis-v:before {
  content: "\e97f";
}

.icon-rss-square:before {
  content: "\e980";
}

.icon-play-circle:before {
  content: "\e981";
}

.icon-minus-square:before {
  content: "\e982";
}

.icon-check-square:before {
  content: "\e983";
}

.icon-compass:before {
  content: "\e984";
}

.icon-file:before {
  content: "\e985";
}

.icon-file-text:before {
  content: "\e986";
}

.icon-youtube-square:before {
  content: "\e987";
}

.icon-youtube:before {
  content: "\e988";
}

.icon-youtube-play:before {
  content: "\e989";
}

.icon-tumblr:before {
  content: "\e98a";
}

.icon-tumblr-square:before {
  content: "\e98b";
}

.icon-long-arrow-down:before {
  content: "\e98c";
}

.icon-long-arrow-up:before {
  content: "\e98d";
}

.icon-long-arrow-left:before {
  content: "\e98e";
}

.icon-long-arrow-right:before {
  content: "\e98f";
}

.icon-skype:before {
  content: "\e990";
}

.icon-vimeo-square:before {
  content: "\e991";
}

.icon-envelope-square:before {
  content: "\e992";
}

.icon-paper-plane:before {
  content: "\e993";
}

.icon-send:before {
  content: "\e993";
}

.icon-paper-plane-o:before {
  content: "\e994";
}

.icon-send-o:before {
  content: "\e994";
}

.icon-share-alt:before {
  content: "\e995";
}

.icon-cc-visa:before {
  content: "\e996";
}

.icon-cc-mastercard:before {
  content: "\e997";
}

.icon-cc-discover:before {
  content: "\e998";
}

.icon-cc-amex:before {
  content: "\e999";
}

.icon-cc-paypal:before {
  content: "\e99a";
}

.icon-cc-stripe:before {
  content: "\e99b";
}

.icon-area-chart:before {
  content: "\e99c";
}

.icon-pie-chart:before {
  content: "\e99d";
}

.icon-line-chart:before {
  content: "\e99e";
}

.icon-toggle-off:before {
  content: "\e99f";
}

.icon-toggle-on:before {
  content: "\e9a0";
}

.icon-facebook-official:before {
  content: "\e9a1";
}

.icon-calendar-plus-o:before {
  content: "\e9a2";
}

.icon-calendar-minus-o:before {
  content: "\e9a3";
}

.icon-calendar-times-o:before {
  content: "\e9a4";
}

.icon-calendar-check-o:before {
  content: "\e9a5";
}

/* @partial page-layout */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#header,
#main,
.footer,
#action-menus-wrapper,
.region-content-top,
.region-navigation,
.region-content-bottom,
.region-bottom,
.layout-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
}

#page {
  min-width: 320px;
}

#header,
#content,
.region-content-bottom,
.region-content-top,
.region-sidebar-first,
.region-sidebar-second,
.footer,
.layout-container {
  padding-left: 12px;
  padding-right: 12px;
}

.block {
  margin-bottom: 30px;
  margin-top: 0;
}

/*
 * Containers for grid items and flow items.
 */
#header:before, #header:after,
#main:before,
#main:after,
.region-content-bottom:before,
.region-content-bottom:after,
.region-content-top:before,
.region-content-top:after,
.region-top:before,
.region-top:after,
.region-highlighted:before,
.region-highlighted:after {
  content: '';
  display: table;
}

#header:after,
#main:after,
.region-content-bottom:after,
.region-content-top:after,
.region-top:after,
.region-highlighted:after {
  clear: both;
}

.region-highlighted {
  position: relative;
  margin-bottom: 30px;
}

.region-highlighted .not-front {
  padding-top: 200px;
}

.region-content-top,
.region-content-bottom {
  clear: both;
}

.region-sidebar-first,
.region-sidebar-second {
  display: none;
}

/* PHONE (min-width: 480px)
    ========================================================================== */
/* TABLET (min-width: 600px to 960px)
   ========================================================================== */
@media (min-width: 37.5em) and (max-width: 61.1875em) {
  .region-sidebar-first,
  .region-sidebar-second {
    display: block;
  }
  /*
   * The layout when there is only one sidebar, the left one.
   */
  .sidebar-first #content {
    width: 66.6666666667%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: none;
    padding-left: 12px;
    padding-right: 12px;
  }
  .sidebar-first .region-sidebar-first {
    width: 33.3333333333%;
    float: left;
    margin-right: -100%;
    clear: none;
    padding-left: 12px;
    padding-right: 12px;
  }
  /*
   * The layout when there is only one sidebar, the right one.
   */
  .sidebar-second #content {
    width: 66.6666666667%;
    float: left;
    margin-right: -100%;
    clear: none;
    padding-left: 12px;
    padding-right: 12px;
  }
  .sidebar-second .region-sidebar-second {
    width: 33.3333333333%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: none;
    padding-left: 12px;
    padding-right: 12px;
  }
  /*
   * The layout when there are two sidebars.
   */
  .two-sidebars #content {
    width: 66.6666666667%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: none;
    padding-left: 12px;
    padding-right: 12px;
  }
  .two-sidebars .region-sidebar-first {
    width: 33.3333333333%;
    float: left;
    margin-right: -100%;
    clear: none;
    padding-left: 12px;
    padding-right: 12px;
  }
  .two-sidebars .region-sidebar-second {
    width: 100%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: none;
    padding-left: 12px;
    padding-right: 12px;
  }
}

/* DESKTOP (min-width: 960px+)
  ========================================================================== */
@media (min-width: 61.25em) {
  .region-sidebar-first,
  .region-sidebar-second {
    display: block;
  }
  /*
   * The layout when there is only one sidebar, the left one.
   */
  .sidebar-first #content {
    width: 75%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: none;
    padding-left: 12px;
    padding-right: 12px;
  }
  .sidebar-first .region-sidebar-first {
    width: 16.6666666667%;
    float: left;
    margin-right: -100%;
    clear: none;
    padding-left: 12px;
    padding-right: 12px;
  }
  /*
   * The layout when there is only one sidebar, the right one.
   */
  .sidebar-second #content {
    width: 75%;
    float: left;
    margin-right: -100%;
    clear: none;
    padding-left: 12px;
    padding-right: 12px;
  }
  .sidebar-second .region-sidebar-second {
    width: 16.6666666667%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: none;
    padding-left: 12px;
    padding-right: 12px;
  }
  /*
   * The layout when there are two sidebars.
   */
  .two-sidebars #content {
    width: 50%;
    float: left;
    margin-right: -100%;
    margin-left: 25%;
    clear: none;
    padding-left: 12px;
    padding-right: 12px;
  }
  .two-sidebars .region-sidebar-first {
    width: 16.6666666667%;
    float: left;
    margin-right: -100%;
    clear: none;
    padding-left: 12px;
    padding-right: 12px;
  }
  .two-sidebars .region-sidebar-second {
    width: 16.6666666667%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: none;
    padding-left: 12px;
    padding-right: 12px;
  }
}

/* @partial page-theme */
/* Body
   ========================================================================== */
html {
  position: relative;
}

body {
  background-color: #ffffff;
}

/* Page
   ========================================================================== */
#page {
  position: relative;
  overflow: hidden;
}

/* Main / Content
   ========================================================================== */
.not-front #main {
  padding-top: 30px;
}

/* Messages
   ========================================================================== */
.messages, .messages--status, .messages--warning, .messages--error {
  margin: 30px 0;
  padding: 10px 10px 10px 50px;
  /* LTR */
  background-image: inline-image("message-24-ok.png");
  background-position: 8px 8px;
  /* LTR */
  background-repeat: no-repeat;
  border: 1px solid #bbee77;
}

.messages--warning {
  background-image: inline-image("message-24-warning.png");
  border-color: #eedd55;
}

.messages--error {
  background-image: inline-image("message-24-error.png");
  border-color: #ed541d;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

/* Core/module installation error messages. */
.messages--error p.error {
  color: #333333;
}

/* System status report. */
.ok,
.messages--status {
  background-color: #f8fff0;
  color: #234600;
}

.warning,
.messages--warning {
  background-color: #fffce5;
  color: #884400;
}

.error,
.messages--error {
  background-color: #fef5f1;
  color: #8c2e0b;
}

/* First Sidebar
   ========================================================================== */
/* Second Sidebar
   ========================================================================== */
/* Page Bottom
   ========================================================================== */
/* Instant Click Bar
   ========================================================================== */
#instantclick-bar {
  background: #e8b33c;
}

/* Link color overrides
   ========================================================================== */
a {
  color: #3c3b3b;
}

a:hover, a.active, a.active-trail {
  color: #e8b33c;
}

.breadcrumb {
  padding-left: 12px;
  padding-right: 12px;
  text-transform: uppercase;
  color: #e8b33c;
}

.breadcrumb .breadcrumb-separator {
  color: #3c3b3b;
}

.breadcrumb a {
  text-transform: uppercase;
}

/* Search
   ============================================================================== */
.search-activate {
  position: absolute;
  top: 47px;
  right: 0;
  z-index: 20;
}

.search-activate:hover #search-button {
  color: #3c3b3b;
}

.search-activate #search-button {
  display: block;
}

.search-activate #search-button span {
  text-transform: uppercase;
  float: left;
  margin: 0;
  font-size: 20px;
  padding-right: 5px;
  padding-top: 3px;
}

.show-nav .search-activate #search-button span {
  color: #ffffff;
}

.show-nav .search-activate #search-button .search-icon {
  color: #ffffff;
}

.search-activate #search-field {
  display: none;
}

.search-active {
  z-index: 102;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  overflow: hidden;
}

.js.opacity .search-active {
  display: block;
  /*
     *@include translateY(-100%);
     *@include single-transition(transform, 0.3s, ease-in-out);
     */
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: compact(compact(compact(opacity 0.3s ease-in-out false), false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(compact(opacity 0.3s ease-in-out false), false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(compact(opacity 0.3s ease-in-out false), false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(compact(opacity 0.3s ease-in-out false), false, false, false, false, false, false, false, false, false);
}

.js.opacity .show-search .search-active {
  overflow: visible;
  /*
     *@include translateY(0);
     */
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.search-active #search-wrapper {
  overflow: hidden;
  max-height: 0;
  padding: 0;
  height: 10000px;
  -webkit-transition-property: compact(max-height, padding, false, false, false, false, false, false, false, false);
  -moz-transition-property: compact(max-height, padding, false, false, false, false, false, false, false, false);
  -o-transition-property: compact(max-height, padding, false, false, false, false, false, false, false, false);
  transition-property: compact(max-height, padding, false, false, false, false, false, false, false, false);
  -webkit-transition-duration: compact(0s, false, false, false, false, false, false, false, false, false);
  -moz-transition-duration: compact(0s, false, false, false, false, false, false, false, false, false);
  -o-transition-duration: compact(0s, false, false, false, false, false, false, false, false, false);
  transition-duration: compact(0s, false, false, false, false, false, false, false, false, false);
  -webkit-transition-delay: compact(0.3s, false, false, false, false, false, false, false, false, false);
  -moz-transition-delay: compact(0.3s, false, false, false, false, false, false, false, false, false);
  -o-transition-delay: compact(0.3s, false, false, false, false, false, false, false, false, false);
  transition-delay: compact(0.3s, false, false, false, false, false, false, false, false, false);
}

.show-search .search-active #search-wrapper {
  max-height: 10000px;
  padding: 150px 30px;
  -webkit-transition-property: compact(max-height, padding, false, false, false, false, false, false, false, false);
  -moz-transition-property: compact(max-height, padding, false, false, false, false, false, false, false, false);
  -o-transition-property: compact(max-height, padding, false, false, false, false, false, false, false, false);
  transition-property: compact(max-height, padding, false, false, false, false, false, false, false, false);
  -webkit-transition-duration: compact(0s, false, false, false, false, false, false, false, false, false);
  -moz-transition-duration: compact(0s, false, false, false, false, false, false, false, false, false);
  -o-transition-duration: compact(0s, false, false, false, false, false, false, false, false, false);
  transition-duration: compact(0s, false, false, false, false, false, false, false, false, false);
  -webkit-transition-delay: compact(0s, false, false, false, false, false, false, false, false, false);
  -moz-transition-delay: compact(0s, false, false, false, false, false, false, false, false, false);
  -o-transition-delay: compact(0s, false, false, false, false, false, false, false, false, false);
  transition-delay: compact(0s, false, false, false, false, false, false, false, false, false);
}

.search-active #search-wrapper .logo-small {
  width: 100px;
}

.search-active #search-wrapper #search-field {
  position: relative;
  padding-top: 10px;
  width: 60%;
}

.search-active #search-wrapper #search-field .search-glass {
  position: absolute;
  top: 40px;
  left: 10px;
}

.search-active #search-wrapper #search-field input[type='text'] {
  padding-left: 50px;
  border: none;
  box-shadow: none;
  width: 100%;
  height: 80px;
  font-size: 24px;
  font-size: 1.8461538462rem;
  line-height: 36px;
  margin-bottom: 18px;
  margin-bottom: 1.3846153846rem;
  margin-top: 36px;
  margin-top: 2.7692307692rem;
  text-transform: uppercase;
  color: #e8b33c;
  font-weight: 100;
  margin-top: 0;
  margin-bottom: 0;
  color: #454545;
}

.search-active #search-wrapper #search-field #start-searching {
  position: absolute;
  right: 0;
  top: 10px;
  height: 80px;
  border: none;
  box-shadow: none;
  width: 80px;
  font-size: 24px;
  font-size: 1.8461538462rem;
  line-height: 36px;
  margin-bottom: 18px;
  margin-bottom: 1.3846153846rem;
  margin-top: 36px;
  margin-top: 2.7692307692rem;
  text-transform: uppercase;
  color: #e8b33c;
  font-weight: 100;
  color: white;
  background: #454545;
  margin-bottom: 0;
  margin-top: 0;
}

.search-active #search-wrapper #search-field #advanced-search {
  position: absolute;
  right: 95px;
  top: 42px;
}

.search-active #search-wrapper #search-results {
  width: 60%;
  background: rgba(255, 255, 255, 0.6);
}

.search-active #search-wrapper #search-results .loading {
  margin: 0 auto;
  width: 32px;
  padding-top: 60px;
  padding-bottom: 60px;
}

.search-active #search-wrapper #search-results .result {
  padding-top: 3.75px;
  padding-bottom: 3.75px;
  padding-left: 50px;
  padding-right: 80px;
  -webkit-transition: compact(compact(compact(background-color 0.05s ease 0s), false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(compact(background-color 0.05s ease 0s), false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(compact(background-color 0.05s ease 0s), false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(compact(background-color 0.05s ease 0s), false, false, false, false, false, false, false, false, false);
}

.search-active #search-wrapper #search-results .result .category h3,
.search-active #search-wrapper #search-results .result .title h3 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 26px;
  font-weight: 100;
}

.search-active #search-wrapper #search-results .result .category h3 {
  color: #e8b33c;
}

.search-active #search-wrapper #search-results .result .category h3 .in {
  color: #cccccc;
  text-transform: lowercase;
}

.search-active #search-wrapper #search-results .result.active {
  background: rgba(0, 0, 0, 0.1);
}

.scale-container {
  background: white;
  -webkit-transition: compact(compact(compact(transform 0.4s ease-in-out false), false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(compact(transform 0.4s ease-in-out false), false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(compact(transform 0.4s ease-in-out false), false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(compact(transform 0.4s ease-in-out false), false, false, false, false, false, false, false, false, false);
}

.show-search .scale-container {
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
}

/*
 * Language switcher
 ****/
.language-wrapper {
  float: left;
}

.language-wrapper #current-lang a,
.language-wrapper .language-switcher-locale-url a {
  text-transform: uppercase;
  font-weight: bold;
  color: #454545;
  font-size: 13px;
}

.language-wrapper #current-lang a:hover,
.language-wrapper .language-switcher-locale-url a:hover {
  color: #e8b33c;
}

.language-wrapper #current-lang {
  margin-right: 10px;
  float: left;
}

.language-wrapper .language-switcher-locale-url {
  top: 0;
  display: none;
  z-index: 40;
  margin: 0;
  list-style-type: none;
  padding: 0;
  margin: 1em;
  overflow: hidden;
  margin: 0;
}

.language-wrapper .language-switcher-locale-url > li {
  display: block;
  float: left;
  margin-right: 0.25em;
}

.language-wrapper .language-switcher-locale-url > li:last-child {
  margin-right: 0;
}

.language-wrapper .language-switcher-locale-url > li {
  margin-right: 1em;
}

.lang-open .language-wrapper {
  margin: -10px 0 0 -10px;
  padding: 10px;
  background: white;
  -webkit-box-shadow: compact(5px #3c3b3b, false, false, false, false, false, false, false, false, false);
  -moz-box-shadow: compact(5px #3c3b3b, false, false, false, false, false, false, false, false, false);
  box-shadow: compact(5px #3c3b3b, false, false, false, false, false, false, false, false, false);
}

.lang-open .language-wrapper .language-switcher-locale-url {
  display: block;
}

.lang-open .secondary-menu {
  display: none;
}

/* @partial forms */
/* Forms
   ========================================================================== */
/* Wrapper for a form element (or group of form elements) and its label. */
.form-item {
  margin: 30px 0;
  /* Pack groups of checkboxes and radio buttons closer together. */
  /* Form items in a table. */
  /* Highlight the form elements that caused a form submission error. */
  /* The descriptive help text (separate from the label). */
}

.form-checkboxes .form-item,
.form-radios .form-item {
  /* Drupal core uses "0.4em 0". */
  margin: 0;
}

tr.odd .form-item,
tr.even .form-item {
  margin: 0;
}

.form-item label {
  /* The label for a form element */
  display: block;
  font-weight: bold;
  margin: 0 0 0 0;
  font-size: 13px;
  font-size: 1rem;
}

.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #e02443;
}

.form-item .description {
  font-size: 13px;
  margin-top: 4px;
}

.form-type-radio .description,
.form-type-checkbox .description {
  margin-left: 2.4em;
}

.form-required {
  /* The part of the label that indicates a required field */
  color: #e02443;
}

/* Labels for radios and checkboxes. */
label.option {
  display: inline;
  font-weight: normal;
}

/* Buttons used by contrib modules like Media. */
a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
}

/* Password confirmation. */
.password-parent,
.confirm-parent {
  margin: 0;
}

/* Drupal's default login form block. */
#user-login-form {
  text-align: left;
  /* LTR */
}

/**
 * Autocomplete.
 *
 * @see autocomplete.js
 */
/* Suggestion list. */
#autocomplete .selected {
  background: #0072b9;
  color: #ffffff;
}

/* Fieldsets & helper class for div around fieldset
   ========================================================================== */
/**
 * 1. Correct `color` not being inherited in IE 8/9.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */
  font-weight: bold;
}

fieldset,
.fieldset {
  border-color: #f5dfae;
  border-top-style: solid;
  border-top-width: 0.0769230769em;
  padding-top: 0.4076923077em;
  border-bottom-style: solid;
  border-bottom-width: 0.0769230769em;
  padding-bottom: 0.8230769231em;
  border-left-style: solid;
  border-left-width: 0.0769230769em;
  padding-left: 0.8230769231em;
  border-right-style: solid;
  border-right-width: 0.0769230769em;
  padding-right: 0.8230769231em;
  border-style: solid;
  padding: 18px;
  margin: 0 0 24px 0;
  -webkit-border-radius: 1.499994px;
  -moz-border-radius: 1.499994px;
  -ms-border-radius: 1.499994px;
  -o-border-radius: 1.499994px;
  border-radius: 1.499994px;
  background: none repeat scroll 0 0 white;
}

/* Drupal body field footer */
fieldset.filter-wrapper {
  margin: 0 0 24px 0;
  padding: 1.5em 0 0 0;
  font-size: 14px;
  font-size: 0.875rem;
  -moz-border-radius-topleft: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  -moz-border-radius-bottomleft: 1.499994px;
  -webkit-border-bottom-left-radius: 1.499994px;
  border-bottom-left-radius: 1.499994px;
  -moz-border-radius-bottomright: 1.499994px;
  -webkit-border-bottom-right-radius: 1.499994px;
  border-bottom-right-radius: 1.499994px;
}

fieldset.filter-wrapper .filter-help {
  margin: 0;
}

/**
 * Collapsible fieldsets.
 *
 * @see collapse.js
 */
html.js .collapsible .fieldset-legend {
  background-image: inline-image("menu-expanded.png");
  background-position: 5px 65%;
  /* LTR */
  background-repeat: no-repeat;
  padding-left: 15px;
  /* LTR */
}

html.js .collapsed .fieldset-legend {
  background-image: inline-image("menu-collapsed.png");
  /* LTR */
  background-position: 5px 50%;
  /* LTR */
}

.fieldset-legend .summary {
  color: #999999;
  font-size: 12px;
  font-size: 0.857rem;
  margin-left: 0.5em;
}

/* Submit Buttons and Node Edit Inputs Submit
   ========================================================================== */
/**
 * 1. Correct font family not being inherited in all browsers.
 * 2. Correct font size not being inherited in all browsers.
 * 3. Address margins set differently in Firefox 4+, Safari 5, and Chrome.
 * 4. Improve appearance and consistency with IE 6/7.
 * 5. Keep form elements constrained in their containers.
 */
button,
input,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 2 */
  margin: 0;
  /* 3 */
  max-width: 100%;
  /* 5 */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* 5 */
}

button:focus, button:active,
input:focus,
input:active,
select:focus,
select:active,
textarea:focus,
textarea:active {
  /* for Opera */
  z-index: 1;
}

button,
textarea,
input {
  background: #fff;
  border: 1px solid #f5dfae;
  -webkit-box-shadow: compact(inset 0 1px 2px rgba(0, 0, 0, 0.1), false, false, false, false, false, false, false, false, false);
  -moz-box-shadow: compact(inset 0 1px 2px rgba(0, 0, 0, 0.1), false, false, false, false, false, false, false, false, false);
  box-shadow: compact(inset 0 1px 2px rgba(0, 0, 0, 0.1), false, false, false, false, false, false, false, false, false);
  -webkit-border-radius: 1.499994px;
  -moz-border-radius: 1.499994px;
  -ms-border-radius: 1.499994px;
  -o-border-radius: 1.499994px;
  border-radius: 1.499994px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition-property: compact(border, box-shadow, color, background-color, false, false, false, false, false, false);
  -moz-transition-property: compact(border, box-shadow, color, background-color, false, false, false, false, false, false);
  -o-transition-property: compact(border, box-shadow, color, background-color, false, false, false, false, false, false);
  transition-property: compact(border, box-shadow, color, background-color, false, false, false, false, false, false);
  -webkit-transition-duration: compact(0.1s, false, false, false, false, false, false, false, false, false);
  -moz-transition-duration: compact(0.1s, false, false, false, false, false, false, false, false, false);
  -o-transition-duration: compact(0.1s, false, false, false, false, false, false, false, false, false);
  transition-duration: compact(0.1s, false, false, false, false, false, false, false, false, false);
  -webkit-transition-timing-function: compact(ease-in, false, false, false, false, false, false, false, false, false);
  -moz-transition-timing-function: compact(ease-in, false, false, false, false, false, false, false, false, false);
  -o-transition-timing-function: compact(ease-in, false, false, false, false, false, false, false, false, false);
  transition-timing-function: compact(ease-in, false, false, false, false, false, false, false, false, false);
  padding: 8px 16px;
  font-family: inherit;
  color: #3c3b3b;
  font-size: 14px;
  max-width: 100%;
  /* fixed overflow of input fields in drupal 6 layouts */
}

button:focus, button:active,
textarea:focus,
textarea:active,
input:focus,
input:active {
  border: 1px solid #e8b33c;
  background: #fff;
  outline: none;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 8+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to `content-box` in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type='checkbox'],
input[type='radio'] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  /* 2 */
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/* Remove inner padding and border in Firefox 4+. */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type='submit'],
.node-readmore a {
  background-color: #e8b33c;
  color: white;
  font-weight: normal;
  border: 1px solid #d89d19;
  -webkit-border-radius: 1.499994px;
  -moz-border-radius: 1.499994px;
  -ms-border-radius: 1.499994px;
  -o-border-radius: 1.499994px;
  border-radius: 1.499994px;
  -webkit-box-shadow: compact(rgba(255, 255, 255, 0.2) 0 0 8px inset, rgba(0, 0, 0, 0.05) 0 1px 2px, false, false, false, false, false, false, false, false);
  -moz-box-shadow: compact(rgba(255, 255, 255, 0.2) 0 0 8px inset, rgba(0, 0, 0, 0.05) 0 1px 2px, false, false, false, false, false, false, false, false);
  box-shadow: compact(rgba(255, 255, 255, 0.2) 0 0 8px inset, rgba(0, 0, 0, 0.05) 0 1px 2px, false, false, false, false, false, false, false, false);
  font-size: 13px;
  font-size: 1rem;
  text-transform: uppercase;
  text-shadow: 0 1px 1px #aa7c14;
  text-align: center;
  display: inline-block;
  line-height: 19.5px;
  height: 19.5px;
  padding: 0 24px;
}

input[type='submit']:hover,
.node-readmore a:hover {
  text-decoration: none;
  background-color: #d89d19;
  background-image: -owg(compact(linear-gradient(center top, #d89d19, #e8b33c), false, false, false, false, false, false, false, false, false));
  background-image: -webkit(compact(linear-gradient(center top, #d89d19, #e8b33c), false, false, false, false, false, false, false, false, false));
  background-image: -moz(compact(linear-gradient(center top, #d89d19, #e8b33c), false, false, false, false, false, false, false, false, false));
  background-image: -o(compact(linear-gradient(center top, #d89d19, #e8b33c), false, false, false, false, false, false, false, false, false));
  background-image: compact(linear-gradient(center top, #d89d19, #e8b33c), false, false, false, false, false, false, false, false, false);
}

input[type='submit']:active, input[type='submit']:focus,
.node-readmore a:active,
.node-readmore a:focus {
  background-color: #d89d19;
}

button,
input,
textarea {
  font-size: 14px;
  font-size: 1.0769230769rem;
}

.form-type-checkbox label,
.form-type-radio label {
  font-size: 14px;
  font-size: 1.0769230769rem;
}

.form-actions input {
  margin-right: 1em;
}

body.page-node-add input[type='submit'] {
  width: auto;
}

input[value='Delete'],
input[value='Remove'] {
  background-color: #e02443;
  color: white;
  font-weight: normal;
  border: 1px solid #b71a34;
  -webkit-border-radius: 1.499994px;
  -moz-border-radius: 1.499994px;
  -ms-border-radius: 1.499994px;
  -o-border-radius: 1.499994px;
  border-radius: 1.499994px;
  -webkit-box-shadow: compact(rgba(255, 255, 255, 0.2) 0 0 8px inset, rgba(0, 0, 0, 0.05) 0 1px 2px, false, false, false, false, false, false, false, false);
  -moz-box-shadow: compact(rgba(255, 255, 255, 0.2) 0 0 8px inset, rgba(0, 0, 0, 0.05) 0 1px 2px, false, false, false, false, false, false, false, false);
  box-shadow: compact(rgba(255, 255, 255, 0.2) 0 0 8px inset, rgba(0, 0, 0, 0.05) 0 1px 2px, false, false, false, false, false, false, false, false);
  font-size: 13px;
  font-size: 1rem;
  text-transform: uppercase;
  text-shadow: 0 1px 1px #8a1427;
  text-align: center;
  display: inline-block;
  line-height: 19.5px;
  height: 19.5px;
  padding: 0 24px;
}

input[value='Delete']:hover,
input[value='Remove']:hover {
  text-decoration: none;
  background-color: #b71a34;
  background-image: -owg(compact(linear-gradient(center top, #b71a34, #e02443), false, false, false, false, false, false, false, false, false));
  background-image: -webkit(compact(linear-gradient(center top, #b71a34, #e02443), false, false, false, false, false, false, false, false, false));
  background-image: -moz(compact(linear-gradient(center top, #b71a34, #e02443), false, false, false, false, false, false, false, false, false));
  background-image: -o(compact(linear-gradient(center top, #b71a34, #e02443), false, false, false, false, false, false, false, false, false));
  background-image: compact(linear-gradient(center top, #b71a34, #e02443), false, false, false, false, false, false, false, false, false);
}

input[value='Delete']:active, input[value='Delete']:focus,
input[value='Remove']:active,
input[value='Remove']:focus {
  background-color: #b71a34;
}

input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='week'],
input[type='text'] {
  width: 120px;
}

input[type='email'],
input[type='url'],
#user-login .form-text,
.form-item-title input[type='text'] {
  width: 320px;
}

input[type='number'] {
  width: 60px;
}

.form-item-title .form-text {
  width: 100%;
}

/* Correct height of image inputs like inputs found in Paypal forms */
input[type='image'] {
  height: auto;
}

/**
 * 1. Fix cascading border from iput selector above in ie8
 */
input[type='radio'],
input[type='checkbox'] {
  margin-right: 6px;
  background: none;
  border: none;
  /* 1 */
  -webkit-box-shadow: compact(none, false, false, false, false, false, false, false, false, false);
  -moz-box-shadow: compact(none, false, false, false, false, false, false, false, false, false);
  box-shadow: compact(none, false, false, false, false, false, false, false, false, false);
}

html.js input.form-autocomplete {
  background-position: 99% 5px;
}

html.js input.throbbing {
  background-position: 99% -15px;
}

/*
 * 1. Improves usability and consistency of cursor style between image-type 'input' and others
 * 2. Corrects inability to style clickable 'input' types in iOS
 * 3. Removes inner spacing in IE7 without affecting normal text inputs
 *    Known issue: inner spacing remains in IE6
 */
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
  /* 1 */
  -webkit-appearance: button;
  /* 2 */
}

button:focus,
input[type='button']:focus,
input[type='reset']:focus,
input[type='submit']:focus {
  background-color: #e5aa25;
}

button,
input[type='button'],
input[type='reset'] {
  padding: 2px 10px;
}

textarea[disabled],
select[disabled],
input[disabled] {
  background-color: #eeeeee;
  color: #888888;
}

input[disabled] {
  border-color: #eeeeee;
  color: #888888;
  text-shadow: none;
  box-shadow: none;
}

input[disabled]:hover, input[disabled]:focus, input[disabled]:active {
  background-color: #eeeeee;
  color: #888888;
  border-color: #eeeeee;
  color: #888888;
  text-shadow: none;
  box-shadow: none;
}

/*
 * 1. Removes default vertical scrollbar in IE6/7/8/9
 * 2. Improves readability and alignment in all browsers
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}

/* Tables
   ========================================================================== */
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  /* Prevent cramped-looking tables */
  /* Add vertical rhythm margins. */
  margin: 30px 0;
}

tbody {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #fafafa transparent transparent transparent;
}

th {
  text-align: left;
  /* LTR */
  padding-right: 1em;
  /* LTR */
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: transparent transparent #fafafa transparent;
}

td {
  padding: 0.25em 0.5em;
}

tr.even {
  background: white;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #fafafa transparent #fafafa transparent;
}

tr.odd {
  background: transparent;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #fafafa transparent transparent transparent;
}

tr.drag {
  background: #fafafa transparent transparent transparent;
}

fieldset {
  /* Darken table when in a ui-block */
}

fieldset th {
  border-color: #f7f7f7;
}

fieldset tbody {
  border-color: #f7f7f7;
}

fieldset tr.even {
  background: #f7f7f7;
  border-color: #f7f7f7;
}

fieldset tr.odd {
  background: transparent;
  border-color: #f7f7f7;
}

fieldset tr.drag-previous {
  background-color: #fffce5;
}

fieldset tr.drag {
  background: #f7f7f7;
}

table .item-list ul {
  margin: 0;
}

td.menu-disabled {
  background: #ccc;
}

/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */
a.tabledrag-handle .handle {
  background-position: center center;
  height: 32px;
  margin: 0;
  padding: 0;
  width: 32px;
  display: block;
}

a.tabledrag-handle-hover .handle {
  background-position: center center;
}

.draggable a.tabledrag-handle {
  height: 1.7em;
  margin-left: 0;
  background-color: #f1f1f1;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}

.draggable a.tabledrag-handle:hover {
  background-color: #d1d1d1;
}

tr.drag {
  background-color: #fffff0;
}

tr.drag-previous {
  background-color: #fffce5;
}

.tabledrag-toggle-weight {
  font-size: 0.9em;
}

/**
 * TableSelect behavior.
 *
 * @see tableselect.js
 */
tr.selected td {
  background: #ffffcc;
}

/**
 * Progress bar.
 *
 * @see progress.js
 */
.progress {
  font-weight: bold;
}

.progress .bar {
  background: #cccccc;
  border-color: #666666;
  margin: 0 0.2em;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}

.progress .filled {
  background-color: #0072b9;
  background-image: image-url("progress.gif");
}

/* Buttons
    ========================================================================== */
.btn {
  background-color: #e8b33c;
  color: white;
  font-weight: normal;
  border: 1px solid #d89d19;
  -webkit-border-radius: 1.499994px;
  -moz-border-radius: 1.499994px;
  -ms-border-radius: 1.499994px;
  -o-border-radius: 1.499994px;
  border-radius: 1.499994px;
  -webkit-box-shadow: compact(rgba(255, 255, 255, 0.2) 0 0 8px inset, rgba(0, 0, 0, 0.05) 0 1px 2px, false, false, false, false, false, false, false, false);
  -moz-box-shadow: compact(rgba(255, 255, 255, 0.2) 0 0 8px inset, rgba(0, 0, 0, 0.05) 0 1px 2px, false, false, false, false, false, false, false, false);
  box-shadow: compact(rgba(255, 255, 255, 0.2) 0 0 8px inset, rgba(0, 0, 0, 0.05) 0 1px 2px, false, false, false, false, false, false, false, false);
  font-size: 13px;
  font-size: 1rem;
  text-transform: uppercase;
  text-shadow: 0 1px 1px #aa7c14;
  text-align: center;
  display: inline-block;
  line-height: 19.5px;
  height: 19.5px;
  padding: 0 24px;
}

.btn:hover {
  text-decoration: none;
  background-color: #d89d19;
  background-image: -owg(compact(linear-gradient(center top, #d89d19, #e8b33c), false, false, false, false, false, false, false, false, false));
  background-image: -webkit(compact(linear-gradient(center top, #d89d19, #e8b33c), false, false, false, false, false, false, false, false, false));
  background-image: -moz(compact(linear-gradient(center top, #d89d19, #e8b33c), false, false, false, false, false, false, false, false, false));
  background-image: -o(compact(linear-gradient(center top, #d89d19, #e8b33c), false, false, false, false, false, false, false, false, false));
  background-image: compact(linear-gradient(center top, #d89d19, #e8b33c), false, false, false, false, false, false, false, false, false);
}

.btn:active, .btn:focus {
  background-color: #d89d19;
}

/* Search
    ========================================================================== */
.search-results {
  padding-left: 0;
}

#search-form .form-item-keys label {
  display: block;
}

/* General Webform Styles
   ========================================================================== */
.webform-client-form {
  border-top: 5px solid #ffffff;
  border-bottom: 5px solid #e8b33c;
  background-color: #f1f1f1;
  padding: 24px;
}

.webform-client-form fieldset {
  background: none;
  border: none;
  padding: 0;
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 1em;
  margin-bottom: 1.5em;
}

.webform-client-form fieldset:before, .webform-client-form fieldset:after {
  content: '';
  display: table;
}

.webform-client-form fieldset:after {
  clear: both;
}

.webform-client-form fieldset legend {
  font-size: 1.143em;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #e36e5f;
  font-weight: bold;
}

.webform-client-form .form-item {
  margin: 0 0 20px 0;
}

.webform-client-form .form-actions {
  margin-bottom: 0;
  text-align: right;
}

.webform-client-form .form-actions input {
  margin-right: 0;
  margin-left: 16px;
}

.webform-client-form .webform-component-textfield,
.webform-client-form .webform-component-select {
  display: inline-block;
  margin-right: 8px;
}

.node-webform.view-mode-full .field-body {
  padding: 1em 0;
}

#webform-component-edit-form {
  /* adding spacing to webform submit bottom */
}

#webform-component-edit-form input [type='submit'] {
  margin-top: 1em;
}

/* Custom Search Pages ... /dashboard/parts_search */
.dashboard-search-result table .btn {
  font-size: 12px;
  font-size: 0.9230769231rem;
}

.dashboard-search-result tbody {
  border-bottom: 1px solid #ebebeb;
}

.dashboard-search-result th {
  background-color: #ebebeb;
}

.dashboard-search-result td,
.dashboard-search-result th {
  padding: 0.75em 1em;
}

.dashboard-search-result .btn {
  color: #000000;
  white-space: nowrap;
}

.dashboard-search-summary {
  font-size: 16px;
  font-size: 1.2307692308rem;
  text-transform: uppercase;
  margin: 0;
  color: #3c3b3b;
}

.dashboard-search-summary em {
  color: #e7b820;
  font-style: normal;
}

.dashboard-search-table th {
  white-space: nowrap;
}

.dashboard-search-table th:first-child {
  white-space: normal;
}

/* @partial navigation */
/* Main Navigation
   ========================================================================== */
#navigation {
  /* Main menu and secondary menu links and menu block links. */
}

#navigation .block {
  margin-bottom: 0;
}

#navigation .links,
#navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
  /* LTR */
}

#navigation .links li,
#navigation .menu li {
  /* A simple method to get navigation links to appear in one line. */
  /*float: left; [> LTR <]*/
  padding: 0 10px 0 0;
  /* LTR */
  margin: 0 0 7.5px 0;
  /* LTR */
  list-style-type: none;
  list-style-image: none;
}

#navigation .links li.last,
#navigation .menu li.last {
  margin-bottom: 0;
}

ul.menu li,
ul.menu li.menu__item {
  list-style-image: none;
  list-style-type: none;
}

/* Breadcrumb
   ========================================================================== */
.breadcrumb {
  font-size: 14px;
  font-size: 1.0769230769rem;
  font-size: .875em;
}

.breadcrumb ol {
  margin: 0;
  padding: 0;
}

.breadcrumb li {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* General Drupal Menus
   ========================================================================== */
.menu__item.is-leaf {
  list-style-image: inline-image("menu-leaf.png");
  list-style-type: square;
}

.menu__item.is-expanded {
  list-style-image: inline-image("menu-expanded.png");
  list-style-type: circle;
}

.menu__item.is-collapsed {
  list-style-image: inline-image("menu-collapsed.png");
  /* LTR */
  list-style-type: disc;
}

/* The active item in a Drupal menu. */
.menu a.active {
  color: #d89d19;
}

/* Pagination
   ========================================================================== */
/* A list of page numbers when more than 1 page of content is available. */
.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

.pager-item a,
.pager-first a,
.pager-previous a,
.pager-next a,
.pager-last a,
.pager-ellipsis a, .pager-current, .item-list .pager li.pager-current {
  list-style-type: none;
  -webkit-border-radius: 1.499994px;
  -moz-border-radius: 1.499994px;
  -ms-border-radius: 1.499994px;
  -o-border-radius: 1.499994px;
  border-radius: 1.499994px;
  color: white;
  text-transform: uppercase;
  padding: 4px 8px;
  float: left;
  -webkit-transition: compact(compact(padding 0.15s ease-in, false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(padding 0.15s ease-in, false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(padding 0.15s ease-in, false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(padding 0.15s ease-in, false, false, false, false, false, false, false, false, false);
}

.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  /* A concatenation of several list items using an ellipsis. */
}

.pager-item a,
.pager-first a,
.pager-previous a,
.pager-next a,
.pager-last a,
.pager-ellipsis a {
  background-color: #e8b33c;
}

.pager-item a:hover, .pager-item a :active,
.pager-first a:hover,
.pager-first a :active,
.pager-previous a:hover,
.pager-previous a :active,
.pager-next a:hover,
.pager-next a :active,
.pager-last a:hover,
.pager-last a :active,
.pager-ellipsis a:hover,
.pager-ellipsis a :active {
  background-color: #e5aa25;
  -webkit-border-radius: 1.499994px;
  -moz-border-radius: 1.499994px;
  -ms-border-radius: 1.499994px;
  -o-border-radius: 1.499994px;
  border-radius: 1.499994px;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
}

/* The current page's list item. */
.pager-current {
  background-color: #eeeeee;
  color: #3c3b3b;
}

.item-list .pager {
  /* A list of page numbers when more than 1 page of content is available */
  padding: 0;
  overflow: hidden;
}

.item-list .pager li {
  float: left;
  margin-right: 2px;
  list-style: none;
}

.item-list .pager li.pager-current {
  padding: 4px 8px;
}

.item-list {
  overflow: hidden;
}

/* Node edit tabs
   ========================================================================== */
ul.tabs-primary,
ul.tabs-secondary {
  overflow: hidden;
  list-style: none;
  margin: 1em 0 2.5em;
  padding: 0 2px;
  white-space: nowrap;
}

ul.tabs-primary li,
ul.tabs-secondary li {
  float: left;
  /* LTR */
  margin: 0.5em 3px 0;
}

ul.tabs-primary a, ul.tabs-primary a:link,
ul.tabs-secondary a,
ul.tabs-secondary a:link {
  display: block;
  text-decoration: none;
}

ul.tabs-primary li {
  margin: 0.5em 0 0 3px;
}

ul.tabs-primary li.is-active a {
  background-color: #e8b33c;
  color: #494949;
  border: 1px solid #d1d1d1;
}

ul.tabs-primary li a {
  font-size: 9.75px;
  text-transform: uppercase;
  background-color: #e8b33c;
  border: 1px solid #e8b33c;
  color: #ffffff;
  line-height: 26px;
  padding: 0 20px;
}

ul.tabs-primary li a:hover {
  text-decoration: none;
  background-color: #c18c17;
  border: 1px solid #d89d19;
}

ul.tabs-primary li a.active {
  background-color: #ffffff;
  color: #494949;
}

/* Menu Blocks
   ========================================================================== */
.block-menu-block h2.block-title {
  text-transform: uppercase;
  font-size: 13px;
  font-size: 1rem;
  line-height: 18px;
  margin-top: 18px;
  margin-top: 1.3846153846rem;
  margin-bottom: 0px;
}

.block-menu-block ul.menu ul.menu {
  padding-left: 7.5px;
}

.block-menu-block#block-menu-block-3 {
  padding-left: 7.5px;
  border-left: 2px solid #e8b33c;
  max-width: 200px;
}

/* @partial modular */
.block {
  margin-bottom: 30px;
  margin-top: 0;
}

.sidebar-block-dark {
  background: #3c3b3b;
  padding: 10px;
}

.sidebar-block-dark h3 {
  cursor: pointer;
  margin-top: 9px !important;
  margin-bottom: 9px !important;
  line-height: 18px !important;
  color: #ffffff;
  font-weight: 100;
  border-left: 1px solid #e8b33c;
  padding-left: 9px;
  font-weight: 100;
}

.sidebar-block-dark .item-list ul {
  margin-left: 0;
  padding-left: 0;
  margin: 0;
}

.sidebar-block-dark .item-list ul li {
  list-style-type: none;
  list-style-image: none;
}

.sidebar-block-dark .item-list ul li {
  font-size: 13px;
  font-size: 1rem;
  line-height: 18px;
  margin-bottom: 18px;
  margin-bottom: 1.3846153846rem;
  margin-top: 18px;
  margin-top: 1.3846153846rem;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 9px !important;
  margin-bottom: 9px !important;
  line-height: 18px !important;
  color: #ffffff;
  font-weight: 100;
  border-left: 1px solid #e8b33c;
  padding-left: 9px;
}

@media (min-width: 30em) {
  .sidebar-block-dark .item-list ul li {
    font-size: 16px;
    font-size: 1.2307692308rem;
    line-height: 36px;
    margin-bottom: 18px;
    margin-bottom: 1.3846153846rem;
    margin-top: 18px;
    margin-top: 1.3846153846rem;
  }
}

/* @partial node-pages */
.node-unpublished {
  /* Unpublished nodes */
  /* background-color: #fff4f4; */
  /* Drupal core uses a #fff4f4 background */
}

.node-unpublished p.unpublished {
  /* The word "Unpublished" displayed underneath the content. */
  height: 0;
  overflow: visible;
  color: #d8d8d8;
  font-size: 75px;
  line-height: 1;
  font-family: Impact, 'Arial Narrow', Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  word-wrap: break-word;
}

.field-name-field-pc-tagline {
  text-transform: uppercase;
}

.field-name-body {
  margin: 15px 0px;
}

/* @partial views-styles */
.primary-title {
  color: #e8b33c;
  text-transform: uppercase;
}

.secondary-title {
  text-transform: uppercase;
  margin-bottom: 0;
}

.view-sub-menu-links {
  padding-left: 7.5px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-left: 2px solid #e8b33c;
  margin-bottom: 30px;
  overflow: hidden;
}

.view-sub-menu-links.primary-title {
  margin-bottom: 15px;
}

.view-sub-menu-links .view-header h3 {
  line-height: 20px;
  margin: 0;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.view-sub-menu-links .views-row {
  display: inline-block;
}

.view-sub-menu-links .views-row .views-field {
  text-transform: uppercase;
}

.view-sub-menu-links .views-row .views-field:after {
  content: ' | ';
}

.view-sub-menu-links .views-row:last-child .views-field:after {
  content: '';
}

.view-product-category-listing .field-content {
  margin-bottom: 0;
}

.view-product-category-listing .views-field-title {
  text-transform: uppercase;
}

.view-product-category-listing.view-display-id-block_1 {
  padding-left: 0;
}

.view-product-category-listing.view-display-id-block_1 .views-row {
  overflow: hidden;
  border-top: 5px solid black;
}

.view-product-category-listing.view-display-id-block_1 .views-row:last-child {
  border-bottom: 5px solid black;
}

.view-product-category-listing.view-display-id-block_1 .views-row .views-field-field-pp-prim-image {
  width: 12.5%;
  clear: right;
  float: left;
  padding-left: 12px;
  padding-right: 12px;
}

.view-product-category-listing.view-display-id-block_1 .views-row .views-field-title,
.view-product-category-listing.view-display-id-block_1 .views-row .views-field-field-pp-tagline {
  width: 87.5%;
  clear: right;
  float: left;
  padding-left: 12px;
  padding-right: 12px;
}

.view-product-category-listing.view-display-id-block_2 .views-row {
  width: 25%;
  clear: right;
  float: left;
  padding-left: 12px;
  padding-right: 12px;
  text-align: center;
}

.view-product-category-listing.view-display-id-block_2 .views-row .views-field-field-prod-image {
  width: 100px;
  margin: 0 auto;
}

.view-product-category-listing.view-display-id-block_2 .views-row .views-field-title-field .field-content {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}

.view-product-category-listing.view-display-id-block_3 {
  padding: 30px 0;
}

.view-product-category-listing.view-display-id-block_3 .views-row {
  width: 31.9444444444%;
  clear: right;
  float: left;
  margin-left: 0.6944444444%;
  margin-right: 0.6944444444%;
  background: #333333;
  padding: 30px;
}

.view-product-category-listing.view-display-id-block_3 .views-row .views-field-title h4 {
  font-size: 24px;
  font-size: 1.8461538462rem;
  line-height: 36px;
  margin-bottom: 18px;
  margin-bottom: 1.3846153846rem;
  margin-top: 36px;
  margin-top: 2.7692307692rem;
  text-transform: uppercase;
  color: #e8b33c;
  font-weight: 100;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #e8b33c;
  font-weight: 100;
}

.view-product-category-listing.view-display-id-block_3 .views-row .views-field-field-pp-tagline {
  text-transform: uppercase;
}

.view-product-category-listing.view-display-id-block_3 .views-row .views-field-body,
.view-product-category-listing.view-display-id-block_3 .views-row .views-field-field-pp-tagline {
  color: #ffffff;
}

.view-product-category-listing.view-display-id-block_3 .views-row .views-field-view-node {
  margin-top: 15px;
}

.view-product-category-listing.view-display-id-block_3 .views-row .views-field-view-node a {
  font-weight: bold;
  background: #e8b33c;
  padding: 3.75px 5px;
  text-transform: uppercase;
  -webkit-transition: compact(compact(compact(background 0.3s ease-in-out false), false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(compact(background 0.3s ease-in-out false), false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(compact(background 0.3s ease-in-out false), false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(compact(background 0.3s ease-in-out false), false, false, false, false, false, false, false, false, false);
}

.view-product-category-listing.view-display-id-block_3 .views-row .views-field-view-node a:hover {
  color: #3c3b3b;
  background: #d89d19;
}

.region-content-bottom .view-product-listing .view-header {
  margin-bottom: 15px;
  border-top: 5px solid black;
  margin-top: 30px;
}

.region-content-bottom .view-product-listing .view-header h3 {
  margin-bottom: 0;
}

.region-content-bottom .view-product-listing .views-row {
  width: 16.6666666667%;
  clear: right;
  float: left;
  padding-left: 12px;
  padding-right: 12px;
}

.views-related-resources-block .views-field-edit-node {
  width: 50px;
}

.views-related-resources-block caption {
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: bold;
}

#block-views-related-resources-block {
  clear: both;
}

@media (min-width: 61.25em) {
  #block-views-related-resources-block table {
    display: none;
  }
}

#block-views-related-resources-block-1 {
  display: none;
}

@media (min-width: 61.25em) {
  #block-views-related-resources-block-1 {
    display: block;
  }
}

#block-views-related-ccr-block {
  float: left;
  margin-right: 20px;
  margin: 30px 0;
}

#block-views-related-ccr-block .views-field-nid a {
  font-weight: bold;
  text-transform: uppercase;
}

#block-views-related-ccr-block .views-field-nid a img {
  width: 12px;
  margin-right: 4px;
  margin-bottom: 2px;
}

#block-views-id-chart-block {
  margin: 30px 0;
}

#block-views-id-chart-block .views-field-field-pp-identification-chart a {
  font-weight: bold;
  text-transform: uppercase;
}

#block-views-id-chart-block .views-field-field-pp-identification-chart a img {
  width: 12px;
  margin-right: 4px;
  margin-bottom: 4px;
}

#block-views-related-resources-block-2 .item-list {
  position: relative;
  overflow: visible;
}

#block-views-related-resources-block-2 .item-list ul {
  display: none;
  position: absolute;
  background: #ffffff;
  right: 155px;
  width: 300px;
  padding: 18px;
  border: 1px solid #cccccc;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: compact(3px 3px #eee, false, false, false, false, false, false, false, false, false);
  -moz-box-shadow: compact(3px 3px #eee, false, false, false, false, false, false, false, false, false);
  box-shadow: compact(3px 3px #eee, false, false, false, false, false, false, false, false, false);
  font-weight: normal;
  top: -16px;
}

#block-views-related-resources-block-2 .item-list ul li {
  border-left: none;
  padding-left: 0;
}

#block-views-related-resources-block-2 .item-list ul li .field-content {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: #e8b33c;
}

#block-views-related-resources-block-2 .item-list ul:after {
  content: '▶';
  position: absolute;
  right: -10px;
  color: white;
  top: 16px;
  text-shadow: 2px 0 3px #888888;
}

.region-highlighted #home-slides {
  background-color: #000000;
}

.region-highlighted #home-slides .slide-copy {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  max-width: 100%;
  padding: 0 5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.region-highlighted #home-slides .slide-copy.has-alt-layout {
  justify-content: flex-start;
}

.region-highlighted #home-slides .slide-copy .slide-inner {
  max-width: 35%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 50px;
}

.region-highlighted #home-slides .slide-copy h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 26px;
  line-height: 31px;
}

.region-highlighted #home-slides .slide-copy h3 {
  margin-top: 15px;
  margin-bottom: 0;
  color: #ffffff;
}

.region-highlighted #home-slides .slide-copy .slide-body {
  font-size: 16px;
  margin-top: 20px;
  color: #ffffff;
}

.region-highlighted #home-slides .slide-copy .slide-body a {
  background: transparent;
  border: none;
  display: inline;
  padding: 0;
  text-transform: none;
  font-size: 15px;
  font-family: "ProximaNovaA-Regular";
}

.region-highlighted #home-slides .slide-copy .slide-body a:after {
  display: none;
}

.region-highlighted #home-slides .slide-copy a {
  font-size: 17px;
  font-size: 1.3076923077rem;
  background: transparent;
  border: 2px solid #ffffff;
  color: #e7b820;
  padding: 8px 80px 4px 12px;
  display: inline-block;
  text-align: left;
  transition: 0.5s all;
  text-transform: uppercase;
  line-height: 1;
  position: relative;
  margin-top: 30px;
  padding: 10px 80px 7px 12px;
  font-family: "TradeGothicLTStd-Extended";
  font-size: 15px;
}

.region-highlighted #home-slides .slide-copy a:after {
  font-size: 24px;
  color: #e7b820;
  position: absolute;
  right: 12px;
  top: 1px;
}

.region-highlighted #home-slides .slides li {
  padding: 0;
  margin: 0;
}

.region-highlighted #home-slides .slick-slide {
  background: #ccc;
}

.region-highlighted #home-slides .slick-slide img.background {
  /* Set rules to fill background */
  min-height: 100%;
  display: block;
  /* Set up proportionate scaling */
  width: 100%;
  height: auto;
  /* Seet up positioning */
  position: relative;
  top: 0;
  left: 0;
  min-width: 1400px;
  left: 50%;
  margin-left: -700px;
  /* 50% */
}

.home-slides-nav {
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.home-slides-nav .slick-prev,
.home-slides-nav .slick-next {
  box-shadow: none;
  background: none;
  border: none;
  color: #ffffff;
}

.home-slides-nav .slick-prev:before,
.home-slides-nav .slick-next:before {
  position: relative;
  top: -2px;
  font-size: 42px;
  line-height: 30px;
}

.home-slides-nav .slick-prev {
  order: 1;
}

.home-slides-nav .slick-dots {
  order: 2;
}

.home-slides-nav .slick-next {
  order: 3;
}

.home-slides-nav .slick-dots {
  display: block;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.home-slides-nav .slick-dots li {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.home-slides-nav .slick-dots li button {
  font-size: 0;
  line-height: 0;
  width: 30px;
  height: 30px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: none;
  border: 1px solid transparent;
  border-radius: 50%;
  transition: all 0.2s ease-out;
}

.home-slides-nav .slick-dots li button:hover, .home-slides-nav .slick-dots li button:focus {
  outline: none;
  border-color: #ffffff;
}

.home-slides-nav .slick-dots li button span {
  border: 1px solid #e7b820;
  padding: 2px;
  display: inline-block;
  border-radius: 50%;
}

.home-slides-nav .slick-dots li.slick-active button {
  border-color: #ffffff;
}

.home-slides-nav .slick-dots li.slick-active button:before {
  opacity: .75;
  color: black;
}

.home-video-slider {
  position: relative;
  color: #e7b820;
  margin: 0;
}

.home-video-slider .block {
  margin: 0;
}

.home-video-slider video {
  display: block;
}

.home-video-slider .field-name-field-industry-home-description {
  color: #fff;
}

.industry-video {
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
}

.industry-video video {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.industries-menu {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 5%;
  width: 25%;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.industries-menu .industries-slogan p {
  font-size: 16px;
}

.industries-menu-list {
  margin: 0;
  padding: 0;
}

.industries-menu-list a {
  font-size: 13px;
  font-size: 1rem;
  background: transparent;
  border: 2px solid #ffffff;
  color: #e7b820;
  padding: 8px 80px 4px 12px;
  display: inline-block;
  text-align: left;
  transition: 0.5s all;
  text-transform: uppercase;
  line-height: 1;
  position: relative;
  width: 100%;
  background: linear-gradient(to right, white 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: none;
  font-size: 16px;
  font-family: "TradeGothicLTStd-Extended";
  padding: 12px 80px 8px 12px;
  background-color: rgba(0, 0, 0, 0.3);
}

.industries-menu-list a:after {
  font-size: 20px;
  color: #e7b820;
  position: absolute;
  right: 12px;
  top: 1px;
}

.industries-menu-list a:after {
  top: 7px;
}

.industries-menu-list a.is-active-video {
  transition: all 0.2s ease-out;
}

@media (max-width: 47.9375em) {
  .industries-menu-list a.is-active-video {
    background-color: #ffffff;
  }
}

@media (min-width: 48em) {
  .industries-menu-list a.is-active-video {
    transition-duration: 5s;
    background-position: left bottom;
  }
}

.industries-menu-list li {
  list-style-type: none;
  list-style-image: none;
}

.industries-slogan {
  color: #ffffff;
}

.industry-description {
  position: absolute;
  top: 0;
  left: 5%;
  width: 50%;
  bottom: 100px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.industry-description h2 {
  margin: 0;
  font-size: 25px;
}

.industry-description a {
  font-size: 17px;
  font-size: 1.3076923077rem;
  background: transparent;
  border: 2px solid #ffffff;
  color: #e7b820;
  padding: 8px 80px 4px 12px;
  display: inline-block;
  text-align: left;
  transition: 0.5s all;
  text-transform: uppercase;
  line-height: 1;
  position: relative;
  font-size: 16px;
  padding: 10px 80px 8px 12px;
  margin-top: 30px;
  font-family: "TradeGothicLTStd-Extended";
}

.industry-description a:after {
  font-size: 24px;
  color: #e7b820;
  position: absolute;
  right: 12px;
  top: 1px;
}

.industry-description a:after {
  top: 4px;
}

.industry-description .field-name-field-industry-home-description {
  text-transform: uppercase;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.industry-description-inner {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 50px;
}

.industry-background {
  width: 100%;
}

.home-video-slider {
  background-color: #000000;
}

@media (min-width: 48em) {
  .home-video-slider .field-name-field-industry-home-fallback-img {
    display: none;
  }
}

.home-video-slider video {
  display: none;
}

@media (min-width: 48em) {
  .home-video-slider video {
    display: block;
  }
}

.front .section {
  position: relative;
  background-size: cover;
}

.front .section hr {
  display: none;
}

.front .section .copy {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  text-align: center;
  padding: 30px;
}

.front .section .copy .caption h3 {
  color: #454545;
  font-weight: 100;
}

.front .section .copy .links li a {
  font-size: 13px;
  font-size: 1rem;
  line-height: 18px;
  margin-bottom: 18px;
  margin-bottom: 1.3846153846rem;
  margin-top: 18px;
  margin-top: 1.3846153846rem;
  text-transform: uppercase;
}

@media (min-width: 30em) {
  .front .section .copy .links li a {
    font-size: 16px;
    font-size: 1.2307692308rem;
    line-height: 36px;
    margin-bottom: 18px;
    margin-bottom: 1.3846153846rem;
    margin-top: 18px;
    margin-top: 1.3846153846rem;
  }
}

.front .section.small .title h1 {
  font-size: 24px;
  font-size: 1.8461538462rem;
  line-height: 36px;
  margin-bottom: 18px;
  margin-bottom: 1.3846153846rem;
  margin-top: 36px;
  margin-top: 2.7692307692rem;
  text-transform: uppercase;
  color: #e8b33c;
  font-weight: 100;
}

.front .section.dark .caption h3 {
  color: #ffffff;
}

.front .section.dark .links li a {
  color: #ffffff;
}

.front .section.dark .links li a:hover, .front .section.dark .links li a.active, .front .section.dark .links li a.active-trail {
  color: #e8b33c;
}

.front #solutions {
  min-height: 500px;
  overflow: hidden;
  background: none;
}

.front #solutions .copy {
  max-width: 800px;
  margin: 0px auto;
  padding: 0px;
}

.front #solutions .copy .title,
.front #solutions .copy .caption {
  padding: 0px 30px 0px 30px;
}

@media (min-width: 30em) {
  .front #solutions .title h1 {
    line-height: 55px;
  }
  .front #solutions .title h1:after {
    content: ' ';
    position: absolute;
    width: 50%;
    height: 0px;
    box-shadow: black 0px 0px 100px 15px;
    z-index: -1;
    left: 25%;
  }
}

.front #solutions .links {
  width: 300px;
  margin: 0px auto;
}

@media (min-width: 37.5em) {
  .front #solutions .links {
    width: 580px;
  }
}

.front #solutions .links li {
  float: left;
  width: 75px;
  height: 75px;
  margin: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  display: block;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.7);
}

@media (min-width: 37.5em) {
  .front #solutions .links li {
    width: 150px;
    height: 150px;
    margin: 20px;
  }
}

.front #solutions .links li.first {
  border: 1px solid rgba(232, 179, 60, 0.7);
}

.front #solutions .links li.first a {
  background: rgba(232, 179, 60, 0.7);
}

.front #solutions .links li.first a:hover {
  background: rgba(232, 179, 60, 0.9);
}

.front #solutions .links li a {
  width: 67px;
  height: 67px;
  top: 3px;
  left: 3px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: compact(compact(compact(background 0.2s ease-in false), false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(compact(background 0.2s ease-in false), false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(compact(background 0.2s ease-in false), false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(compact(background 0.2s ease-in false), false, false, false, false, false, false, false, false, false);
  position: absolute;
  display: table;
  text-align: center;
  margin: 0px;
  color: #3c3b3b;
  background: rgba(255, 255, 255, 0.7);
  line-height: 20px;
}

@media (min-width: 37.5em) {
  .front #solutions .links li a {
    width: 142px;
    height: 142px;
  }
}

.front #solutions .links li a:hover {
  background: rgba(255, 255, 255, 0.9);
}

.front #solutions .links li a div {
  display: table-cell;
  vertical-align: middle;
}

@media (min-width: 48em) {
  .front #provider {
    min-height: 500px;
  }
}

@media (min-width: 48em) {
  .front #provider .copy {
    padding-top: 100px;
  }
}

.front #provider hr {
  display: block;
  width: 45%;
  margin: 0px auto;
  border: 0;
  height: 1px;
  background-color: #e8b33c;
}

.front #provider .links li a {
  position: relative;
  white-space: nowrap;
  -webkit-transition: compact(compact(compact(color 0.2s ease-in false), false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(compact(color 0.2s ease-in false), false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(compact(color 0.2s ease-in false), false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(compact(color 0.2s ease-in false), false, false, false, false, false, false, false, false, false);
}

.front #provider .links li a:after {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: compact(compact(compact(opacity 0.2s ease-in false), false, false, false, false, false, false, false, false, false) false false);
  -moz-transition: compact(compact(compact(opacity 0.2s ease-in false), false, false, false, false, false, false, false, false, false) false false false);
  -o-transition: compact(compact(compact(opacity 0.2s ease-in false), false, false, false, false, false, false, false, false, false) false false false);
  transition: compact(compact(opacity 0.2s ease-in false), false, false, false, false, false, false, false, false, false);
  content: url("/sites/all/themes/armstrong_zen/images/provider-underscore.png");
  position: absolute;
  left: -72px;
  margin-left: 50%;
  top: 4px;
}

.front #provider .links li a:hover:after {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

@media (min-width: 48em) {
  .front #worldwide {
    min-height: 904px;
    margin-top: -55px;
  }
}

.front #worldwide .copy {
  text-align: left;
}

@media (min-width: 48em) {
  .front #worldwide .copy {
    padding-top: 600px;
    max-width: 960px;
  }
}

@media (min-width: 48em) {
  .front #tbd {
    min-height: 459px;
  }
}

.front #tbd .copy {
  text-align: left;
}

@media (min-width: 48em) {
  .front #tbd .copy {
    max-width: 400px;
    padding-top: 144px;
  }
}

.front #tbd .links {
  padding-top: 0px;
  padding-bottom: 0px;
}

.front #tbd .links ul.inline li {
  padding: 0px;
}

.front #tbd .links ul.inline li a {
  width: 150px;
  background: #e8b33c;
  color: #3c3b3b;
  padding: 3px 18px;
  display: block;
  margin: 0px;
  text-align: center;
}

@media (min-width: 48em) {
  .front #knowledge {
    min-height: 851px;
  }
}

.front #knowledge .copy {
  text-align: left;
}

@media (min-width: 48em) {
  .front #knowledge .copy {
    padding-top: 300px;
    max-width: 600px;
  }
}

@media (min-width: 48em) {
  .front #service {
    min-height: 500px;
  }
}

@media (min-width: 48em) {
  .front #service .copy {
    padding-top: 100px;
  }
}

@media (min-width: 48em) {
  .front #you {
    min-height: 850px;
  }
}

.front #you .copy {
  text-align: left;
}

@media (min-width: 48em) {
  .front #you .copy {
    padding-top: 300px;
    max-width: 600px;
  }
}

.front .region-highlighted {
  max-width: 1400px;
  margin: 0px auto;
}

.wys-row {
  clear: both;
  overflow: hidden;
}

.wys-row .wys-one-half {
  width: 50%;
  clear: right;
  float: left;
  padding-left: 12px;
  padding-right: 12px;
}

.wys-row .wys-one-third {
  width: 33.3333333333%;
  clear: right;
  float: left;
  padding-left: 12px;
  padding-right: 12px;
}

.wys-row .wys-two-thirds {
  width: 66.6666666667%;
  clear: right;
  float: left;
  padding-left: 12px;
  padding-right: 12px;
}

.front #sticky-contents {
  display: none !important;
}

.front #solutions .title h1:after {
  box-shadow: none;
}

#navigation .region-navigation .block-title,
#navigation .region-navigation a:hover {
  color: #e8b33c;
}

.scroll-down {
  text-align: center;
  position: fixed;
  bottom: 20px;
  left: 48%;
  transition: 1s all;
  z-index: 100;
}

.flex-direction-nav a {
  text-shadow: none;
}

.flex-direction-nav a:before {
  font-size: 32px;
  color: #ffffff;
}

.region-highlighted .flex-control-container .flex-direction-nav .flex-prev:hover {
  opacity: 0.5;
}

.region-highlighted .flex-control-container .flex-direction-nav .flex-next:hover {
  opacity: 0.5;
}

.front #solutions {
  min-height: 700px;
}

.front #worldwide {
  min-height: 800px;
  margin-top: 0;
}

.front #provider {
  height: 500px;
  margin-top: 0;
  transition: 1s all;
}

.front #ad-series {
  height: 500px;
  margin-top: 0;
  transition: 1s all;
  width: 100%;
}

.front #ad-series .links ul.inline li a {
  width: 180px;
  background: #e7b820;
  color: #3c3b3b;
  padding: 6px 18px 3px 18px;
  display: block;
  margin: 0px auto;
  text-align: center;
  transition: 0.5s all;
}

.front #ad-series .links ul.inline li a:hover {
  background: #3c3b3b;
  color: #FFF;
}

.front #ad-series .title h1 {
  margin-top: 100px;
}

.front #provider .copy {
  text-shadow: 1px 1px 3px #2d2d2d;
}

.front #tbd {
  height: 500px;
  margin-top: 0;
  transition: 1s all;
}

.front #knowledge {
  height: 800px;
  margin-top: 0;
  min-height: 800px;
}

.front #service {
  height: 500px;
  margin-top: 0;
  transition: 1s all;
}

.front #you {
  height: 800px;
  margin-top: 0 !important;
}

.front #you .copy {
  padding: 280px 0 0 50px;
  max-width: 100%;
}

.front #register {
  height: 500px;
  margin-top: 0;
  transition: 1s all;
}

.front #register .copy {
  padding: 80px 200px;
  width: 100%;
  margin: 0 auto;
}

.front #register .links ul.inline li a {
  width: 180px;
  background: #e7b820;
  color: #3c3b3b;
  padding: 6px 18px 3px 18px;
  display: block;
  margin: 0px auto;
  text-align: center;
  transition: 0.5s all;
}

.front #register .links ul.inline li a:hover {
  color: #e7b820;
  background: #3b3c3c;
}

.front #register .copy .caption h3 {
  color: #FFFFFF;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
}

.front #register .copy .body a {
  color: #e7b820;
  font-weight: normal;
  font-family: "ProximaNovaA-Regular";
  font-size: 14px;
}

.front #register .copy .body a:hover {
  color: #FFFFFF;
}

.front .section .image picture {
  width: 100%;
  left: 0;
  margin-left: 0px;
}

.front .section .image picture img {
  width: 100%;
}

.front .region-highlighted {
  max-width: 100%;
}

.region-highlighted #home-slides .slide picture {
  margin-left: -50%;
}

.region-highlighted #home-slides .slide img {
  width: 100%;
}

.backgroundsize .front .section {
  background-size: cover !important;
}

.backgroundsize .front .section .image {
  display: block;
  position: absolute;
  min-width: 100%;
}

.front #solutions .links li a {
  padding-top: 63px;
}

.front #solutions .links li.first a {
  background: rgba(255, 255, 255, 0.7);
}

.front #solutions .links li.first {
  border: 1px solid rgba(255, 255, 255, 0.7);
}

.front #solutions .links li:hover {
  border: 1px solid rgba(232, 179, 60, 0.9);
}

.front #solutions .links li a:hover {
  background: rgba(232, 179, 60, 0.9);
}

.front #provider h3 .caption {
  display: none;
}

.front #worldwide .copy {
  padding: 0px 0 0 100px;
  max-width: 100%;
}

.front #worldwide .copy .title {
  padding-top: 450px;
  max-width: 50%;
  text-shadow: 1px 1px 1px #6B6B6B;
}

.front #worldwide .copy .title h1 {
  line-height: 45px;
}

.front .section .copy .links li a {
  font-size: 17px;
  line-height: 20px;
  line-height: 25px;
  font-family: "ProximaNovaA-Regular";
}

.front #worldwide .links ul.inline li a, .front #tbd .links ul.inline li a, .front #knowledge .links ul.inline li a, .front #you .links ul.inline li a {
  width: 180px;
  background: #e7b820;
  color: #3c3b3b;
  padding: 6px 18px 3px 18px;
  display: block;
  margin: 0px;
  text-align: center;
  transition: 0.5s all;
}

.front #worldwide .links ul.inline li a:hover, .front #tbd .links ul.inline li a:hover, .front #knowledge .links ul.inline li a:hover, .front #you .links ul.inline li a:hover {
  color: #e7b820;
  background: #3b3c3c;
}

.front #service .links ul.inline li a {
  width: 180px;
  background: #e7b820;
  color: #3c3b3b;
  padding: 6px 18px 3px 18px;
  display: block;
  margin: 0px auto;
  text-align: center;
  transition: 0.5s all;
}

.front #service .links ul.inline li a:hover {
  background-color: #3c3b3b;
  color: #e7b820;
}

.front .section.small .title h1 {
  font-size: 28px;
  margin-top: 60px;
  margin-top: 5rem;
}

.front #provider .links li a:after {
  top: 12px;
}

.front #solutions .title h1 {
  line-height: 55px;
  text-shadow: 1px 1px 6px black;
  letter-spacing: -1px;
  font-size: 40px;
  font-size: 3.1rem;
}

.front .section .copy .caption h3 {
  font-family: "ProximaNovaA-Regular";
  font-size: 1.3rem;
  font-size: 17px;
  line-height: 22px;
}

.front #solutions .caption h3 {
  text-shadow: 1px 1px 3px #2d2d2d;
}

.front #you .copy .caption h3 {
  color: #FFFFFF;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
}

.front #service .copy {
  padding: 80px 200px;
  width: 100%;
  margin: 0 auto;
}

.front #service .copy h1 {
  text-shadow: 1px 1px 3px #2d2d2d;
}

.front #service .copy .caption, .front #service .copy .links {
  position: relative;
}

.front #service .copy .caption {
  text-shadow: 1px 1px 3px #2d2d2d;
}

.front #tbd .copy {
  max-width: 100%;
  padding: 70px 0 0 100px;
}

.front #tbd .copy .title {
  max-width: 500px;
  padding-top: 0px;
}

.front #tbd .copy .body {
  max-width: 400px;
  padding-top: 0px;
}

.front #tbd .links, .front #knowledge .links, .front #you .links, .front #worldwide .links {
  position: relative;
}

.front #knowledge .copy {
  padding: 0 100px 0 100px;
  max-width: 100%;
}

.front #knowledge .copy .title {
  padding-top: 280px;
  max-width: 50%;
  text-shadow: 1px 1px 1px #6B6B6B;
}

.front #knowledge .copy .title h1 {
  line-height: 45px;
}

.front #knowledge .copy .body .caption {
  max-width: 50%;
}

.front #you .copy .title, .front #you .copy .body .caption {
  max-width: 50%;
  position: relative;
}

/* HOME PAGE SLIDER */
.flex-control-nav {
  bottom: 20px;
  z-index: 100;
}

.region-highlighted #home-slides .slide img.background {
  min-width: 1400px;
  left: 0;
  margin-left: 0;
}

.front .copy .title {
  z-index: 2;
  position: relative;
  padding-top: 36px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
}

.front #provider ul.links li:last-child {
  padding-right: 0;
}

.front #provider .colorize {
  opacity: 0;
  width: 100%;
  height: 500px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("/sites/default/files/uploads/02-industries-01-colorized.jpg");
  transition: 2s all;
  background-size: cover;
}

.front #worldwide .airplane {
  width: 100%;
  height: 800px;
  position: absolute;
  top: -150px;
  left: 1000px;
  background-image: url("/sites/default/files/uploads/03-global-02.png");
  transition: 2s all;
  background-size: cover;
}

.front #worldwide {
  transition: 1s all;
}

.front #tbd .device {
  width: 100%;
  height: 500px;
  position: absolute;
  top: 500px;
  left: 0px;
  background-image: url("/sites/default/files/uploads/04-sage-02.png");
  transition: 2s all;
  background-size: cover;
}

.front #knowledge .charts {
  width: 100%;
  height: 800px;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: 3s all;
  opacity: 0;
  background-size: cover;
}

.front #knowledge .charts1 {
  background-image: url("/sites/default/files/uploads/knowledge-head1.png");
}

.front #knowledge .charts2 {
  background-image: url("/sites/default/files/uploads/knowledge-head2.png");
  -webkit-transition-delay: 1s;
  transition-delay: 1s;
}

.front #knowledge .charts3 {
  background-image: url("/sites/default/files/uploads/knowledge-head3.png");
  -webkit-transition-delay: 2s;
  transition-delay: 2s;
}

.front #knowledge .charts4 {
  background-image: url("/sites/default/files/uploads/knowledge-head4.png");
  -webkit-transition-delay: 3s;
  transition-delay: 3s;
}

.front #knowledge .charts5 {
  background-image: url("/sites/default/files/uploads/knowledge-head5.png");
  -webkit-transition-delay: 4s;
  transition-delay: 4s;
}

.front #service .colorize {
  opacity: 0;
  width: 100%;
  height: 500px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("/sites/default/files/uploads/06-service-01-colorized-new.jpg");
  transition: 2s all;
  background-size: cover;
}

.front #you .colorize {
  opacity: 0;
  width: 100%;
  height: 850px;
  position: absolute;
  top: 0;
  left: 0;
  transition: 2s all;
  background-image: url("/sites/default/files/uploads/07-customer-01-colorized-newest.jpg");
  background-size: cover;
}

.front #main-wrapper {
  margin-top: 0;
}

.front #solutions .copy {
  position: relative;
}

/* MEDIA QUERIES */
@media (min-width: 1400px) {
  .header-image img {
    margin-left: -50%;
  }
}

@media (max-width: 1600px) {
  .front #solutions {
    min-height: 550px;
  }
}

@media (min-width: 1600px) {
  .front #knowledge, .front #knowledge .charts, .front #worldwide {
    height: 1000px;
    min-height: 1000px;
  }
  .front #worldwide .airplane {
    height: 1100px;
    top: -140px;
  }
}

@media (max-width: 1200px) {
  .front #worldwide .airplane {
    height: 686px;
    top: -125px;
    left: 1000px;
  }
  .front #worldwide .copy .title {
    padding-top: 400px;
  }
  .front #worldwide {
    min-height: 750px;
  }
  .front #worldwide .copy {
    padding: 0px 0 0 50px;
  }
  .front #tbd .copy {
    padding: 100px 0 0 50px;
  }
  .front #knowledge .copy {
    padding: 0 100px 0 50px;
  }
  .front #knowledge {
    height: 700px;
    min-height: 700px;
  }
  .front #knowledge .charts {
    height: 720px;
  }
  .front #service .copy {
    padding: 80px 150px;
  }
  .front #you .colorize {
    height: 700px;
  }
  .front #you {
    min-height: 700px;
    height: 700px;
  }
  .front #register .copy {
    padding: 50px 100px;
  }
  .front #solutions .title h1 {
    line-height: 47px;
    font-size: 37px;
  }
  h1 {
    font-size: 37px;
  }
  .front #solutions {
    min-height: 500px;
  }
  .front #solutions .links li a {
    width: 122px;
    height: 122px;
  }
  .front #solutions .links li {
    width: 130px;
    height: 130px;
    margin: 20px;
  }
  .front #solutions .links li a {
    padding-top: 53px;
    font-size: 15px;
  }
  .front #solutions .links {
    width: 510px;
  }
}

@media (max-width: 980px) {
  .front #solutions .title h1 {
    line-height: 40px;
    font-size: 32px;
  }
  .front #solutions .links li a {
    width: 100px;
    height: 100px;
  }
  .front #solutions .links li {
    width: 108px;
    height: 108px;
    margin: 0 15px;
  }
  .front #solutions .links li a {
    padding-top: 42px;
    font-size: 14px;
  }
  .front #solutions .links {
    width: 414px;
  }
  .front #provider {
    min-height: 400px;
    height: 400px;
    background-position: right;
  }
  .front #provider .colorize {
    height: 400px;
  }
  .front #provider .copy {
    padding-top: 20px;
  }
  .front .section .copy .links li a {
    font-size: 15px;
    line-height: 20px;
  }
  .front #worldwide {
    min-height: 600px;
  }
  .front #worldwide .airplane {
    height: 600px;
    top: -110px;
    left: 1000px;
  }
  .front #worldwide .copy .title {
    padding-top: 200px;
  }
  .front #worldwide .copy .caption {
    max-width: 50%;
  }
  h1 {
    font-size: 30px;
  }
  .front #tbd .copy {
    padding: 50px 0 0 50px;
  }
  .front #knowledge {
    height: 650px;
    min-height: 650px;
  }
  .front #knowledge .charts {
    height: 650px;
  }
  .front #knowledge .copy .title {
    padding-top: 175px !important;
  }
  .front .copy .title {
    padding-top: 0;
  }
  .front .section.small .title h1 {
    font-size: 23px;
  }
  .front .section .copy .caption h3 {
    font-size: 15px;
    line-height: 20px;
  }
  .front #register, .front #service .colorize, .front #service, .front #tbd .device, .front #tbd {
    height: 400px;
    min-height: 400px;
  }
  .front #register .copy, .front #service .copy {
    padding: 50px;
  }
}

@media (max-width: 767px) {
  .front #service .colorize {
    display: none;
  }
  #home-slides {
    top: 0px !important;
  }
  .front #knowledge .charts {
    display: none;
  }
  .front #tbd .device {
    top: 0;
  }
  .front #worldwide .airplane {
    height: 500px;
    top: -80px;
    left: 0px;
  }
  .front #knowledge .copy .title {
    padding-top: 100px;
    max-width: 100%;
  }
  .front #knowledge .copy {
    text-align: center;
  }
  .front #knowledge .copy .body .caption {
    max-width: 100%;
  }
  .front #knowledge .links {
    text-align: -webkit-center;
    text-align: center;
    width: 180px;
    margin: 0 auto;
  }
  .front #worldwide .copy .title {
    padding-top: 150px;
  }
  .front #worldwide .copy .title h1, .front #knowledge .copy .title h1, h1 {
    line-height: 35px;
  }
  .front .section.small .title h1 {
    font-size: 20px;
    margin-top: 10px;
    line-height: 28px;
  }
  .front #provider .copy {
    padding-top: 70px;
  }
  .front #you {
    min-height: 600px;
    height: 600px;
    background-position: right;
  }
  .front #you .colorize {
    height: 600px;
  }
  .front .section .copy .caption h3 {
    font-size: 14px;
    line-height: 20px;
  }
  #search-field input, #search-wrapper {
    width: 100%;
  }
}

@media (max-width: 599px) {
  .front #worldwide .copy, .front #tbd .copy, .front #you .copy {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
  .front #worldwide .airplane {
    height: 400px;
    top: -50px;
  }
  .front #solutions .title h1 {
    line-height: 35px;
    font-size: 28px;
  }
  .front #worldwide .copy .title h1, .front #knowledge .copy .title h1, h1 {
    line-height: 30px;
    font-size: 24px;
  }
  .front #worldwide .copy .title {
    padding-top: 100px;
    max-width: 100%;
  }
  .front #knowledge .copy {
    padding: 0 20px;
  }
  .front #register .copy, .front #service .copy {
    padding: 50px 20px;
  }
  .front #worldwide .copy .caption {
    max-width: 100%;
  }
  .front #tbd .links, .front #knowledge .links, .front #you .links, .front #worldwide .links {
    position: relative;
    text-align: center;
    text-align: -webkit-center;
  }
  .front #you .copy .title, .front #you .copy .body .caption {
    max-width: 100%;
    position: relative;
  }
  .front .section.small .title h1 {
    font-size: 17px;
    margin-top: 10px;
    line-height: 23px;
  }
  .front #solutions .links li a {
    width: 78px;
    height: 78px;
  }
  .front #solutions .links li {
    width: 86px;
    height: 86px;
    margin: 0 15px;
  }
  .front #solutions .links li a {
    padding-top: 31px;
    font-size: 12px;
  }
  .front #solutions .links {
    width: 354px;
  }
  .search-activate #search-button span, #toggle-nav .text {
    font-size: 13px;
  }
  .scroll-down {
    left: 45%;
  }
  .front #solutions .links li {
    margin: 0px 5px;
  }
  .front #solutions .links {
    width: 320px;
  }
}

.page-node-368218 .views-row.views-row-8.views-row-even,
.page-node-368218 .views-row.views-row-9.views-row-odd.views-row-last {
  display: none;
}

.view-career-postings table {
  margin: 0;
}

.view.view-career-postings {
  border: 5px solid #3b3c3c;
  padding: 30px;
  margin: 16px 0;
}

.node-type-career-post .webform-client-form {
  margin: 16px 0;
}

.region-btn a:hover {
  color: #ffffff;
}

.region-btn {
  width: 100%;
  height: 100px;
  background-size: cover;
  text-align: left;
  margin-bottom: 16px;
  padding: 1px 16px;
}

.region-btn a.btn {
  display: inline-block;
  height: auto;
}

.region-btn.na {
  background-image: url(/sites/default/files/uploads/arm-na-texture.jpg);
}

.region-btn.in {
  background-image: url(/sites/default/files/uploads/arm-india-texture.jpg);
}

.region-btn.row {
  background-image: url(/sites/default/files/uploads/arm-map-texture.jpg);
}

mark.unpublished {
  width: 100%;
  height: 35px;
  display: block;
  background: #9e4949;
  text-align: center;
  margin: 0 auto;
  padding: 0 0 0 30px;
  line-height: 40px;
  text-indent: 0;
  text-transform: uppercase;
  text-align: left;
  color: #ffffff;
}

.view-product-category-listing.view-display-id-block_3 .views-row-10,
.view-product-category-listing.view-display-id-block_3 .views-row-11 {
  display: none;
}

.views-row.views-row-8.views-row-even.views-row-last {
  display: none;
}

.view-sub-menu-links .views-row.views-row-8.views-row-even.views-row-last {
  display: inline-block;
}

#block-menu-block-8 .menu-mlid-27334 ul.menu {
  display: none;
}

.ProductSelectors label:after {
  content: '*';
  color: red;
  padding-left: 3px;
}

.ProductSelectors label {
  font-family: ProximaNovaA-Regular;
  text-transform: none;
}

.btn {
  background-color: #e8b33c;
  color: #ffffff;
  font-weight: normal;
  border: 1px solid #e8b33c;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 13px;
  font-size: 1rem;
  text-transform: uppercase;
  text-shadow: none;
  text-align: center;
  display: inline-block;
  line-height: 19px;
  padding: 5px 20px 3px;
  height: inherit;
}

.btn:hover {
  color: #000000;
}

.row .col-6 {
  width: 45%;
  padding: 30px 0;
}

.row .col-6.right {
  float: right;
}

.row .col-6.left {
  float: left;
}

/*NEW PRODUCT PAGE STYLES */
.field-item ul.in-page-nav {
  margin: 30px 0;
  padding: 0;
  list-style: none;
}

.field-item ul.in-page-nav li {
  display: inline-block;
  margin-bottom: 5px;
  padding-bottom: 0;
}

.field-item ul.in-page-nav li a {
  border-right: 1px solid #999999;
  padding-right: 10px;
  margin-right: 10px;
}

.field-item ul.in-page-nav li:last-child a {
  border-right: none;
  padding-right: 0;
  margin-right: 0;
}

.availability-in-listing,
.descriptor-in-listing,
.subhead-in-listing {
  padding-left: 10px;
  width: 80%;
  float: right;
}

.field.field-name-field-availability {
  text-transform: uppercase;
}

.hwfi-wrap {
  background-image: url("/sites/default/files/uploads/hwf1temp.jpg");
  background-size: cover;
  width: 100%;
  height: auto;
  padding: 50px 50px 70px 50px;
  margin-top: 30px;
}

.field-item ul.hw-subnav {
  list-style: none;
  padding: 0;
  width: 100%;
  margin: 35px 0 0 0;
}

.field-item ul.hw-subnav li {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.field-item .hw-subnav li a {
  border-right: 1px solid #e8b33c;
  display: block;
  padding: 0 10px;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 16px;
  font-size: 16px;
}

.field-item .hw-subnav li:first-child a {
  padding: 0 10px 0 0;
}

.field-item .hw-subnav li a:hover {
  color: #e8b33c;
}

.field-item .hw-subnav li:last-child a {
  border-right: none;
  padding-right: 0;
}

#content .secondary-title {
  margin-bottom: 20px;
}

/*TEMPORARY RULES TO REMOVE UNWANTED ELEMENTS FROM DISPLAYING*/
#sticky-contents {
  position: fixed;
  top: 210px;
  right: 10px;
  z-index: 5;
  -webkit-transition: 1s all;
  transition: 1s all;
}

.sticky-content {
  display: none;
  padding: 20px 20px 0;
  background-color: rgba(0, 0, 0, 0.9);
  width: 140px;
  -webkit-box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
}

html:lang(en) .en-sticky-content {
  display: block;
}

html:lang(fr) .fr-sticky-content {
  display: block;
}

html:lang(de) .de-sticky-content {
  display: block;
}

html:lang(es) .es-sticky-content {
  display: block;
}

html:lang(zh-hans) .ch-sticky-content {
  display: block;
}

.sticky-content h1 {
  font-size: 15px;
  color: #ffffff;
  padding: 0 0 10px 0;
  border-bottom: 1px solid #ffffff;
  line-height: 18px;
  margin: 0 0 10px 0;
}

.sticky-content p {
  font-size: 13px;
  line-height: 18px;
  padding: 0 0 10px 0;
}

.sticky-content a {
  color: #d89d19;
}

.node-192333 .field-name-field-pp-prim-image,
.node-192333 .field-name-field-pp-tagline,
.node-368218 .field-name-field-pp-tagline,
.feed-icon,
.page-advanced-search .breadcrumb {
  display: none;
}

.region-content-bottom,
.submitted {
  display: none;
}

/**MENU OVERRIDES*/
ul ul li {
  list-style-type: disc;
}

ul ul ul li {
  list-style-type: disc;
}

.block-menu-block#block-menu-block-4 {
  padding-left: 9px;
  border-left: 2px solid #e7b820;
  max-width: 200px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

h2 {
  line-height: 30px;
}

input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='week'],
input[type='text'] {
  min-width: 250px;
  max-width: 100%;
}

select {
  text-transform: none;
  border: 1px solid #e7b820;
  border-radius: 0;
  padding: 5px 10px;
  height: 33px;
  font-size: 14px;
  font-size: 1.07692rem;
  padding: 8px 16px;
  font-family: inherit;
  color: #3c3b3b;
  min-width: 250px;
}

.webform-client-form .webform-component-textfield,
.webform-client-form .webform-component-select {
  display: block;
  margin-right: 8px;
}

select.form-select {
  height: auto;
}

.form-item {
  margin: 20px 0;
}

article a {
  color: #e7b820;
}

article a:hover {
  color: #3b3c3c;
}

.header .header-title-wrapper h3.tagline {
  line-height: 25px;
  font-family: 'ProximaNovaA-Regular';
  width: 80%;
  font-size: 19px;
  text-shadow: 1px 1px 3px #2d2d2d;
  margin: 0 auto;
}

.view-product-category-listing .views-row h3.field-content {
  margin-bottom: 10px;
  margin-top: 0;
}

/* INDUSTRY BOXES*/
.view-product-category-listing.view-display-id-block_3 .views-row {
  background-size: cover;
  background-position: center;
}

.page-node-368218 .view-product-category-listing.view-display-id-block_3 .views-row-1 {
  background-image: url("/sites/default/files/uploads/06-highered.png");
}

.page-node-368218 .view-product-category-listing.view-display-id-block_3 .views-row-2 {
  background-image: url("/sites/default/files/uploads/08-healthcare.png");
}

.page-node-368218 .view-product-category-listing.view-display-id-block_3 .views-row-3 {
  background-image: url("/sites/default/files/uploads/07-hospitality.jpg");
}

.page-node-368218 .view-product-category-listing.view-display-id-block_3 .views-row-4 {
  background-image: url("/sites/default/files/uploads/01-refineries.png");
}

.page-node-368218 .view-product-category-listing.view-display-id-block_3 .views-row-5 {
  background-image: url("/sites/default/files/uploads/05-foodbeverage.png");
}

.page-node-368218 .view-product-category-listing.view-display-id-block_3 .views-row-6 {
  background-image: url("/sites/default/files/uploads/04-pharmaceuticals.png");
}

.page-node-368218 .view-product-category-listing.view-display-id-block_3 .views-row-7 {
  background-image: url("/sites/default/files/uploads/02-power.png");
}

.page-node-192333 .view-product-category-listing.view-display-id-block_3 .views-row-2 {
  background-image: url("/sites/default/files/uploads/02-hotwater.png");
}

.page-node-192333 .view-product-category-listing.view-display-id-block_3 .views-row-4 {
  background-image: url("/sites/default/files/uploads/03-humidification.png");
}

.page-node-192333 .view-product-category-listing.view-display-id-block_3 .views-row-5 {
  background-image: url("/sites/default/files/uploads/05-flowmeasurement.png");
}

.page-node-192333 .view-product-category-listing.view-display-id-block_3 .views-row-6 {
  background-image: url("/sites/default/files/uploads/06-processheating.png");
}

.page-node-192333 .view-product-category-listing.view-display-id-block_3 .views-row-7 {
  background-image: url("/sites/default/files/uploads/packagedsystems-producst-landing.jpg");
}

.page-node-192333 .view-product-category-listing.view-display-id-block_3 .views-row-8 {
  background-image: url("/sites/default/files/uploads/sage-products-landing.jpg");
}

.page-node-192333 .view-product-category-listing.view-display-id-block_3 .views-row-3 {
  background-image: url("/sites/default/files/uploads/armstrong-hotwater-industry.jpg");
}

.page-node-192333 .view-product-category-listing.view-display-id-block_3 .views-row-1 {
  background-image: url("/sites/default/files/uploads/01-steamcondensate.png");
}

.page-node-192333 .view-product-category-listing .views-field-title {
  text-shadow: 1px 1px 1px #6b6b6b;
}

.page-node-368218 .view-product-category-listing .views-field-title {
  text-shadow: 1px 1px 1px #6b6b6b;
}

.page-resource-library .header-image {
  background-image: url("/sites/default/files/uploads/header-knowledge.jpg");
  background-size: cover;
}

strong {
  font-family: 'ProximaNovaA-Bold';
  font-weight: normal;
}

tr.odd {
  border: none;
}

tr.even {
  border: none;
}

th {
  border-color: transparent transparent #cccccc;
}

td.views-field-field-req-,
td.views-field-field-location,
td.views-field-field-job-file {
  padding: 10px 0 0 0;
}

.field-item ul,
.field-content ul {
  display: table;
  padding: 0 0 0 15px;
}

#replocator-form .country {
  background-color: #eeeeee;
  padding: 1px 15px;
  margin-bottom: 10px;
}

/*BROWN BOX */
#block-views-related-resources-block-2 {
  margin-bottom: 0;
}

.scale-container {
  background: transparent;
}

.block-ai-additional-resources {
  padding: 0 10px 10px 10px;
  margin-top: -10px;
}

#block-menu-menu-user-dashboard-menu,
#block-menu-menu-employee-dashboard-menu {
  padding-left: 9px;
  border-left: 2px solid #e7b820;
  max-width: 200px;
}

#navigation .region-navigation a:hover {
  color: #e7b820;
}

#main-wrapper {
  background: white;
}

#page-title {
  margin-top: 18px;
  margin-bottom: 18px;
}

h2.block-title,
.webform-client-form fieldset legend {
  font-family: 'ProximaNovaA-Bold';
  text-transform: uppercase;
  font-size: 13px;
  font-size: 1rem;
  line-height: 18px;
  margin-top: 18px;
  margin-top: 1.38462rem;
  margin-bottom: 0;
  color: #3c3b3b;
}

.webform-client-form fieldset legend {
  margin-bottom: 1.38462rem;
}

.col-6-l {
  width: 48%;
  margin-right: 2%;
  float: left;
  padding-top: 20px;
}

.col-6-r {
  width: 48%;
  margin-left: 2%;
  float: right;
  padding-top: 20px;
}

/* DASHBOARD*/
.marquee-logo {
  float: right;
  margin: -18px 0 10px 0;
}

/*NAVBAR */
.search-activate #search-button span {
  letter-spacing: 1px;
  display: inline;
}

/*HEADER IMAGE */
.region-top {
  background-color: #acacac;
  position: relative;
  left: 0;
  width: 100%;
  z-index: -1;
  top: 0;
  height: 400px;
}

.header-image {
  overflow: hidden;
  margin: 0 auto;
  height: 400px;
}

/* INDUSTRIES BG IMAGES*/
/*GENERAL STYLES*/
hr {
  height: 4px;
  border: none;
  padding-bottom: -1px;
  margin: 36px 0 15px 0;
  background-color: #3c3b3b;
}

.page__title {
  padding-left: 9px;
  margin-top: 36px;
  margin-bottom: 36px;
  border-left: 2px solid #e7b820;
  margin-bottom: 36px;
  overflow: hidden;
  color: #e7b820;
}

h1 {
  font-size: 40px;
  font-size: 3.1rem;
  line-height: 55px;
  color: #e7b820;
}

.page-node-368218 h2.field-content {
  font-size: 1.7rem;
}

h3 {
  line-height: 18px;
  font-size: 1.2rem;
  font-family: 'ProximaNovaA-Bold';
}

#header {
  width: 100%;
}

#header .header-title-wrapper h1.header-title {
  line-height: 55px;
  text-shadow: 1px 1px 3px #2d2d2d;
  letter-spacing: -1px;
  font-size: 40px;
  font-size: 3.1rem;
}

#header .header-title-wrapper h3.tagline {
  line-height: 25px;
  font-family: 'ProximaNovaA-Regular';
  font-size: 19px;
  text-shadow: 1px 1px 3px #2d2d2d;
}

.sidebar-block-dark h3 {
  font-size: 1rem;
  font-family: 'ProximaNovaA-Regular';
  transition: 0.5s;
}

.sidebar-block-dark h3 span:hover {
  color: #e7b820;
}

.sidebar-block-dark {
  padding: 10px 10px 0 10px;
}

h4 {
  text-transform: uppercase;
  margin-top: 0;
}

h5 {
  color: #e7b820;
}

.menu__item.is-active ul.menu {
  margin-top: 3px;
}

ul.menu li {
  padding-bottom: 3px;
}

ul.menu li ul.menu li.menu__item {
  font-size: 95%;
  line-height: 12px;
}

.field-content h4,
.field-item h4 {
  margin-top: 20px;
  margin-bottom: 5px;
}

.field-item blockquote h4 {
  margin-top: 0;
}

.view-product-category-listing.view-display-id-block_3 .views-row .views-field-view-node a {
  padding: 10px 15px 7px 15px;
  position: absolute;
  bottom: 30px;
}

.view-product-category-listing.view-display-id-block_3 .views-row .views-field-view-node a:hover {
  background-color: #ffffff;
}

blockquote {
  margin: 0 0 36px 0;
  border-left: 2.6px solid #e7b820;
  padding: 6.5px 13px;
}

.sidebar-block-dark .item-list ul li {
  font-size: 12px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  line-height: 14px !important;
}

#block-views-related-resources-block-2 .item-list ul li a:hover {
  color: #3b3c3c;
}

img.file-icon {
  height: 13px;
  line-height: 12px;
  width: auto;
  vertical-align: middle;
}

.secondary-title {
  margin-top: 0;
  margin-bottom: 35px;
  margin-left: 11px;
}

.view-product-category-listing .field-content {
  margin-bottom: 10px;
}

ol,
ul {
  margin: 16px 0 16px 0;
}

li {
  padding-bottom: 10px;
}

.view-product-category-listing.view-display-id-block_3 .views-row {
  padding: 0 30px 30px 30px;
  min-height: 344px;
  position: relative;
  margin-bottom: 1.5%;
}

.field-name-field-pp-tagline,
h4.sub-head {
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-transform: none;
  font-family: 'ProximaNovaA-Bold';
  font-weight: 300;
}

.views-field-field-pp-identification-chart,
.view-id-related_ccr {
  margin-left: 12px;
}

.views-field-field-pp-prim-image {
  width: 100%;
  float: left;
}

.views-field-title {
  padding: 10px 0 0 0;
}

.views-field-title a {
  color: #e7b820;
}

.views-field-field-stats {
  font-size: 10px;
}

.view-display-id-block {
  padding-left: 12px;
}

#block-views-news-index-block .view-display-id-block {
  padding-left: 0;
}

.view-product-category-listing.view-display-id-block_1 .views-row {
  padding: 15px 0;
  border-top: 5px solid #3c3b3b;
}

.view-product-category-listing.view-display-id-block_1 .views-row .views-field-field-pp-prim-image {
  width: 20%;
  padding-top: 10px;
}

.view-product-category-listing.view-display-id-block_1 .views-row .views-field-title,
.view-product-category-listing.view-display-id-block_1 .views-row .views-field-field-pp-tagline {
  width: 80%;
}

.block-menu-block h2.block-title {
  margin-bottom: 10px;
}

.two-sidebars .region-sidebar-second {
  width: 20%;
  margin-top: 18px;
}

.two-sidebars .region-sidebar-first {
  width: 20%;
}

#block-views-id-chart-block .views-field-field-pp-identification-chart a,
#block-views-related-ccr-block .views-field-nid a {
  font-weight: normal;
  text-transform: uppercase;
  font-size: 15px;
  font-family: 'ProximaNovaA-Bold';
}

#block-views-id-chart-block .views-field-field-pp-identification-chart a img,
#block-views-related-ccr-block .views-field-nid a img {
  width: 16px;
  margin-right: 9px;
  margin-bottom: 4px;
}

.node-product-page {
  margin-left: 11px;
}

.breadcrumb {
  margin-bottom: 10px;
}

#navigation .region-navigation .block-menu-block {
  padding-right: 2%;
  width: 14%;
}

#navigation .region-navigation .block-menu-block:last-child {
  padding-right: 0;
}

ul.menu li,
ul.menu li.menu__item {
  font-size: 90%;
}

#block-views-related-resources-block-2 .item-list ul {
  right: 190px;
  max-height: 500px;
  overflow: scroll;
}

.node-product-page .required-fields {
  float: left;
  width: 60%;
}

.node-product-page .field-name-field-pp-prim-image,
.node-product-page .field-name-field-pp-purchase-link {
  border: 2px solid #3c3b3b;
  width: 90%;
  text-align: center;
  margin: 0 0 15px 0;
}

.node-product-page .field-name-field-pp-purchase-link {
  background-image: url("/sites/default/files/uploads/configure-now-new.png");
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 110px;
  padding: 0;
  position: relative;
}

.node-product-page .field-name-field-pp-purchase-link .field-items {
  background-color: #ffffff;
  width: 168px;
  height: 100px;
  float: right;
  padding: 10px;
}

.node-product-page .field-name-field-pp-purchase-link .field-items .field-item img,
.node-product-page .field-name-field-pp-prim-image .field-items .field-item img {
  padding: 50px 10px;
}

.node-product-page .field-name-field-pp-purchase-link .field-item::after {
  content: 'Size, select and quote exactly what you need';
  text-transform: uppercase;
  font-size: 11px;
  font-family: 'ProximaNovaA-Bold';
  line-height: 12px;
}

.node-product-page .field-name-field-pp-purchase-link .field-item a {
  background-color: #3c3b3b;
  padding: 6px 10px 5px 10px;
  color: #e7b820;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'ProximaNovaA-Bold';
  position: absolute;
  bottom: 10px;
  right: 24px;
}

.node-product-page .field-name-field-pp-purchase-link .field-item a:hover {
  background-color: #e7b820;
  color: #3c3b3b;
}

.field-name-body {
  margin: 0 0 20px 0;
}

.views-field-field-stats {
  font-size: 9px;
  padding-top: 5px;
}

.view-product-category-listing.view-display-id-block_2 .views-row {
  min-height: 190px;
}

.view-sub-menu-links {
  margin-top: 18px;
}

.region-content-bottom .view-product-listing .views-row {
  min-height: 225px;
}

.node-product-page .field-name-body {
  margin: 0 0 20px 0;
}

.sidebar-first #content {
  width: 80%;
}

.two-sidebars #content {
  width: 55%;
  margin-left: 22%;
}

.view-product-category-listing.view-display-id-block_3 {
  padding: 0;
}

/*Right BRown Box */
#block-views-related-resources-block-2 .item-list ul li .field-content {
  white-space: normal;
}

.block-ai-additional-resources {
  background: #3c3b3b;
  padding: 10px;
}

.block-ai-additional-resources ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.block-ai-additional-resources ul li {
  cursor: pointer;
  margin-top: 9px !important;
  margin-bottom: 9px !important;
  line-height: 18px !important;
  color: white;
  border-left: 1px solid #e7b820;
  padding-left: 9px;
  text-transform: uppercase;
  padding-bottom: 0;
}

.block-ai-additional-resources ul li a {
  color: white;
  font-weight: 100;
  text-transform: uppercase;
}

.block-ai-additional-resources ul li ul li {
  border-left: none;
  font-size: 11px;
  padding-left: 15px;
  list-style: none;
}

.block-ai-additional-resources ul li ul li a {
  border-left: none;
  font-size: 11px;
}

.block-ai-additional-resources ul li a:hover,
.block-ai-additional-resources ul li:hover,
.block-ai-additional-resources ul li.selected {
  color: #e7b820;
}

.block-ai-additional-resources ul > li > ul {
  display: none;
}

.block-ai-additional-resources ul li.selected > ul {
  display: block;
}

/*SEARCH STYLES*/
.show-search .scale-container {
  -webkit-transform: scale(1);
  transform: scale(1);
}

#search-active #search-wrapper #search-results .result .category h3 {
  font-size: 11px;
  line-height: 13px;
}

.show-search #search-active #search-wrapper {
  padding: 110px 50px;
}

#search-active #search-wrapper #search-field input[type='text'] {
  height: 70px;
}

#search-active #search-wrapper #search-field .search-glass {
  position: absolute;
  top: 35px;
  left: 10px;
}

#search-active #search-wrapper #search-field #start-searching {
  height: 70px;
  width: 70px;
}

#search-active #search-wrapper #search-field #advanced-search {
  position: absolute;
  right: 95px;
  top: 38px;
}

#search-active #search-wrapper #search-results .result .title h3 {
  line-height: 18px;
}

/*CASE STUDY STYLES*/
.view-case-studies .views-row {
  width: 100%;
  margin-bottom: 20px;
  height: 100px;
}

.view-case-studies .views-field-view-node a {
  text-transform: uppercase;
  color: #e7b820;
}

.view-case-studies .views-field-view-node a:hover {
  color: #3c3b3b;
}

.view-case-studies .views-field-field-pp-prim-image {
  margin-bottom: 15px;
  width: 15%;
  margin-right: 2%;
  float: left;
}

.view-case-studies .views-exposed-widget {
  margin-bottom: 15px;
}

.view-case-studies select {
  height: 29px;
  font-size: 11px;
  padding: 3px 10px;
}

.node-case-study .field-type-taxonomy-term-reference .field-label {
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 18px;
  font-family: 'ProximaNovaA-Bold';
}

.group-cs-left {
  float: left;
  width: 30%;
  margin-top: 20px;
}

.group-cs-main {
  float: right;
  width: 68%;
  margin-left: 2%;
  margin-top: 20px;
}

.field-name-field-cs-region {
  padding-top: 20px;
}

/*Competitor Cross Reference*/
#block-views-related-ccr-block {
  float: left;
  margin: 0 12px;
  width: 100%;
}

.view-related-ccr {
  padding: 0;
  margin: 0;
  width: 100%;
}

.view-related-ccr .views-field-field-ccr-company {
  float: left;
  margin-right: 5px;
}

.view-related-ccr .views-field-title-field {
  float: left;
}

.view-related-ccr .views-view-grid {
  margin: 10px 0 0 0;
  width: 100%;
}

.view-related-ccr .views-view-grid td {
  padding: 0 10px 0 0;
}

.view-resource-library td,
.view-resource-library th {
  font-size: 11px;
  padding: 5px 8px 0 0;
  vertical-align: top;
}

/* STEAMLOGIC STYLES */
img.steamlogic {
  width: 200px;
  padding-bottom: 30px;
}

img.steamlogic-rosemount {
  width: 200px;
  padding-bottom: 42px;
}

.steam-logic-menu a {
  text-transform: uppercase;
  color: #e7b820;
  padding: 0 5px;
}

.steam-logic-menu {
  margin-bottom: 30px;
}

.page-steamlogic #content h1 {
  font-family: 'ProximaNovaA-Bold';
  text-transform: uppercase;
  font-size: 13px;
  font-size: 1rem;
  line-height: 18px;
  margin-top: 18px;
  margin-top: 1.38462rem;
  margin-bottom: 0;
  color: #3c3b3b;
}

/*Advanced search styles */
.advanced-search-type {
  text-transform: uppercase;
  font-size: 12px;
}

.advanced-search-title a {
  color: #d89d19;
  text-transform: uppercase;
  font-family: 'ProximaNovaA-Bold';
}

.advanced-search-title {
  margin-top: 20px;
}

.page-advanced-search .views-row {
  padding-left: 8px;
  border-left: 3px solid #e7b820;
}

/*Social Media Menu*/
/*CCR styles*/
.view-id-related_ccr {
  display: none;
}

#block-views-related-ccr-block h2 {
  background-image: url("/sites/default/files/uploads/plus-icon.png");
  background-position: right 1px;
  background-repeat: no-repeat;
  background-size: 12px;
  width: 230px;
  cursor: pointer;
}

#block-views-related-ccr-block h2:hover {
  color: #d89d19;
}

/* Webforms*/
.webform-client-form {
  border: 5px solid #3b3c3c;
  background-color: #ffffff;
  padding: 30px;
  margin: 30px 0;
}

.field .field-label,
.form-item label,
.views-field-title {
  font-weight: normal;
  font-family: 'ProximaNovaA-Bold';
}

/*News Articles*/
.field-name-field-nr-category {
  margin-bottom: 20px;
}

/*Armstrong University Courses*/
.course-button {
  text-transform: uppercase;
  text-align: center;
}

.course-link {
  color: #3c3b3b !important;
  display: block;
  background-color: #e7b820;
  padding: 0.5em;
}

.course-link span {
  color: white;
  font-size: 0.8rem;
  background-color: #cc9933;
  padding: 5px 5px 3px;
}

.course-row {
  padding: 0.7em;
}

.course-head {
  color: #e7b820;
  background-color: #3c3b3b;
  padding: 8px 7px 6px;
  font-family: 'ProximaNovaA-Regular';
}

#ai-registration-form .form-item-EmailOptIn a {
  display: block;
  margin: 10px 0 0 0;
  color: #e7b820;
}

#replocator-form .sales-phone:before {
  content: 'Phone: ';
  padding-right: 5px;
  color: #999999;
}

#replocator-form .sales-fax:before {
  content: 'Fax: ';
  padding-right: 5px;
  color: #999999;
}

.page__title,
.node__title,
.block__title,
.comments__title,
.comments__form-title,
.comment__title {
  font-family: 'ProximaNovaA-Bold';
  font-weight: normal;
}

/*CHina Overrides*/
.country-cn #block-menu-menu-social-media {
  display: none;
}

/*MEDIA QUERIES*/
@media (min-width: 1600px) {
  .front #worldwide .copy,
  .front #tbd .copy,
  .front #knowledge .copy,
  .front #you .copy {
    padding-left: 100px;
  }
}

@media (min-width: 1400px) {
  .header-image img {
    margin-left: -50%;
  }
}

@media (max-width: 1600px) {
  .front #solutions {
    min-height: 620px;
  }
}

@media (max-width: 1400px) {
  h1 {
    font-size: 40px;
  }
}

@media (max-width: 1200px) {
  .sticky-content h1 {
    font-size: 11px;
    padding: 0 0 5px 0;
    line-height: 13px;
    margin: 0 0 5px 0;
  }
  .sticky-content p {
    font-size: 10px;
    line-height: 12px;
    padding: 0 0 10px 0;
  }
  .sticky-content {
    right: 0;
    padding: 10px 10px 0;
    width: 80px;
    top: 170px;
  }
  h2 {
    font-size: 20px;
  }
  h1 {
    font-size: 35px;
  }
  .not-front #main {
    padding-left: 12px;
    padding-right: 12px;
  }
  #navigation .region-navigation .block-title,
  #navigation .region-navigation a:hover {
    max-width: 150px;
  }
  #navigation .region-navigation .block-menu-block {
    padding-right: 15px;
    max-width: 155px;
  }
  .header-image {
    height: 300px;
  }
  .region-top {
    height: 300px;
  }
}

@media (max-width: 61.1875em) and (min-width: 37.5em) {
  .sidebar-first .region-sidebar-first {
    width: 20%;
  }
}

@media (max-width: 980px) {
  .sticky-content p {
    font-size: 10px;
  }
  h2 {
    font-size: 19px;
  }
  #navigation .region-navigation .block-title,
  #navigation .region-navigation a:hover {
    max-width: 100%;
  }
  #navigation .region-navigation .block-menu-block {
    padding-right: 0;
    width: 100%;
  }
  .two-sidebars .region-sidebar-second {
    width: 100%;
    margin-top: 90px;
  }
  .two-sidebars #content {
    width: 79%;
    margin-left: 0;
  }
  #navigation .region-navigation .block-menu-block {
    padding-right: 0;
    max-width: 100%;
  }
  .header-image {
    height: 300px;
  }
  .region-top {
    height: 300px;
  }
  .header-image img {
    min-width: 1000px;
    left: 50%;
    margin-left: -500px;
  }
  .two-sidebars #content {
    padding-right: 25px;
  }
  #header .header-title-wrapper h1.header-title {
    line-height: 30px;
    font-size: 30px;
  }
  #header .header-title-wrapper h3.tagline {
    line-height: 24px;
    font-size: 16px;
  }
  .view-product-category-listing.view-display-id-block_3 .views-row {
    padding: 0 18px 25px 18px;
    min-height: 250px;
  }
  #search-active #search-wrapper #search-field {
    width: 100%;
  }
  #search-active #search-wrapper #search-field #start-searching {
    font-size: 14px;
    height: 50px;
    width: 50px;
  }
  #search-active #search-wrapper #search-field input[type='text'] {
    height: 50px;
  }
  #search-active #search-wrapper #search-field #advanced-search {
    position: absolute;
    right: 70px;
    max-width: 50px;
    top: 20px;
    text-align: right;
  }
  #search-active #search-wrapper #search-field .search-glass {
    top: 25px;
    left: 10px;
  }
}

@media (max-width: 767px) {
  h2 {
    font-size: 17px;
  }
  .sticky-content {
    right: 0;
    left: 0;
    padding: 10px 10px 0;
    width: 100%;
    top: inherit !important;
    bottom: 0;
    height: 30px;
  }
  .sticky-content br {
    display: none;
  }
  .sticky-content h1 {
    font-size: 9px;
    padding: 0 0 0 0;
    line-height: 13px;
    margin: 0 0 5px 0;
    width: 150px;
    border-bottom: none;
    border-right: 1px solid #ffffff;
    float: left;
  }
  .sticky-content p {
    font-size: 10px;
    line-height: 13px;
    padding: 0 0 0 10px;
    width: calc(100% - 150px);
    float: right;
  }
  #header .header-title-wrapper h1.header-title {
    line-height: 25px;
    font-size: 23px;
    margin-top: 20px;
  }
  #header .header-title-wrapper h3.tagline {
    line-height: 22px;
    font-size: 14px;
  }
  .footer-wrapper .footer .block {
    margin: 10px auto;
    width: 210px;
    float: none;
  }
  .header-image {
    height: 275px;
  }
  .region-top {
    height: 275px;
  }
  .header-image img {
    min-width: 800px;
    left: 50%;
    margin-left: -400px;
  }
  .logo,
  .rtecenter img {
    margin-top: 25px;
    width: 135px;
  }
  .rtecenter img {
    width: 135px;
  }
  .node-product-page .required-fields {
    float: left;
    width: 100%;
  }
  .node-product-page .field-name-field-pp-prim-image,
  .node-product-page .field-name-field-pp-purchase-link {
    padding: 40px 10px;
    width: 100%;
  }
  .view-product-category-listing.view-display-id-block_3 .views-row {
    width: 48%;
  }
  .node-product-page .field-name-field-pp-purchase-link {
    padding: 5px 10px;
  }
  select,
  textarea,
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='month'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='week'],
  input[type='text'],
  input[type='email'] {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .breadcrumb {
    font-size: 13px;
    line-height: 20px;
  }
  #block-views-related-resources-block-2 .item-list ul {
    right: inherit;
    max-height: 500px;
    overflow: scroll;
    position: relative;
    background: transparent;
    border: none;
    box-shadow: none;
    width: 100%;
    padding-bottom: 0;
  }
  #block-views-related-resources-block-2 .item-list ul::after {
    display: none;
  }
  #block-views-related-resources-block-2 .item-list ul li a:hover {
    color: #666666;
  }
  .tagline {
    display: none;
  }
  #action-menus-wrapper #action-menus {
    left: 10px;
    top: 10px;
  }
  .language-wrapper #current-lang a,
  .language-wrapper .language-switcher-locale-url a {
    text-transform: uppercase;
    font-weight: bold;
    color: #454545;
    font-size: 11px;
  }
  .secondary-menu ul li a {
    font-size: 11px;
  }
  #search-active #search-wrapper #search-field #advanced-search {
    font-size: 11px;
  }
  .view-sub-menu-links {
    line-height: 25px;
  }
  .two-sidebars #content {
    width: 100%;
  }
  .view-product-category-listing.view-display-id-block_3 .views-row {
    width: 100%;
  }
  select,
  textarea,
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='month'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='week'],
  input[type='text'],
  input[type='email'] {
    width: 250px !important;
  }
  .two-sidebars .region-sidebar-first {
    width: 100%;
    margin-right: 0;
    display: block !important;
  }
  .two-sidebars .region-sidebar-second {
    width: 100%;
    margin-top: 60px;
    display: block !important;
  }
  .view-sub-menu-links .views-row .views-field {
    font-size: 10px;
    line-height: 15px;
  }
  .view-sub-menu-links .views-row {
    display: block;
  }
  .view-sub-menu-links .views-row .views-field:after {
    content: '';
  }
  .sidebar-first #content {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .breadcrumb,
  .breadcrumb a {
    font-size: 10px;
    line-height: 20px;
  }
  .header-image,
  .region-top {
    height: 175px;
  }
  .secondary-menu ul li a {
    font-size: 10px;
  }
  .language-wrapper #current-lang a,
  .language-wrapper .language-switcher-locale-url a {
    font-size: 10px;
  }
  .logo {
    width: 85px;
  }
  .rtecenter img {
    width: 100px !important;
    height: auto !important;
  }
  .search-activate {
    right: 0;
    top: 35px;
  }
  .search-activate #search-button span, #toggle-nav .text {
    font-size: 12px;
  }
  #action-menus-wrapper #action-menus {
    left: 10px;
    top: 10px;
    right: 10px;
  }
  .search-activate #search-button {
    color: #1a1a1b;
  }
  .header-image img {
    min-width: 600px;
  }
  h2 {
    font-size: 17px;
    line-height: 22px;
  }
  .node-product-page {
    margin-left: 0;
  }
  #header .header-title-wrapper h1.header-title {
    line-height: 20px;
    font-size: 17px;
    margin-top: 0;
  }
  p,
  li {
    font-size: 12px;
  }
  .view-display-id-block {
    padding-left: 0;
  }
  .view-display-id-block.view-sub-menu-links {
    padding-left: 5px;
  }
  h3 {
    line-height: 18px;
    font-size: 12px;
  }
  ul.menu li,
  ul.menu li.menu__item {
    font-size: 11px;
    margin-top: 5px;
  }
  ul.menu li ul.menu li.menu__item {
    font-size: 10px;
    line-height: 12px;
    margin-top: 5px;
  }
  .sticky-content h1 {
    font-size: 8px;
    padding: 0 0 0 0;
    line-height: 14px;
    margin: 0 0 5px 0;
    width: 136px;
  }
  .sticky-content p {
    font-size: 9px;
    line-height: 12px;
    padding: 0 0 0 10px;
    width: calc(100% - 138px);
    float: right;
  }
  .sticky-content a {
    margin-right: 5px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .front #solutions .links li {
    margin: 0 10px !important;
  }
}

/* Warning: ie8 sees this code with respond.js. Add ie8 specific 1x images back in.
Example with conditional class found on html element (html.tpl)
@include retina-query {
  .logo {
    background-image: url( ....@2x.png);
    background-size: XXpx XXpx;
  }
  .ie-8 .logo {
    background-image: url( ....@1x.png);
  }
}
 */
/**
 * @file
 * Print styling
 *
 * We provide some sane print styling for Drupal using Zen's layout method.
 */
@media print {
  a:link,
  a:visited {
    /* underline all links */
    text-decoration: underline !important;
  }
  #site-name a:link, #site-name
  a:visited {
    /* Don't underline header */
    text-decoration: none !important;
  }
  #content a[href]:after {
    /* Add visible URL after links. */
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 13px;
  }
  #content a[href^="javascript:"]:after,
  #content a[href^="#"]:after {
    /* Only display useful links. */
    content: "";
  }
  #content abbr[title]:after {
    /* Add visible title after abbreviations. */
    content: " (" attr(title) ")";
  }
  #content {
    /* Un-float the content */
    float: none !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  body,
  #page,
  #main,
  #content {
    /* Turn off any background colors or images */
    color: #000;
    background-color: transparent !important;
    background-image: none !important;
  }
  #skip-link,
  #toolbar,
  #navigation,
  .region-sidebar-first,
  .region-sidebar-second,
  #footer,
  .breadcrumb,
  .tabs,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .pager,
  .feed-icons {
    /* Hide sidebars and nav elements */
    visibility: hidden;
    display: none;
  }
}

/* End @media print */
