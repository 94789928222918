.front{
  #tbd{
    @include breakpoint($tablet-portrait-min){
      min-height: 459px;
    }

    .copy{
      text-align: left;

      @include breakpoint($tablet-portrait-min){
        max-width: 400px;
        padding-top: 144px;
      }
    }
    .links{
      padding-top: 0px;
      padding-bottom: 0px;

      ul.inline{
        li{
          padding: 0px;

          a{
            width: 150px;
            background: $link-color;
            color: $body-font-color;
            padding: 3px 18px;
            display: block;
            margin: 0px;
            text-align: center;
          }
        }
      }
    }
  }
}
