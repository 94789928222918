/* @partial navigation */


/* Main Navigation
   ========================================================================== */


#navigation {
  // Sometimes you want to prevent overlapping with main div.
  // overflow: hidden;
  .block {
    margin-bottom: 0;
  }

  .block-menu .block__title,
  .block-menu-block .block__title {
//    @extend %element-invisible;
  }

  /* Main menu and secondary menu links and menu block links. */
  .links,
  .menu {
    margin: 0;
    padding: 0;
    text-align: left; /* LTR */

    li {
      /* A simple method to get navigation links to appear in one line. */
      /*float: left; [> LTR <]*/
      padding: 0 10px 0 0; /* LTR */
      margin: 0 0 $vert-gutter/4 0; /* LTR */
      list-style-type: none;
      list-style-image: none;
      &.last {
        margin-bottom: 0;
      }
    }

  }
}

ul.menu {
  li,
  li.menu__item {
    list-style-image: none;
    list-style-type: none;
  }
}


/* Breadcrumb
   ========================================================================== */


.breadcrumb {
  @include rem('font-size', 14px);
  font-size: .875em;
  ol {
    margin: 0;
    padding: 0;
  }
  li {
    display: inline;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}


/* General Drupal Menus
   ========================================================================== */


.menu__item.is-leaf {
  list-style-image: inline-image("menu-leaf.png");
  list-style-type: square;
}
.menu__item.is-expanded {
  list-style-image: inline-image("menu-expanded.png");
  list-style-type: circle;
}
.menu__item.is-collapsed {
  list-style-image: inline-image("menu-collapsed.png"); /* LTR */
  list-style-type: disc;
}

/* The active item in a Drupal menu. */
.menu a.active {
  color: $link-color-visited;
}


/* Pagination
   ========================================================================== */


/* A list of page numbers when more than 1 page of content is available. */
.pager {
  clear: both;
  padding: 0;
  text-align: center;
}
%pager__item {
  list-style-type: none;
  @include border-radius($ui-border-radius);
  color: lighten($pager-color, 100%);
  text-transform: uppercase;
  padding: 4px 8px;
  float: left;
  @include transition(padding .15s ease-in);
}

.pager-item,      /* A list item containing a page number in the list of pages. */
.pager-first,     /* The first page's list item. */
.pager-previous,  /* The previous page's list item. */
.pager-next,      /* The next page's list item. */
.pager-last,      /* The last page's list item. */
.pager-ellipsis { /* A concatenation of several list items using an ellipsis. */
  a {
    @extend %pager__item;
    background-color: $pager-color;
    &:hover, :active {
      background-color: $pager-color-hover;
      @include border-radius($ui-border-radius);
      text-transform: uppercase;
      color: lighten($pager-color, 100%);
      text-decoration: none;
    }
  }
}

/* The current page's list item. */
.pager-current {
  @extend %pager__item;
  background-color: $disabled-background-color;
  color: $body-font-color;
}

.item-list .pager { /* A list of page numbers when more than 1 page of content is available */
  padding: 0;
  overflow: hidden;
  li {
    float: left;
    margin-right: 2px;
    list-style: none;

    &.pager-current {
      @extend %pager__item;
      padding: 4px 8px;
    }
  }
}


.item-list {
    overflow: hidden;
}


/* Node edit tabs
   ========================================================================== */


$tab-radius: 4px;
$tab-color: $link-color;
$tab-font-size: .75 * $base-font-size;

ul.tabs-primary,
ul.tabs-secondary {
 @include clearfix;
 list-style: none;
 margin: 1em 0 2.5em;
 padding: 0 2px;
 white-space: nowrap;
 li {
   float: left; /* LTR */
   margin: 0.5em 3px 0;
 }
 a, a:link {
   display: block;
   text-decoration: none;
 }
}

ul.tabs-primary {
  li {
    margin: 0.5em 0 0 3px;
    &.is-active {
      a {
        background-color: $tab-color;
        color: #494949;
        border: 1px solid #d1d1d1;
      }
    }
    a {
      font-size: $tab-font-size;
      text-transform: uppercase;
      background-color: $tab-color;
      border: 1px solid $tab-color;
      color: #ffffff;
      line-height: 26px;
      padding: 0 20px;
      &:hover {
        text-decoration: none;
        background-color: darken($tab-color, 15%);
        border: 1px solid darken($tab-color, 10%);
      }
      &.active {
        background-color: #ffffff;
        color: #494949;
        &:hover {
        }
      }
    }  
  }
}

// /*
//  * Secondary tabs STOCK 
//  */

// ul.secondary {
//   font-size: .9em;
//   margin-top: -1.5em; /* Collapse bottom margin of ul.primary. */
//   padding-bottom: .5em;

//   a:link,
//   a:visited {
//     @include border-radius(.75em);
//     @include transition(background-color 0.3s);
//     @include single-text-shadow(#fff, 1px, 1px, 0);
//     background-color: #f2f2f2;
//     color: #666;
//     padding: 0 .5em;
//   }
//   a:hover,
//   a:focus {
//     background-color: #dedede;
//     border-color: #999;
//     color: #333;
//   }
//   a.active,
//   a:active {
//     @include single-text-shadow(#333, 1px, 1px, 0);
//     background-color: #666;
//     border-color: #000;
//     color: #fff;
//   }
// }


/* Menu Blocks
   ========================================================================== */
.block-menu-block{
  h2.block-title{
    text-transform: uppercase;
    @include rem('font-size', $h4-font-size);
    line-height: $h4-line-height;
    @include rem('margin-top', $h4-margin-top);
    margin-bottom: 0px;
  }

  ul.menu {
    ul.menu {
      padding-left: $vert-gutter / 4;
    }
  }

  &#block-menu-block-3{
    padding-left: $vert-gutter / 4;
    border-left: $ui-border-highlight-width solid $primary-color;
    max-width: 200px;
  }
}
