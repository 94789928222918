

$nav-color: $link-color;


/* Behavior
   ========================================================================= */

.js #navigation {
  @include opacity(0);
  @include single-transition(opacity, 0.3s, ease-in);
  visibility: hidden;
} 

.show-nav #navigation {
  @include opacity(1);
  visibility: visible;
}

#toggle-nav {
  position: absolute;
  top: 40px;
  left: 0px;
  z-index: 10;

  .bar{
    background: $body-font-color;
    @include transition(background 0.3s ease-in);
  }

  .show-nav & .bar,
  &:hover .bar {
    background: $link-color;
  }

  .text{
    color: $yellow;
    @include transition(color 0.3s ease-in);
    // position: absolute;
    // left: 30px;
    // top: -1px;
    text-transform: uppercase;
    color: $body-font-color;
  }

  .show-nav & .text,
  &:hover .text{
    color: $link-color;
  }
}


/* Theme
   ========================================================================= */

/*
 * Toggle Button
 */

.nav-button {
  display: block;
  cursor: pointer;
}


.nav-button {
  background-color: transparent;
  color: $nav-color;
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  @include border-radius($ui-border-radius);
  line-height: 18px;
  margin-bottom: 0;
  text-align: center;

  &:hover, &:active {
    color: lighten($nav-color, 20%);
    .toggle-bar {
      background-color: lighten($nav-color, 20%);
    }
  }
}

.nav-button .bar {
  background-color: $nav-color;
  @include border-radius(1px);
  display: block;
  margin-top: 0;
  height: 3px;
  line-height: 14px;
  vertical-align: text-top;
}

// Navigation styles
.navigation{
  // position: fixed;
  padding:  0px $vert-gutter / 3 0px $vert-gutter / 3;
  // top: 0px;
  // left: 0px;
  // right: 0px;
  // z-index: 10;
  // background: rgba(0, 0, 0, 0.9);
  visibility: hidden;

  .region-navigation{
    overflow: hidden;
    padding-bottom: $vert-gutter;
    padding-top: $vert-gutter;
    // max-height: 0px;
    // @include single-transition(max-height, 0.0s, ease-in, 0.3s);

    .show-nav & {
      // max-height: 1000px;
      // @include single-transition(max-height, 0.0s, ease-in);
    }

    .block-title,
    a{
      color: $white;
    }

    .block-menu-block{
      float: none;
      text-align: center;

      @include breakpoint($desktop-min){
        text-align: left;
        float: left;
        padding-right: $vert-gutter / 2;
        max-width: 200px;
      }

      .menu-block-wrapper{
        display: none;
        @include breakpoint($desktop-min){
          display: block;
        }
      }
    }

  }
}
 


////// NEW

.nav-button .bar + .bar {
  margin-top: 4px;
}

#toggle-nav .text {
// left: 35px;
font-weight:normal;
font-size:10px;
color: $yellow;
}

.nav-button {
  .bars {
    width: 28px;
  }

  .bar {
    height: 3px;
    width: 28px;
    background: #1a1a1b;

    &.second {
      width: 18px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
