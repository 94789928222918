$large-header-height: 169px;
$small-header-height: 134px;

#header-wrapper {
    position: fixed;
    width: 100%;
    height: $large-header-height;
    transition: height 0.5s ease-in-out;
    background: #ffffff;
    z-index: 100;
}

.scale-container {
    padding-top: $large-header-height;
}

.logo {
    will-change: width;
    transition: width 0.5s ease-in-out;
}

#navigation {
    // top: -1000px;

    #block-block-2 {
        display: none;
    }
}

.show-nav {
    #header-wrapper{ 
        height: auto;
    }

    .logo img {
        display: none;
        &.inverted {
            display: block;
        }
    }
    // #navigation {
    //     top: $large-header-height;
    //     z-index: 101;
    // }
    
    #header-wrapper {
        background-color: black;
    }

}

////////////// WHEN HEADER IS SMALLER /////////////@include

.scrolled {
    #header-wrapper {
         height: $small-header-height;
    }
    .scale-container {
        padding-top: $small-header-height;
    }
    .logo {
        width: 115px;
    }

    #navigation {
        top: $small-header-height;
        z-index: 101;
    }

    &.show-nav {
        #header-wrapper{ 
            height: auto;
        }
    }
}