.wys-row{
  clear: both;
  overflow: hidden;

  .wys-one-half{
    @include float-span(12);
  }
  .wys-one-third{
    @include float-span(8);
  }
  .wys-two-thirds{
    @include float-span(16);
  }
}


