/* @partial typography */


// Example font-face declaration
// @font-face {
//   font-family: 'Example';
//   src: url('../fonts/example.eot');
//   src: url('../fonts/example.eot?iefix') format('eot'),
//     url('../fonts/example.woff') format('woff'),
//     url('../fonts/example.ttf') format('truetype'),
//     url('../fonts/example.svg#webfontOkOndcij') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }


/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 * 3. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units. 
 */
html {
  font-family: $body-font; /* 1 */
  font-size: 100% * ($base-font-size / 16px); /* 3 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
  // Establish a vertical rhythm unit using $base-line-height.
  @include adjust-leading-to(1);
}


/* =============================================================================
   Typography

   To achieve a pleasant vertical rhythm, we use Compass' Vertical Rhythm mixins
   so that the line height of our base font becomes the basic unit of vertical
   measurement. We use multiples of that unit to set the top and bottom margins
   for our block level elements and to set the line heights of any fonts.
   For more information, see http://24ways.org/2006/compose-to-a-vertical-rhythm
   ========================================================================== */



/*  Header Styles
   ========================================================================== */

@mixin h1(){
  @include rem('font-size', $h1-font-size-mobile);
  line-height: $h1-line-height-mobile;
  @include rem('margin-bottom', $h1-margin-bottom-mobile);
  @include rem('margin-top', $h1-margin-top-mobile);

  @include breakpoint($tablet-small-min){
    @include rem('font-size', $h1-font-size);
    line-height: $h1-line-height;
    @include rem('margin-bottom', $h1-margin-bottom);
    @include rem('margin-top', $h1-margin-top);
  }


  text-transform: uppercase;
  color: $link-color;
  font-weight: 100;
}

@mixin h2(){
  @include rem('font-size', $h2-font-size);
  line-height: $h2-line-height;
  @include rem('margin-bottom', $h2-margin-bottom);
  @include rem('margin-top', $h2-margin-top);

  text-transform: uppercase;
  color: $link-color;
  font-weight: 100;
}

@mixin h3(){
  @include rem('font-size', $h4-font-size);
  line-height: $h4-line-height;
  @include rem('margin-bottom', $h4-margin-bottom);
  @include rem('margin-top', $h4-margin-top);

  @include breakpoint($phone-min){
    @include rem('font-size', $h3-font-size);
    line-height: $h3-line-height;
    @include rem('margin-bottom', $h3-margin-bottom);
    @include rem('margin-top', $h3-margin-top);
  }
  text-transform: uppercase;
}

@mixin h4(){
  @include rem('font-size', $h4-font-size);
  line-height: $h4-line-height;
  @include rem('margin-bottom', $h4-margin-bottom);
  @include rem('margin-top', $h4-margin-top);
}

h1, h2, h3, h4, h5, h6 {
  color: $page-title-color;
  font-weight: bold;
  font-family: $header-font;
  word-wrap: break-word;
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

h4, h5, h6 {
  @include h4;
}

h1, h2{
  font-weight: normal;
  font-family: $webfont-gothic;
}


/*  Drupal Titles
   ========================================================================== */


.page__title,          /* The title of the page. */
.node__title,          /* Title of a piece of content when it is given in a list of content. */
.block__title,         /* Block title. */
.comments__title,      /* Comment section heading. */
.comments__form-title, /* Comment form heading. */
.comment__title {      /* Comment title. */
  font-family: $header-font;
  font-weight: bold;
}

.block-title {
  font-family: $block-title-font;
  color: $block-title-color;
  @include h3();
}

.page__title {
  color: $page-title-color;
  font-family: $page-title-font;
}

.field-label {
  font-weight: bold;
  font-size: 1em;
}

h3.field-label {
  margin-bottom: .25em;
}


/*  Misc
   ========================================================================== */


/* Thematic break */
hr {
  height: 1px;
  border: 1px solid #666;
  padding-bottom: -1px;
  margin: $vert-gutter 0;
}

/*
 * Addresses styling not present in IE7/8/9, S5, Chrome
 */

abbr[title] {
  border-bottom: 1px dotted;
  cursor: help;
  white-space: nowrap;
}

/*
 * Addresses style set to 'bolder' in FF3+, S4/5, Chrome
*/

b,
strong {
  font-weight: bold;
}

blockquote {
  /* Sets 1 unit of vertical rhythm on the top and bottom margin. */
  margin: $vert-gutter $indent-amount;
  border-left: (.2 * $base-font-size) solid $blockquote-border-color; 
  padding: (.5 * $base-font-size) $base-font-size;
}

/*
 * Addresses styling not present in S5, Chrome
 */

dfn {
  font-style: italic;
}

/*
 * Addresses styling not present in IE6/7/8/9
 */

mark {
  background: #ff0;
  color: #000;
}

/*
 * Addresses margins set differently in IE6/7
 */

pre {
  /* Sets 1 unit of vertical rhythm on the top and bottom margin. */
  margin: rhythm(2) 0;
}

p {
  margin: 0;
}

p + p {
  margin-top: $vert-gutter / 3;
}
 

/*
 * Corrects font family set oddly in IE6, S4/5, Chrome
 * en.wikipedia.org/wiki/User:Davidgothberg/Test59
 */

pre,
code,
kbd,
samp,
tt,
var {
  font-family: $font-monospace; /* The $font-monospace value ends with ", serif". */
  @if $legacy-support-for-ie6 {
    _font-family: 'courier new', monospace;
  }
}

p {
  pre,
  code,
  kbd,
  samp,
  tt,
  var {
    margin: 0;
    padding: 0;
    color: $body-font-color;
    background: transparent;  
  }
}

/*
 * Improves readability of pre-formatted text in all browsers
 */

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/*
 * 1. Addresses CSS quotes not supported in IE6/7
 * 2. Addresses quote property not supported in S4
 */

/* 1 */

q {
  quotes: none;
}

/* 2 */

q:before,
q:after {
  content: '';
  content: none;
}

/* @todo Determine how big element affects vertical rhythm. */
small {
  font-size: 75%;
}

/*
 * Prevents sub and sup affecting line-height in all browsers
 * gist.github.com/413930
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}


/* WYSIWYG / BASIC PAGE FIELD CSS */


.wys-divider {
  height: 0;
  border-bottom: 1px solid darken($body-bgrd-color, 20%);
  margin: $vert-gutter 0;
}

.wys-intro-title {
  font-weight: bold;
}

.wys-intro-text {
  font-weight: normal;
}

.wys-section-title {
  font-weight: bold;
}

.wys-block-title {
  font-weight: bold;
  color: darken($body-font-color, 20%);
}

