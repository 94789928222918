.front{
  .section{
    position: relative;
    background-size: cover;

    hr{
      display: none;
    }

    .copy{
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;

      text-align: center;
      padding: $vert-gutter;

      .caption{
        h3{
          color: $page-title-color;
          font-weight: 100;
        }
      }

      .links {
        li{
          a{
            @include h3;
          }
        }
      }
    }

    &.small{
      .title{
        h1{
          @include h2;
        }
      }
    }

    &.dark {
      .caption{
        h3{
          color: $white;
        }
      }
      .links {
        li{
          a{
            color: $white;
            &:hover,
            &.active,
            &.active-trail {
              color: $link-color;
            }
          }
        }
      }
    }
  }
}
