/* @partial modular */

///////////////////////////////////////////////////////////////
// This file intended for object oriented css.
// Like a block style that is reused across all pages or
// A teaser listing that's the same across different content
// listing (news, events, etc.).
///////////////////////////////////////////////////////////////

.block {
  margin-bottom: $vert-gutter;
  margin-top: 0;
}

.sidebar-block-dark {
  background: $body-font-color;
  padding: 10px;

  h3 {
    cursor: pointer;
    margin-top: 9px !important;
    margin-bottom: 9px !important;
    line-height: 18px !important;
    color: $white;
    font-weight: 100;
    border-left: 1px solid $link-color;
    padding-left: 9px;
    font-weight: 100;
  }

  .item-list ul {
    @include clean-list;

    margin: 0;

    li {
      @include h3;

      cursor: pointer;
      margin-top: 9px !important;
      margin-bottom: 9px !important;
      line-height: 18px !important;
      color: $white;
      font-weight: 100;
      border-left: 1px solid $link-color;
      padding-left: 9px;

      // a:visited,
      // a{
      //   color: $white;
      // }
    }
  }
}
