/* @partial views-styles */

.primary-title {
  color: $primary-color;
  text-transform: uppercase;
}

.secondary-title {
  text-transform: uppercase;
  margin-bottom: 0;
}

.view-sub-menu-links {
  padding-left: $vert-gutter / 4;
  margin-top: $vert-gutter;
  margin-bottom: $vert-gutter;
  border-left: $ui-border-highlight-width solid $primary-color;

  &.primary-title {
    margin-bottom: $vert-gutter / 2;
  }

  .view-header h3 {
    line-height: 20px;
    margin: 0;
    margin-bottom: $vert-gutter / 2;
    text-transform: uppercase;
  }

  margin-bottom: $vert-gutter;

  @include clearfix;

  .views-row {
    display: inline-block;

    .views-field {
      text-transform: uppercase;

      &:after {
        content: ' | ';
      }
    }

    &:last-child {
      .views-field {
        &:after {
          content: '';
        }
      }
    }
  }
}

.view-product-category-listing {
  .field-content {
    margin-bottom: 0;
  }

  .views-field-title {
    text-transform: uppercase;
  }

  &.view-display-id-block_1 {
    padding-left: 0;

    .views-row {
      overflow: hidden;
      border-top: 5px solid black;

      &:last-child {
        border-bottom: 5px solid black;
      }

      .views-field-field-pp-prim-image {
        @include float-span(3);
      }

      .views-field-title,
      .views-field-field-pp-tagline {
        @include float-span(21);
      }
    }
  }

  &.view-display-id-block_2 {
    .views-row {
      @include float-span(6);

      text-align: center;

      .views-field-field-prod-image {
        width: 100px;
        margin: 0 auto;
      }

      .views-field-title-field .field-content {
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
      }
    }
  }

  &.view-display-id-block_3 {
    padding: $vert-gutter 0;

    .views-row {
      @include float-span(8, $gutter: 0.5);

      background: #333333;
      padding: $vert-gutter;

      .views-field-title h4 {
        @include h2;

        margin-top: 0;
        margin-bottom: 0;
        text-transform: uppercase;
        color: $link-color;
        font-weight: 100;
      }

      .views-field-field-pp-tagline {
        text-transform: uppercase;
      }

      .views-field-body,
      .views-field-field-pp-tagline {
        color: $white;
      }

      .views-field-view-node {
        margin-top: $vert-gutter / 2;

        a {
          font-weight: bold;
          background: $link-color;
          padding: $vert-gutter / 8 $vert-gutter / 6;
          text-transform: uppercase;

          @include single-transition(background, 0.3s, ease-in-out);

          &:hover {
            color: $body-font-color;
            background: darken($link-color, 10%);
          }
        }
      }
    }
  }
}

.region-content-bottom .view-product-listing {
  .view-header {
    margin-bottom: $vert-gutter / 2;
    border-top: 5px solid black;
    margin-top: $vert-gutter;

    h3 {
      margin-bottom: 0;
    }
  }

  .views-row {
    @include float-span(4);
  }
}

.views-related-resources-block {
  .views-field-edit-node {
    width: 50px;
  }

  caption {
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
  }
}

#block-views-related-resources-block {
  clear: both;

  @include breakpoint($desktop-min) {
    table {
      display: none;
    }
  }
}

#block-views-related-resources-block-1 {
  display: none;

  @include breakpoint($desktop-min) {
    display: block;
  }
}

#block-views-related-ccr-block {
  float: left;
  margin-right: 20px;
  margin: $vert-gutter 0;

  .views-field-nid a {
    font-weight: bold;
    text-transform: uppercase;

    img {
      width: 12px;
      margin-right: 4px;
      margin-bottom: 2px;
    }
  }
}

#block-views-id-chart-block {
  margin: $vert-gutter 0;

  .views-field-field-pp-identification-chart a {
    font-weight: bold;
    text-transform: uppercase;

    img {
      width: 12px;
      margin-right: 4px;
      margin-bottom: 4px;
    }
  }
}

#block-views-related-resources-block-2 {
  .item-list {
    position: relative;
    overflow: visible;

    ul {
      display: none;
      position: absolute;
      background: $white;
      right: 155px;
      width: 300px;
      padding: 18px;
      border: 1px solid #cccccc;

      @include border-radius(3px);
      @include box-shadow(00003px 3px #eee);

      font-weight: normal;
      top: -16px;

      li {
        border-left: none;
        padding-left: 0;

        // a:visited,
        // a {
        //   color: $link-color;
        // }

        .field-content {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          color: $link-color;
        }
      }

      &:after {
        content: '▶';
        position: absolute;
        right: -10px;
        color: white;
        top: 16px;
        text-shadow: 2px 0 3px #888888;
      }
    }
  }
}
