.front{
  #provider{
    @include breakpoint($tablet-portrait-min){
      min-height: 500px;
    }
    .copy{
      @include breakpoint($tablet-portrait-min){
        padding-top: 100px;
      }
    }

    hr{
      display: block;
      width: 45%;
      margin: 0px auto;
      border: 0;
      height: 1px;
      background-color: $link-color;
    }

    .links{
      li{
        a{
          position: relative;
          white-space: nowrap;
          @include single-transition(color, 0.2s, ease-in);

          &:after{
            @include opacity(0);
            @include single-transition(opacity, 0.2s, ease-in);
            content: url('/sites/all/themes/armstrong_zen/images/provider-underscore.png');
            position: absolute;
            left: -72px;
            margin-left: 50%;
            top: 4px;
          }
          &:hover:after{
            @include opacity(1);
          }
        }
      }
    }
  }
}
