.page-node-368218 .views-row.views-row-8.views-row-even,
.page-node-368218 .views-row.views-row-9.views-row-odd.views-row-last {
  display: none;
}

.view-career-postings table {
  margin: 0;
}

.view.view-career-postings {
  border: 5px solid $black;
  padding: 30px;
  margin: 16px 0;
}

.node-type-career-post .webform-client-form {
  margin: 16px 0;
}

.region-btn a:hover {
  color: #ffffff;
}

.region-btn {
  width: 100%;
  height: 100px;
  background-size: cover;
  text-align: left;
  margin-bottom: 16px;
  padding: 1px 16px;
}

.region-btn a.btn {
  display: inline-block;
  height: auto;
}

.region-btn.na {
  background-image: url(/sites/default/files/uploads/arm-na-texture.jpg);
}

.region-btn.in {
  background-image: url(/sites/default/files/uploads/arm-india-texture.jpg);
}

.region-btn.row {
  background-image: url(/sites/default/files/uploads/arm-map-texture.jpg);
}

mark.unpublished {
  width: 100%;
  height: 35px;
  display: block;
  background: #9e4949;
  text-align: center;
  margin: 0 auto;
  padding: 0 0 0 30px;
  line-height: 40px;
  text-indent: 0;
  text-transform: uppercase;
  text-align: left;
  color: #ffffff;
}

.view-product-category-listing.view-display-id-block_3 .views-row-10,
.view-product-category-listing.view-display-id-block_3 .views-row-11 {
  display: none;
}

.views-row.views-row-8.views-row-even.views-row-last {
  display: none;
}

.view-sub-menu-links .views-row.views-row-8.views-row-even.views-row-last {
  display: inline-block;
}

#block-menu-block-8 .menu-mlid-27334 ul.menu {
  display: none;
}

.ProductSelectors label:after {
  content: '*';
  color: red;
  padding-left: 3px;
}

.ProductSelectors label {
  font-family: ProximaNovaA-Regular;
  text-transform: none;
}

.btn {
  background-color: #e8b33c;
  color: #ffffff;
  font-weight: normal;
  border: 1px solid #e8b33c;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 13px;
  font-size: 1rem;
  text-transform: uppercase;
  text-shadow: none;
  text-align: center;
  display: inline-block;
  line-height: 19px;
  padding: 5px 20px 3px;
  height: inherit;
}
// .btn:visited{
//     color:#FFF;
// }
.btn:hover {
  color: #000000;
}

.row .col-6 {
  width: 45%;
  padding: 30px 0;
}

.row .col-6.right {
  float: right;
}

.row .col-6.left {
  float: left;
}

/*NEW PRODUCT PAGE STYLES */
.field-item ul.in-page-nav {
  margin: 30px 0;
  padding: 0;
  list-style: none;
}

.field-item ul.in-page-nav li {
  display: inline-block;
  margin-bottom: 5px;
  padding-bottom: 0;
}

.field-item ul.in-page-nav li a {
  border-right: 1px solid #999999;
  padding-right: 10px;
  margin-right: 10px;
}

.field-item ul.in-page-nav li:last-child a {
  border-right: none;
  padding-right: 0;
  margin-right: 0;
}

.availability-in-listing,
.descriptor-in-listing,
.subhead-in-listing {
  padding-left: 10px;
  width: 80%;
  float: right;
}

.field.field-name-field-availability {
  text-transform: uppercase;
}

.hwfi-wrap {
  background-image: url('/sites/default/files/uploads/hwf1temp.jpg');
  background-size: cover;
  width: 100%;
  height: auto;
  padding: 50px 50px 70px 50px;
  margin-top: 30px;
}

.field-item ul.hw-subnav {
  list-style: none;
  padding: 0;
  width: 100%;
  margin: 35px 0 0 0;
}

.field-item ul.hw-subnav li {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.field-item .hw-subnav li a {
  border-right: 1px solid #e8b33c;
  display: block;
  padding: 0 10px;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 16px;
  font-size: 16px;
}

.field-item .hw-subnav li:first-child a {
  padding: 0 10px 0 0;
}

.field-item .hw-subnav li a:hover {
  color: #e8b33c;
}

.field-item .hw-subnav li:last-child a {
  border-right: none;
  padding-right: 0;
}

#content .secondary-title {
  margin-bottom: 20px;
}

/*TEMPORARY RULES TO REMOVE UNWANTED ELEMENTS FROM DISPLAYING*/

#sticky-contents {
  position: fixed;
  top: 210px;
  right: 10px;
  z-index: 5;
  -webkit-transition: 1s all;
  transition: 1s all;
}

.sticky-content {
  display: none;
  padding: 20px 20px 0;
  background-color: rgba(0, 0, 0, 0.9);
  width: 140px;
  -webkit-box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
}

html:lang(en) .en-sticky-content {
  display: block;
}

html:lang(fr) .fr-sticky-content {
  display: block;
}

html:lang(de) .de-sticky-content {
  display: block;
}

html:lang(es) .es-sticky-content {
  display: block;
}

html:lang(zh-hans) .ch-sticky-content {
  display: block;
}

.sticky-content h1 {
  font-size: 15px;
  color: #ffffff;
  padding: 0 0 10px 0;
  border-bottom: 1px solid #ffffff;
  line-height: 18px;
  margin: 0 0 10px 0;
}

.sticky-content p {
  font-size: 13px;
  line-height: 18px;
  padding: 0 0 10px 0;
}

.sticky-content a {
  color: #d89d19;
}

.node-192333 .field-name-field-pp-prim-image,
.node-192333 .field-name-field-pp-tagline,
.node-368218 .field-name-field-pp-tagline,
.feed-icon,
.page-advanced-search .breadcrumb {
  display: none;
}

.region-content-bottom,
.submitted {
  display: none;
}

/**MENU OVERRIDES*/

ul ul li {
  list-style-type: disc;
}

ul ul ul li {
  list-style-type: disc;
}

.block-menu-block#block-menu-block-4 {
  padding-left: 9px;
  border-left: 2px solid $yellow;
  max-width: 200px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

h2 {
  line-height: 30px;
}

input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='week'],
input[type='text'] {
  min-width: 250px;
  max-width: 100%;
}

select {
  text-transform: none;
  border: 1px solid $yellow;
  border-radius: 0;
  padding: 5px 10px;
  height: 33px;
  font-size: 14px;
  font-size: 1.07692rem;
  padding: 8px 16px;
  font-family: inherit;
  color: #3c3b3b;
  min-width: 250px;
}

.webform-client-form .webform-component-textfield,
.webform-client-form .webform-component-select {
  display: block;
  margin-right: 8px;
}

select.form-select {
  height: auto;
}

.form-item {
  margin: 20px 0;
}

article a {
  color: $yellow;
}

article a:hover {
  color: $black;
}

.header .header-title-wrapper h3.tagline {
  line-height: 25px;
  font-family: 'ProximaNovaA-Regular';
  width: 80%;
  font-size: 19px;
  text-shadow: 1px 1px 3px rgba(45, 45, 45, 1);
  margin: 0 auto;
}

.view-product-category-listing .views-row h3.field-content {
  margin-bottom: 10px;
  margin-top: 0;
}

/* INDUSTRY BOXES*/
.view-product-category-listing.view-display-id-block_3 .views-row {
  background-size: cover;
  background-position: center;
}

.page-node-368218
.view-product-category-listing.view-display-id-block_3
.views-row-1 {
  background-image: url('/sites/default/files/uploads/06-highered.png');
}

.page-node-368218
.view-product-category-listing.view-display-id-block_3
.views-row-2 {
  background-image: url('/sites/default/files/uploads/08-healthcare.png');
}

.page-node-368218
.view-product-category-listing.view-display-id-block_3
.views-row-3 {
  background-image: url('/sites/default/files/uploads/07-hospitality.jpg');
}

.page-node-368218
.view-product-category-listing.view-display-id-block_3
.views-row-4 {
  background-image: url('/sites/default/files/uploads/01-refineries.png');
}

.page-node-368218
.view-product-category-listing.view-display-id-block_3
.views-row-5 {
  background-image: url('/sites/default/files/uploads/05-foodbeverage.png');
}

.page-node-368218
.view-product-category-listing.view-display-id-block_3
.views-row-6 {
  background-image: url('/sites/default/files/uploads/04-pharmaceuticals.png');
}

.page-node-368218
.view-product-category-listing.view-display-id-block_3
.views-row-7 {
  background-image: url('/sites/default/files/uploads/02-power.png');
}

.page-node-192333
.view-product-category-listing.view-display-id-block_3
.views-row-2 {
  background-image: url('/sites/default/files/uploads/02-hotwater.png');
}

.page-node-192333
.view-product-category-listing.view-display-id-block_3
.views-row-4 {
  background-image: url('/sites/default/files/uploads/03-humidification.png');
}

.page-node-192333
.view-product-category-listing.view-display-id-block_3
.views-row-5 {
  background-image: url('/sites/default/files/uploads/05-flowmeasurement.png');
}

.page-node-192333
.view-product-category-listing.view-display-id-block_3
.views-row-6 {
  background-image: url('/sites/default/files/uploads/06-processheating.png');
}

.page-node-192333
.view-product-category-listing.view-display-id-block_3
.views-row-7 {
  background-image: url('/sites/default/files/uploads/packagedsystems-producst-landing.jpg');
}

.page-node-192333
.view-product-category-listing.view-display-id-block_3
.views-row-8 {
  background-image: url('/sites/default/files/uploads/sage-products-landing.jpg');
}

.page-node-192333
.view-product-category-listing.view-display-id-block_3
.views-row-3 {
  background-image: url('/sites/default/files/uploads/armstrong-hotwater-industry.jpg');
}

.page-node-192333
.view-product-category-listing.view-display-id-block_3
.views-row-1 {
  background-image: url('/sites/default/files/uploads/01-steamcondensate.png');
}

.page-node-192333 .view-product-category-listing .views-field-title {
  text-shadow: 1px 1px 1px #6b6b6b;
}

.page-node-368218 .view-product-category-listing .views-field-title {
  text-shadow: 1px 1px 1px #6b6b6b;
}

.page-resource-library .header-image {
  background-image: url('/sites/default/files/uploads/header-knowledge.jpg');
  background-size: cover;
}

strong {
  font-family: 'ProximaNovaA-Bold';
  font-weight: normal;
}

tr.odd {
  border: none;
}

tr.even {
  border: none;
}

th {
  border-color: transparent transparent #cccccc;
}

td.views-field-field-req-,
td.views-field-field-location,
td.views-field-field-job-file {
  padding: 10px 0 0 0;
}

.field-item ul,
.field-content ul {
  display: table;
  padding: 0 0 0 15px;
}

#replocator-form .country {
  background-color: #eeeeee;
  padding: 1px 15px;
  margin-bottom: 10px;
}

/*BROWN BOX */
#block-views-related-resources-block-2 {
  margin-bottom: 0;
}

.scale-container {
  background: transparent;
}

.block-ai-additional-resources {
  padding: 0 10px 10px 10px;
  margin-top: -10px;
}

#block-menu-menu-user-dashboard-menu,
#block-menu-menu-employee-dashboard-menu {
  padding-left: 9px;
  border-left: 2px solid $yellow;
  max-width: 200px;
}

#navigation .region-navigation a:hover {
  color: $yellow;
}

#main-wrapper {
  background: white;
}

#page-title {
  margin-top: 18px;
  margin-bottom: 18px;
}

h2.block-title,
.webform-client-form fieldset legend {
  font-family: 'ProximaNovaA-Bold';
  text-transform: uppercase;
  font-size: 13px;
  font-size: 1rem;
  line-height: 18px;
  margin-top: 18px;
  margin-top: 1.38462rem;
  margin-bottom: 0;
  color: #3c3b3b;
}

.webform-client-form fieldset legend {
  margin-bottom: 1.38462rem;
}

.col-6-l {
  width: 48%;
  margin-right: 2%;
  float: left;
  padding-top: 20px;
}

.col-6-r {
  width: 48%;
  margin-left: 2%;
  float: right;
  padding-top: 20px;
}

/* DASHBOARD*/
.marquee-logo {
  float: right;
  margin: -18px 0 10px 0;
}

/*NAVBAR */

.search-activate #search-button span {
  letter-spacing: 1px;
  display: inline;
}

/*HEADER IMAGE */
.region-top {
  background-color: #acacac;
  position: relative;
  left: 0;
  width: 100%;
  z-index: -1;
  top: 0;
  height: 400px;
}

.header-image {
  overflow: hidden;
  margin: 0 auto;
  height: 400px;
}

/* INDUSTRIES BG IMAGES*/

/*GENERAL STYLES*/
hr {
  height: 4px;
  border: none;
  padding-bottom: -1px;
  margin: 36px 0 15px 0;
  background-color: #3c3b3b;
}

.page__title {
  padding-left: 9px;
  margin-top: 36px;
  margin-bottom: 36px;
  border-left: 2px solid $yellow;
  margin-bottom: 36px;
  overflow: hidden;
  color: $yellow;
}

h1 {
  font-size: 40px;
  font-size: 3.1rem;
  line-height: 55px;
  color: $yellow;
}

.page-node-368218 h2.field-content {
  font-size: 1.7rem;
}

h3 {
  line-height: 18px;
  font-size: 1.2rem;
  font-family: 'ProximaNovaA-Bold';
}

#header {
  width: 100%;
}

#header .header-title-wrapper h1.header-title {
  line-height: 55px;
  text-shadow: 1px 1px 3px rgba(45, 45, 45, 1);
  letter-spacing: -1px;
  font-size: 40px;
  font-size: 3.1rem;
}

#header .header-title-wrapper h3.tagline {
  line-height: 25px;
  font-family: 'ProximaNovaA-Regular';
  font-size: 19px;
  text-shadow: 1px 1px 3px rgba(45, 45, 45, 1);
}

.sidebar-block-dark h3 {
  font-size: 1rem;
  font-family: 'ProximaNovaA-Regular';
  transition: 0.5s;
}

.sidebar-block-dark h3 span:hover {
  color: $yellow;
}

.sidebar-block-dark {
  padding: 10px 10px 0 10px;
}

h4 {
  text-transform: uppercase;
  margin-top: 0;
}

h5 {
  color: $yellow;
}

.menu__item.is-active ul.menu {
  margin-top: 3px;
}

ul.menu li {
  // line-height: 14px;
  padding-bottom: 3px;
}

ul.menu li ul.menu li.menu__item {
  font-size: 95%;
  line-height: 12px;
}

.field-content h4,
.field-item h4 {
  margin-top: 20px;
  margin-bottom: 5px;
}

.field-item blockquote h4 {
  margin-top: 0;
}

.view-product-category-listing.view-display-id-block_3
.views-row
.views-field-view-node
a {
  padding: 10px 15px 7px 15px;
  position: absolute;
  bottom: 30px;
}

.view-product-category-listing.view-display-id-block_3
.views-row
.views-field-view-node
a:hover {
  background-color: #ffffff;
}

blockquote {
  margin: 0 0 36px 0;
  border-left: 2.6px solid $yellow;
  padding: 6.5px 13px;
}

.sidebar-block-dark .item-list ul li {
  font-size: 12px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  line-height: 14px !important;
}

#block-views-related-resources-block-2 .item-list ul li a:hover {
  color: $black;
}

img.file-icon {
  height: 13px;
  line-height: 12px;
  width: auto;
  vertical-align: middle;
}

.secondary-title {
  margin-top: 0;
  margin-bottom: 35px;
  margin-left: 11px;
}

.view-product-category-listing .field-content {
  margin-bottom: 10px;
}

ol,
ul {
  margin: 16px 0 16px 0;
}

li {
  padding-bottom: 10px;
}

.view-product-category-listing.view-display-id-block_3 .views-row {
  padding: 0 30px 30px 30px;
  min-height: 344px;
  position: relative;
  margin-bottom: 1.5%;
}

.field-name-field-pp-tagline,
h4.sub-head {
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-transform: none;
  font-family: 'ProximaNovaA-Bold';
  font-weight: 300;
}

.views-field-field-pp-identification-chart,
.view-id-related_ccr {
  margin-left: 12px;
}

.views-field-field-pp-prim-image {
  width: 100%;
  float: left;
}

.views-field-title {
  padding: 10px 0 0 0;
}

.views-field-title a {
  color: $yellow;
}

.views-field-field-stats {
  font-size: 10px;
}

.view-display-id-block {
  padding-left: 12px;
}

#block-views-news-index-block .view-display-id-block {
  padding-left: 0;
}

.view-product-category-listing.view-display-id-block_1 .views-row {
  padding: 15px 0;
  border-top: 5px solid #3c3b3b;
}

.view-product-category-listing.view-display-id-block_1
.views-row
.views-field-field-pp-prim-image {
  width: 20%;
  padding-top: 10px;
}

.view-product-category-listing.view-display-id-block_1
.views-row
.views-field-title,
.view-product-category-listing.view-display-id-block_1
.views-row
.views-field-field-pp-tagline {
  width: 80%;
}

.block-menu-block h2.block-title {
  margin-bottom: 10px;
}

.two-sidebars .region-sidebar-second {
  width: 20%;
  margin-top: 18px;
}

.two-sidebars .region-sidebar-first {
  width: 20%;
}

#block-views-id-chart-block .views-field-field-pp-identification-chart a,
#block-views-related-ccr-block .views-field-nid a {
  font-weight: normal;
  text-transform: uppercase;
  font-size: 15px;
  font-family: 'ProximaNovaA-Bold';
}

#block-views-id-chart-block .views-field-field-pp-identification-chart a img,
#block-views-related-ccr-block .views-field-nid a img {
  width: 16px;
  margin-right: 9px;
  margin-bottom: 4px;
}

.node-product-page {
  margin-left: 11px;
}

.breadcrumb {
  margin-bottom: 10px;
}

#navigation .region-navigation .block-menu-block {
  padding-right: 2%;
  width: 14%;
}

#navigation .region-navigation .block-menu-block:last-child {
  padding-right: 0;
}

ul.menu li,
ul.menu li.menu__item {
  font-size: 90%;
}

#block-views-related-resources-block-2 .item-list ul {
  right: 190px;
  max-height: 500px;
  overflow: scroll;
}

.node-product-page .required-fields {
  float: left;
  width: 60%;
}

.node-product-page .field-name-field-pp-prim-image,
.node-product-page .field-name-field-pp-purchase-link {
  border: 2px solid #3c3b3b;
  width: 90%;
  text-align: center;
  margin: 0 0 15px 0;
}

.node-product-page .field-name-field-pp-purchase-link {
  background-image: url('/sites/default/files/uploads/configure-now-new.png');
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 110px;
  padding: 0;
  position: relative;
}

.node-product-page .field-name-field-pp-purchase-link .field-items {
  background-color: #ffffff;
  width: 168px;
  height: 100px;
  float: right;
  padding: 10px;
}

.node-product-page
.field-name-field-pp-purchase-link
.field-items
.field-item
img,
.node-product-page
.field-name-field-pp-prim-image
.field-items
.field-item
img {
  padding: 50px 10px;
}

.node-product-page .field-name-field-pp-purchase-link .field-item::after {
  content: 'Size, select and quote exactly what you need';
  text-transform: uppercase;
  font-size: 11px;
  font-family: 'ProximaNovaA-Bold';
  line-height: 12px;
}

.node-product-page .field-name-field-pp-purchase-link .field-item a {
  background-color: #3c3b3b;
  padding: 6px 10px 5px 10px;
  color: $yellow;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'ProximaNovaA-Bold';
  position: absolute;
  bottom: 10px;
  right: 24px;
}

.node-product-page .field-name-field-pp-purchase-link .field-item a:hover {
  background-color: $yellow;
  color: #3c3b3b;
}

.field-name-body {
  margin: 0 0 20px 0;
}

.views-field-field-stats {
  font-size: 9px;
  padding-top: 5px;
}

.view-product-category-listing.view-display-id-block_2 .views-row {
  min-height: 190px;
}

.view-sub-menu-links {
  margin-top: 18px;
}

.region-content-bottom .view-product-listing .views-row {
  min-height: 225px;
}

.node-product-page .field-name-body {
  margin: 0 0 20px 0;
}

.sidebar-first #content {
  width: 80%;
}

.two-sidebars #content {
  width: 55%;
  margin-left: 22%;
}

.view-product-category-listing.view-display-id-block_3 {
  padding: 0;
}

/*Right BRown Box */
#block-views-related-resources-block-2 .item-list ul li .field-content {
  white-space: normal;
}

.block-ai-additional-resources {
  background: #3c3b3b;
  padding: 10px;
}

.block-ai-additional-resources ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.block-ai-additional-resources ul li {
  cursor: pointer;
  margin-top: 9px !important;
  margin-bottom: 9px !important;
  line-height: 18px !important;
  color: white;
  border-left: 1px solid $yellow;
  padding-left: 9px;
  text-transform: uppercase;
  padding-bottom: 0;
}

.block-ai-additional-resources ul li a {
  color: white;
  font-weight: 100;
  text-transform: uppercase;
}

.block-ai-additional-resources ul li ul li {
  border-left: none;
  font-size: 11px;
  padding-left: 15px;
  list-style: none;
}

.block-ai-additional-resources ul li ul li a {
  border-left: none;
  font-size: 11px;
}

.block-ai-additional-resources ul li a:hover,
.block-ai-additional-resources ul li:hover,
.block-ai-additional-resources ul li.selected {
  color: $yellow;
}

.block-ai-additional-resources ul > li > ul {
  display: none;
}

.block-ai-additional-resources ul li.selected > ul {
  display: block;
}

/*SEARCH STYLES*/
.show-search .scale-container {
  -webkit-transform: scale(1);
  transform: scale(1);
}

#search-active #search-wrapper #search-results .result .category h3 {
  font-size: 11px;
  line-height: 13px;
}

.show-search #search-active #search-wrapper {
  padding: 110px 50px;
}

#search-active #search-wrapper #search-field input[type='text'] {
  height: 70px;
}

#search-active #search-wrapper #search-field .search-glass {
  position: absolute;
  top: 35px;
  left: 10px;
}

#search-active #search-wrapper #search-field #start-searching {
  height: 70px;
  width: 70px;
}

#search-active #search-wrapper #search-field #advanced-search {
  position: absolute;
  right: 95px;
  top: 38px;
}

#search-active #search-wrapper #search-results .result .title h3 {
  line-height: 18px;
}

/*CASE STUDY STYLES*/

.view-case-studies .views-row {
  width: 100%;
  margin-bottom: 20px;
  height: 100px;
}

.view-case-studies .views-field-view-node a {
  text-transform: uppercase;
  color: $yellow;
}

.view-case-studies .views-field-view-node a:hover {
  color: #3c3b3b;
}

.view-case-studies .views-field-field-pp-prim-image {
  margin-bottom: 15px;
  width: 15%;
  margin-right: 2%;
  float: left;
}

.view-case-studies .views-exposed-widget {
  margin-bottom: 15px;
}

.view-case-studies select {
  height: 29px;
  font-size: 11px;
  padding: 3px 10px;
}

.node-case-study .field-type-taxonomy-term-reference .field-label {
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 18px;
  font-family: 'ProximaNovaA-Bold';
}

.group-cs-left {
  float: left;
  width: 30%;
  margin-top: 20px;
}

.group-cs-main {
  float: right;
  width: 68%;
  margin-left: 2%;
  margin-top: 20px;
}

.field-name-field-cs-region {
  padding-top: 20px;
}

/*Competitor Cross Reference*/
#block-views-related-ccr-block {
  float: left;
  margin: 0 12px;
  width: 100%;
}

.view-related-ccr {
  padding: 0;
  margin: 0;
  width: 100%;
}

.view-related-ccr .views-field-field-ccr-company {
  float: left;
  margin-right: 5px;
}

.view-related-ccr .views-field-title-field {
  float: left;
}

.view-related-ccr .views-view-grid {
  margin: 10px 0 0 0;
  width: 100%;
}

.view-related-ccr .views-view-grid td {
  padding: 0 10px 0 0;
}

.view-resource-library td,
.view-resource-library th {
  font-size: 11px;
  padding: 5px 8px 0 0;
  vertical-align: top;
}

/* STEAMLOGIC STYLES */

img.steamlogic {
  width: 200px;
  padding-bottom: 30px;
}

img.steamlogic-rosemount {
  width: 200px;
  padding-bottom: 42px;
}

.steam-logic-menu a {
  text-transform: uppercase;
  color: $yellow;
  padding: 0 5px;
}

.steam-logic-menu {
  margin-bottom: 30px;
}

.page-steamlogic #content h1 {
  font-family: 'ProximaNovaA-Bold';
  text-transform: uppercase;
  font-size: 13px;
  font-size: 1rem;
  line-height: 18px;
  margin-top: 18px;
  margin-top: 1.38462rem;
  margin-bottom: 0;
  color: #3c3b3b;
}

/*Advanced search styles */
.advanced-search-type {
  text-transform: uppercase;
  font-size: 12px;
}

.advanced-search-title a {
  color: #d89d19;
  text-transform: uppercase;
  font-family: 'ProximaNovaA-Bold';
}

.advanced-search-title {
  margin-top: 20px;
}

.page-advanced-search .views-row {
  padding-left: 8px;
  border-left: 3px solid $yellow;
}

/*Social Media Menu*/
// #block-menu-menu-social-media{  width: 75%;clear: right;float: left;padding-left: 2px;padding-right: 12px;  margin: 20px 0 0 0;}
// #block-menu-menu-social-media li{display:inline;padding-right:15px;float: left;}
// #block-menu-menu-social-media li a{text-indent: -19999px;display:block;background-repeat:no-repeat;  height: 32px;width: 32px;background-size: cover;}
// #block-menu-menu-social-media li:nth-child(1) a{background-image:url('/sites/default/files/uploads/facebook-64.png');}
// #block-menu-menu-social-media li:nth-child(2) a{background-image:url('/sites/default/files/uploads/linkedin-64.png');}
// #block-menu-menu-social-media li:nth-child(3) a{background-image:url('/sites/default/files/uploads/twitter-64.png');}
// #block-menu-menu-social-media li:nth-child(4) a{background-image:url('/sites/default/files/uploads/youtube-64.png');}

/*CCR styles*/
.view-id-related_ccr {
  display: none;
}

#block-views-related-ccr-block h2 {
  background-image: url('/sites/default/files/uploads/plus-icon.png');
  background-position: right 1px;
  background-repeat: no-repeat;
  background-size: 12px;
  width: 230px;
  cursor: pointer;
}

#block-views-related-ccr-block h2:hover {
  color: #d89d19;
}

/* Webforms*/
.webform-client-form {
  border: 5px solid $black;
  background-color: #ffffff;
  padding: 30px;
  margin: 30px 0;
}

.field .field-label,
.form-item label,
.views-field-title {
  font-weight: normal;
  font-family: 'ProximaNovaA-Bold';
}

/*News Articles*/
.field-name-field-nr-category {
  margin-bottom: 20px;
}

/*Armstrong University Courses*/
.course-button {
  text-transform: uppercase;
  text-align: center;
}

.course-link {
  color: #3c3b3b !important;
  display: block;
  background-color: $yellow;
  padding: 0.5em;
}

.course-link span {
  color: white;
  font-size: 0.8rem;
  background-color: #cc9933;
  padding: 5px 5px 3px;
}

.course-row {
  padding: 0.7em;
}

.course-head {
  color: $yellow;
  background-color: #3c3b3b;
  padding: 8px 7px 6px;
  font-family: 'ProximaNovaA-Regular';
}

#ai-registration-form .form-item-EmailOptIn a {
  display: block;
  margin: 10px 0 0 0;
  color: $yellow;
}

#replocator-form .sales-phone:before {
  content: 'Phone: ';
  padding-right: 5px;
  color: #999999;
}

#replocator-form .sales-fax:before {
  content: 'Fax: ';
  padding-right: 5px;
  color: #999999;
}

.page__title,
.node__title,
.block__title,
.comments__title,
.comments__form-title,
.comment__title {
  font-family: 'ProximaNovaA-Bold';
  font-weight: normal;
}

/*CHina Overrides*/
.country-cn #block-menu-menu-social-media {
  display: none;
}

/*MEDIA QUERIES*/
@media (min-width: 1600px) {
  .front #worldwide .copy,
  .front #tbd .copy,
  .front #knowledge .copy,
  .front #you .copy {
    padding-left: 100px;
  }
}

@media (min-width: 1400px) {
  .header-image img {
    margin-left: -50%;
  }
}

@media (max-width: 1600px) {
  .front #solutions {
    min-height: 620px;
  }
}

@media (max-width: 1400px) {
  h1 {
    font-size: 40px;
  }
  // .front .region-highlighted {
  // padding-top: 175px;
  // }
}

@media (max-width: 1200px) {
  .sticky-content h1 {
    font-size: 11px;
    padding: 0 0 5px 0;
    line-height: 13px;
    margin: 0 0 5px 0;
  }

  .sticky-content p {
    font-size: 10px;
    line-height: 12px;
    padding: 0 0 10px 0;
  }

  .sticky-content {
    right: 0;
    padding: 10px 10px 0;
    width: 80px;
    top: 170px;
  }

  h2 {
    font-size: 20px;
  }

  h1 {
    font-size: 35px;
  }

  .not-front #main {
    padding-left: 12px;
    padding-right: 12px;
  }

  #navigation .region-navigation .block-title,
  #navigation .region-navigation a:hover {
    max-width: 150px;
  }

  #navigation .region-navigation .block-menu-block {
    padding-right: 15px;
    max-width: 155px;
  }

  .header-image {
    height: 300px;
  }

  .region-top {
    height: 300px;
  }
}

@media (max-width: 61.1875em) and (min-width: 37.5em) {
  .sidebar-first .region-sidebar-first {
    width: 20%;
  }
}

@media (max-width: 980px) {
  .sticky-content p {
    font-size: 10px;
  }

  h2 {
    font-size: 19px;
  }

  #navigation .region-navigation .block-title,
  #navigation .region-navigation a:hover {
    max-width: 100%;
  }

  #navigation .region-navigation .block-menu-block {
    padding-right: 0;
    width: 100%;
  }

  .two-sidebars .region-sidebar-second {
    width: 100%;
    margin-top: 90px;
  }

  .two-sidebars #content {
    width: 79%;
    margin-left: 0;
  }

  #navigation .region-navigation .block-menu-block {
    padding-right: 0;
    max-width: 100%;
  }

  .header-image {
    height: 300px;
  }

  .region-top {
    height: 300px;
  }

  .header-image img {
    min-width: 1000px;
    left: 50%;
    margin-left: -500px;
  }

  .two-sidebars #content {
    padding-right: 25px;
  }

  #header .header-title-wrapper h1.header-title {
    line-height: 30px;
    font-size: 30px;
  }

  #header .header-title-wrapper h3.tagline {
    line-height: 24px;
    font-size: 16px;
  }

  .view-product-category-listing.view-display-id-block_3 .views-row {
    padding: 0 18px 25px 18px;
    min-height: 250px;
  }

  #search-active #search-wrapper #search-field {
    width: 100%;
  }

  #search-active #search-wrapper #search-field #start-searching {
    font-size: 14px;
    height: 50px;
    width: 50px;
  }

  #search-active #search-wrapper #search-field input[type='text'] {
    height: 50px;
  }

  #search-active #search-wrapper #search-field #advanced-search {
    position: absolute;
    right: 70px;
    max-width: 50px;
    top: 20px;
    text-align: right;
  }

  #search-active #search-wrapper #search-field .search-glass {
    top: 25px;
    left: 10px;
  }
}

@media (max-width: 767px) {
  h2 {
    font-size: 17px;
  }

  .sticky-content {
    right: 0;
    left: 0;
    padding: 10px 10px 0;
    width: 100%;
    top: inherit !important;
    bottom: 0;
    height: 30px;
  }

  .sticky-content br {
    display: none;
  }

  .sticky-content h1 {
    font-size: 9px;
    padding: 0 0 0 0;
    line-height: 13px;
    margin: 0 0 5px 0;
    width: 150px;
    border-bottom: none;
    border-right: 1px solid #ffffff;
    float: left;
  }

  .sticky-content p {
    font-size: 10px;
    line-height: 13px;
    padding: 0 0 0 10px;
    width: calc(100% - 150px);
    float: right;
  }

  #header .header-title-wrapper h1.header-title {
    line-height: 25px;
    font-size: 23px;
    margin-top: 20px;
  }

  #header .header-title-wrapper h3.tagline {
    line-height: 22px;
    font-size: 14px;
  }

  .footer-wrapper .footer .block {
    margin: 10px auto;
    width: 210px;
    float: none;
  }
  // #block-menu-menu-social-media li {
  //   padding: 0 8px;
  // }
  .header-image {
    height: 275px;
  }

  .region-top {
    height: 275px;
  }

  .header-image img {
    min-width: 800px;
    left: 50%;
    margin-left: -400px;
  }

  .logo,
  .rtecenter img {
    margin-top: 25px;
    width: 135px;
  }

  .rtecenter img {
    width: 135px;
  }

  // .show-nav #navigation {
  //   height: 100%;
  // }
  .node-product-page .required-fields {
    float: left;
    width: 100%;
  }

  .node-product-page .field-name-field-pp-prim-image,
  .node-product-page .field-name-field-pp-purchase-link {
    padding: 40px 10px;
    width: 100%;
  }

  .view-product-category-listing.view-display-id-block_3 .views-row {
    width: 48%;
  }

  .node-product-page .field-name-field-pp-purchase-link {
    padding: 5px 10px;
  }

  select,
  textarea,
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='month'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='week'],
  input[type='text'],
  input[type='email'] {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .breadcrumb {
    font-size: 13px;
    line-height: 20px;
  }

  #block-views-related-resources-block-2 .item-list ul {
    right: inherit;
    max-height: 500px;
    overflow: scroll;
    position: relative;
    background: transparent;
    border: none;
    box-shadow: none;
    width: 100%;
    padding-bottom: 0;
  }

  #block-views-related-resources-block-2 .item-list ul::after {
    display: none;
  }

  #block-views-related-resources-block-2 .item-list ul li a:hover {
    color: #666666;
  }

  .tagline {
    display: none;
  }

  #action-menus-wrapper #action-menus {
    left: 10px;
    top: 10px;
  }

  .language-wrapper #current-lang a,
  .language-wrapper .language-switcher-locale-url a {
    text-transform: uppercase;
    font-weight: bold;
    color: #454545;
    font-size: 11px;
  }

  .secondary-menu ul li a {
    font-size: 11px;
  }

  #search-active #search-wrapper #search-field #advanced-search {
    font-size: 11px;
  }

  .view-sub-menu-links {
    line-height: 25px;
  }

  .two-sidebars #content {
    width: 100%;
  }

  .view-product-category-listing.view-display-id-block_3 .views-row {
    width: 100%;
  }

  select,
  textarea,
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='month'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='week'],
  input[type='text'],
  input[type='email'] {
    width: 250px !important;
  }

  .two-sidebars .region-sidebar-first {
    width: 100%;
    margin-right: 0;
    display: block !important;
  }

  .two-sidebars .region-sidebar-second {
    width: 100%;
    margin-top: 60px;
    display: block !important;
  }

  .view-sub-menu-links .views-row .views-field {
    font-size: 10px;
    line-height: 15px;
  }

  .view-sub-menu-links .views-row {
    display: block;
  }

  .view-sub-menu-links .views-row .views-field:after {
    content: '';
  }

  .sidebar-first #content {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .breadcrumb,
  .breadcrumb a {
    font-size: 10px;
    line-height: 20px;
  }

  .header-image,
  .region-top {
    height: 175px;
  }

  .secondary-menu ul li a {
    font-size: 10px;
  }

  .language-wrapper #current-lang a,
  .language-wrapper .language-switcher-locale-url a {
    font-size: 10px;
  }

  .logo {
    width: 85px;
  }

  .rtecenter img {
    width: 100px !important;
    height: auto !important;
  }

  .search-activate {
    right: 0;
    top: 35px;
  }

  .search-activate #search-button span,
  #toggle-nav .text {
    font-size: 12px;
  }

  #action-menus-wrapper #action-menus {
    left: 10px;
    top: 10px;
    right: 10px;
  }

  .search-activate #search-button {
    color: #1a1a1b;
  }

  .header-image img {
    min-width: 600px;
  }

  h2 {
    font-size: 17px;
    line-height: 22px;
  }

  .node-product-page {
    margin-left: 0;
  }

  #header .header-title-wrapper h1.header-title {
    line-height: 20px;
    font-size: 17px;
    margin-top: 0;
  }

  p,
  li {
    font-size: 12px;
  }

  .view-display-id-block {
    padding-left: 0;
  }

  .view-display-id-block.view-sub-menu-links {
    padding-left: 5px;
  }

  h3 {
    line-height: 18px;
    font-size: 12px;
  }

  ul.menu li,
  ul.menu li.menu__item {
    font-size: 11px;
    margin-top: 5px;
  }

  ul.menu li ul.menu li.menu__item {
    font-size: 10px;
    line-height: 12px;
    margin-top: 5px;
  }

  .sticky-content h1 {
    font-size: 8px;
    padding: 0 0 0 0;
    line-height: 14px;
    margin: 0 0 5px 0;
    width: 136px;
  }

  .sticky-content p {
    font-size: 9px;
    line-height: 12px;
    padding: 0 0 0 10px;
    width: calc(100% - 138px);
    float: right;
  }

  .sticky-content a {
    margin-right: 5px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .front #solutions .links li {
    margin: 0 10px !important;
  }
}
