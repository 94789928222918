.front{
  #knowledge{
    @include breakpoint($tablet-portrait-min){
      min-height: 851px;
    }

    .copy {
      text-align: left;

      @include breakpoint($tablet-portrait-min){
        padding-top: 300px;
        max-width: 600px;
      }
    }
  }
}
