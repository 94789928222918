.front{
  #service{
    @include breakpoint($tablet-portrait-min){
      min-height: 500px;
    }

    .copy {
      @include breakpoint($tablet-portrait-min){
        padding-top: 100px;
      }
    }
  }
}
