@font-face {
  font-family: 'icomoon';
  src:
    url('../fonts/iconfonts/icomoon/fonts/icomoon.ttf?xrvc91') format('truetype'),
    url('../fonts/iconfonts/icomoon/fonts/icomoon.woff?xrvc91') format('woff'),
    url('../fonts/iconfonts/icomoon/fonts/icomoon.svg?xrvc91#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

// <i class="icon icon-plus></i>
.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-replace {
    font-size: 0;

    &:before {
        font-size: 16px;
        font-size: 1rem;
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}
// text-indent: -19999px;display:block;background-repeat:no-repeat;  height: 32px;width: 32px;background-size: cover;
.icon-plus:before {
  content: "\e900";
}
.icon-question:before {
  content: "\e901";
}
.icon-minus:before {
  content: "\e902";
}
.icon-music:before {
  content: "\f001";
}
.icon-search:before {
  content: "\e903";
}
.icon-envelope-o:before {
  content: "\e904";
}
.icon-heart:before {
  content: "\e905";
}
.icon-star:before {
  content: "\e906";
}
.icon-star-o:before {
  content: "\e907";
}
.icon-user:before {
  content: "\e908";
}
.icon-th-large:before {
  content: "\e909";
}
.icon-th:before {
  content: "\e90a";
}
.icon-th-list:before {
  content: "\e90b";
}
.icon-check:before {
  content: "\e90c";
}
.icon-close:before {
  content: "\e90d";
}
.icon-remove:before {
  content: "\e90d";
}
.icon-times:before {
  content: "\e90d";
}
.icon-search-plus:before {
  content: "\e90e";
}
.icon-search-minus:before {
  content: "\e90f";
}
.icon-cog:before {
  content: "\e910";
}
.icon-gear:before {
  content: "\e910";
}
.icon-trash-o:before {
  content: "\e911";
}
.icon-home:before {
  content: "\e912";
}
.icon-file-o:before {
  content: "\e913";
}
.icon-clock-o:before {
  content: "\e914";
}
.icon-download:before {
  content: "\e915";
}
.icon-arrow-circle-o-down:before {
  content: "\e916";
}
.icon-arrow-circle-o-up:before {
  content: "\e917";
}
.icon-inbox:before {
  content: "\e918";
}
.icon-play-circle-o:before {
  content: "\e919";
}
.icon-repeat:before {
  content: "\e91a";
}
.icon-rotate-right:before {
  content: "\e91a";
}
.icon-refresh:before {
  content: "\e91b";
}
.icon-list-alt:before {
  content: "\f022";
}
.icon-lock:before {
  content: "\e91c";
}
.icon-flag:before {
  content: "\f024";
}
.icon-headphones:before {
  content: "\f025";
}
.icon-volume-off:before {
  content: "\f026";
}
.icon-volume-down:before {
  content: "\f027";
}
.icon-volume-up:before {
  content: "\f028";
}
.icon-tag:before {
  content: "\e91d";
}
.icon-tags:before {
  content: "\e91e";
}
.icon-book:before {
  content: "\e91f";
}
.icon-bookmark:before {
  content: "\e920";
}
.icon-print:before {
  content: "\e921";
}
.icon-camera:before {
  content: "\e922";
}
.icon-video-camera:before {
  content: "\e923";
}
.icon-image:before {
  content: "\e924";
}
.icon-photo:before {
  content: "\e924";
}
.icon-picture-o:before {
  content: "\e924";
}
.icon-pencil:before {
  content: "\e925";
}
.icon-map-marker:before {
  content: "\e926";
}
.icon-edit:before {
  content: "\e927";
}
.icon-pencil-square-o:before {
  content: "\e927";
}
.icon-share-square-o:before {
  content: "\e928";
}
.icon-check-square-o:before {
  content: "\e929";
}
.icon-play:before {
  content: "\e92a";
}
.icon-pause:before {
  content: "\e92b";
}
.icon-stop:before {
  content: "\e92c";
}
.icon-forward:before {
  content: "\e92d";
}
.icon-fast-forward:before {
  content: "\e92e";
}
.icon-step-forward:before {
  content: "\e92f";
}
.icon-chevron-left:before {
  content: "\e930";
}
.icon-chevron-right:before {
  content: "\e931";
}
.icon-plus-circle:before {
  content: "\e932";
}
.icon-minus-circle:before {
  content: "\e933";
}
.icon-times-circle:before {
  content: "\e934";
}
.icon-check-circle:before {
  content: "\e935";
}
.icon-question-circle:before {
  content: "\e936";
}
.icon-info-circle:before {
  content: "\e937";
}
.icon-times-circle-o:before {
  content: "\e938";
}
.icon-check-circle-o:before {
  content: "\e939";
}
.icon-arrow-left:before {
  content: "\e93a";
}
.icon-arrow-right:before {
  content: "\e93b";
}
.icon-arrow-up:before {
  content: "\e93c";
}
.icon-arrow-down:before {
  content: "\e93d";
}
.icon-mail-forward:before {
  content: "\e93e";
}
.icon-share:before {
  content: "\e93e";
}
.icon-expand:before {
  content: "\e93f";
}
.icon-compress:before {
  content: "\e940";
}
.icon-exclamation-circle:before {
  content: "\e941";
}
.icon-eye:before {
  content: "\e942";
}
.icon-eye-slash:before {
  content: "\e943";
}
.icon-exclamation-triangle:before {
  content: "\e944";
}
.icon-warning:before {
  content: "\e944";
}
.icon-calendar:before {
  content: "\e945";
}
.icon-random:before {
  content: "\e946";
}
.icon-comment:before {
  content: "\e947";
}
.icon-chevron-up:before {
  content: "\e948";
}
.icon-chevron-down:before {
  content: "\e949";
}
.icon-retweet:before {
  content: "\f079";
}
.icon-shopping-cart:before {
  content: "\e94a";
}
.icon-folder:before {
  content: "\e94b";
}
.icon-folder-open:before {
  content: "\e94c";
}
.icon-bar-chart:before {
  content: "\e94d";
}
.icon-bar-chart-o:before {
  content: "\e94d";
}
.icon-twitter-square:before {
  content: "\e94e";
}
.icon-facebook-square:before {
  content: "\e94f";
}
.icon-camera-retro:before {
  content: "\e950";
}
.icon-key:before {
  content: "\e951";
}
.icon-cogs:before {
  content: "\e952";
}
.icon-gears:before {
  content: "\e952";
}
.icon-comments:before {
  content: "\e953";
}
.icon-thumbs-o-up:before {
  content: "\e954";
}
.icon-thumbs-o-down:before {
  content: "\e955";
}
.icon-linkedin-square:before {
  content: "\e956";
}
.icon-phone:before {
  content: "\e957";
}
.icon-phone-square:before {
  content: "\e958";
}
.icon-twitter:before {
  content: "\e959";
}
.icon-facebook:before {
  content: "\e95a";
}
.icon-facebook-f:before {
  content: "\e95a";
}
.icon-arrow-circle-left:before {
  content: "\e95b";
}
.icon-arrow-circle-right:before {
  content: "\e95c";
}
.icon-arrow-circle-up:before {
  content: "\e95d";
}
.icon-arrow-circle-down:before {
  content: "\e95e";
}
.icon-globe:before {
  content: "\e95f";
}
.icon-copy:before {
  content: "\e960";
}
.icon-files-o:before {
  content: "\e960";
}
.icon-paperclip:before {
  content: "\e961";
}
.icon-bars:before {
  content: "\e962";
}
.icon-navicon:before {
  content: "\e962";
}
.icon-reorder:before {
  content: "\e962";
}
.icon-list-ul:before {
  content: "\e963";
}
.icon-pinterest:before {
  content: "\e964";
}
.icon-pinterest-square:before {
  content: "\e965";
}
.icon-google-plus-square:before {
  content: "\e966";
}
.icon-google-plus:before {
  content: "\e967";
}
.icon-caret-down:before {
  content: "\e968";
}
.icon-caret-up:before {
  content: "\e969";
}
.icon-caret-left:before {
  content: "\e96a";
}
.icon-caret-right:before {
  content: "\e96b";
}
.icon-sort-desc:before {
  content: "\e96c";
}
.icon-sort-down:before {
  content: "\e96c";
}
.icon-sort-asc:before {
  content: "\e96d";
}
.icon-sort-up:before {
  content: "\e96d";
}
.icon-linkedin:before {
  content: "\e96e";
}
.icon-cloud-download:before {
  content: "\e96f";
}
.icon-cloud-upload:before {
  content: "\e970";
}
.icon-angle-double-left:before {
  content: "\e971";
}
.icon-angle-double-right:before {
  content: "\e972";
}
.icon-angle-double-up:before {
  content: "\e973";
}
.icon-angle-double-down:before {
  content: "\e974";
}
.icon-angle-left:before {
  content: "\e975";
}
.icon-angle-right:before {
  content: "\e976";
}
.icon-angle-up:before {
  content: "\e977";
}
.icon-angle-down:before {
  content: "\e978";
}
.icon-mobile:before {
  content: "\e979";
}
.icon-mobile-phone:before {
  content: "\e979";
}
.icon-folder-o:before {
  content: "\e97a";
}
.icon-folder-open-o:before {
  content: "\e97b";
}
.icon-info:before {
  content: "\e97c";
}
.icon-calendar-o:before {
  content: "\e97d";
}
.icon-ellipsis-h:before {
  content: "\e97e";
}
.icon-ellipsis-v:before {
  content: "\e97f";
}
.icon-rss-square:before {
  content: "\e980";
}
.icon-play-circle:before {
  content: "\e981";
}
.icon-minus-square:before {
  content: "\e982";
}
.icon-check-square:before {
  content: "\e983";
}
.icon-compass:before {
  content: "\e984";
}
.icon-file:before {
  content: "\e985";
}
.icon-file-text:before {
  content: "\e986";
}
.icon-youtube-square:before {
  content: "\e987";
}
.icon-youtube:before {
  content: "\e988";
}
.icon-youtube-play:before {
  content: "\e989";
}
.icon-tumblr:before {
  content: "\e98a";
}
.icon-tumblr-square:before {
  content: "\e98b";
}
.icon-long-arrow-down:before {
  content: "\e98c";
}
.icon-long-arrow-up:before {
  content: "\e98d";
}
.icon-long-arrow-left:before {
  content: "\e98e";
}
.icon-long-arrow-right:before {
  content: "\e98f";
}
.icon-skype:before {
  content: "\e990";
}
.icon-vimeo-square:before {
  content: "\e991";
}
.icon-envelope-square:before {
  content: "\e992";
}
.icon-paper-plane:before {
  content: "\e993";
}
.icon-send:before {
  content: "\e993";
}
.icon-paper-plane-o:before {
  content: "\e994";
}
.icon-send-o:before {
  content: "\e994";
}
.icon-share-alt:before {
  content: "\e995";
}
.icon-cc-visa:before {
  content: "\e996";
}
.icon-cc-mastercard:before {
  content: "\e997";
}
.icon-cc-discover:before {
  content: "\e998";
}
.icon-cc-amex:before {
  content: "\e999";
}
.icon-cc-paypal:before {
  content: "\e99a";
}
.icon-cc-stripe:before {
  content: "\e99b";
}
.icon-area-chart:before {
  content: "\e99c";
}
.icon-pie-chart:before {
  content: "\e99d";
}
.icon-line-chart:before {
  content: "\e99e";
}
.icon-toggle-off:before {
  content: "\e99f";
}
.icon-toggle-on:before {
  content: "\e9a0";
}
.icon-facebook-official:before {
  content: "\e9a1";
}
.icon-calendar-plus-o:before {
  content: "\e9a2";
}
.icon-calendar-minus-o:before {
  content: "\e9a3";
}
.icon-calendar-times-o:before {
  content: "\e9a4";
}
.icon-calendar-check-o:before {
  content: "\e9a5";
}
