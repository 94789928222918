.front #sticky-contents {
    display:none!important;
}

.front #solutions .title h1:after {
    box-shadow: none;
}

#navigation .region-navigation .block-title,
#navigation .region-navigation a:hover { 
    color: #e8b33c;
}
.scroll-down{
 text-align: center;
    position: fixed;
    bottom: 20px;
    left: 48%;
    transition:1s all;
    z-index: 100;
}
.flex-direction-nav a {
    text-shadow:none;
}

// .flex-direction-nav a:before {
//     content: '';
// }
    
// .flex-direction-nav a.flex-next:before {
//     content: '';
// }

.flex-direction-nav a  {
    @extend .icon-replace;
    &:before {
      font-size: 32px;
      color: #ffffff;
    }
}

.flex-direction-nav a.flex-next:before  {
    @extend .icon-angle-right;
}

.flex-direction-nav a.flex-prev:before  {
    @extend .icon-angle-left;
}

.region-highlighted .flex-control-container .flex-direction-nav .flex-prev:hover {
    opacity:0.5;
}

.region-highlighted .flex-control-container .flex-direction-nav .flex-next:hover {
   opacity:0.5;
}

.front #solutions {
    min-height: 700px;
}

.front #worldwide {
    min-height: 800px;
    margin-top: 0;
}

.front #provider {
    height: 500px;
    margin-top: 0;
    transition: 1s all;
}
.front #ad-series{
    height:500px;
    margin-top: 0;
    transition: 1s all;
    width:100%;
}
.front #ad-series .links ul.inline li a {
    width: 180px;
    background: $yellow;
    color: #3c3b3b;
    padding: 6px 18px 3px 18px;
    display: block;
    margin: 0px auto;
    text-align: center;
    transition: 0.5s all;
}
.front #ad-series .links ul.inline li a:hover {
    background: #3c3b3b;
    color: #FFF;
}
.front #ad-series .title h1 {
    margin-top: 100px;
}
.front #provider .copy {
    text-shadow: 1px 1px 3px rgba(45, 45, 45, 1);
}    

.front #tbd {
    height: 500px;
    margin-top: 0;
    transition: 1s all;
}

.front #knowledge {
    height: 800px;
    margin-top: 0;
    min-height:800px;
}

.front #service {
    height: 500px;
    margin-top: 0;
    transition: 1s all;
}

.front #you {
    height: 800px;
    margin-top: 0 !important;
}

.front #you .copy {
    padding: 280px 0 0 50px;
    max-width:100%;
}



.front #register {
    height: 500px;
    margin-top: 0;
    transition: 1s all;
}

.front #register .copy {
    padding: 80px 200px;
    width: 100%;
    margin: 0 auto;
}

.front #register .links ul.inline li a {
    width: 180px;
    background: $yellow;
    color: #3c3b3b;
    padding: 6px 18px 3px 18px;
    display: block;
    margin: 0px auto;
    text-align: center;
    transition:0.5s all;
}

.front #register .links ul.inline li a:hover {
    color:$yellow;
    background: $black;
}

.front #register .copy .caption h3 {
    color:#FFFFFF;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
}    
.front #register .copy .body a {
    color:$yellow;;
    font-weight:normal;
      font-family: "ProximaNovaA-Regular";
        font-size: 14px;
}
.front #register .copy .body a:hover {
    color:#FFFFFF;;
}
.front .section .image picture {
    width: 100%;
    left: 0;
    margin-left: 0px;
}

.front .section .image picture img {
    width: 100%;
}

.front .region-highlighted {
    max-width: 100%;
    // padding-top: 260px;
}

// .front .footer-wrapper {
//     position: inherit;
//     bottom: 0px;
//     width: 100%;
//     height: 300px;
//     padding: 30px 0 0 0;
// }

.region-highlighted #home-slides .slide picture {
    margin-left: -50%;
}

.region-highlighted #home-slides .slide img {
    width:100%;
}

.backgroundsize .front .section {
    background-size:cover!important;
}

.backgroundsize .front .section .image {
    display: block;
    position: absolute;
    min-width: 100%;
}

.front #solutions .links li a {
    padding-top: 63px;
}

.front #solutions .links li.first a {
    background: rgba(255, 255, 255, 0.7);
}

.front #solutions .links li.first {
    border: 1px solid rgba(255, 255, 255, 0.7);
}

.front #solutions .links li:hover {
    border: 1px solid rgba(232, 179, 60, 0.9);
}

.front #solutions .links li a:hover {
    background: rgba(232, 179, 60, 0.9);
}

.front #provider h3 .caption {
    display:none;
}

.front #worldwide .copy {
    padding: 0px 0 0 100px;
    max-width: 100%;
}

.front #worldwide .copy .title {
    padding-top: 450px;
    max-width: 50%;
    text-shadow: 1px 1px 1px #6B6B6B;
}

.front #worldwide .copy .title h1 {
    line-height: 45px;
}

.front .section .copy .links li a {
    font-size: 17px;
    line-height: 20px;
    line-height: 25px;
    font-family: "ProximaNovaA-Regular";
}

.front #worldwide .links ul.inline li a, .front #tbd .links ul.inline li a, .front #knowledge .links ul.inline li a, .front #you .links ul.inline li a {
    width: 180px;
    background: $yellow;
    color: #3c3b3b;
    padding: 6px 18px 3px 18px;
    display: block;
    margin: 0px;
    text-align: center;
    transition:0.5s all;
}

.front #worldwide .links ul.inline li a:hover, .front #tbd .links ul.inline li a:hover, .front #knowledge .links ul.inline li a:hover, .front #you .links ul.inline li a:hover {
    color:$yellow;
    background: $black;
}

.front #service .links ul.inline li a {
    width: 180px;
    background: $yellow;
    color: #3c3b3b;
    padding: 6px 18px 3px 18px;
    display: block;
    margin: 0px auto;
    text-align: center;
    transition:0.5s all;
}

.front #service .links ul.inline li a:hover {
    background-color: #3c3b3b;
    color: $yellow;
}

.front .section.small .title h1 {
    font-size: 28px;
    margin-top: 60px;
    margin-top: 5rem;
}

.front #provider .links li a:after {
    top: 12px;
}

.front #solutions .title h1 {
    line-height: 55px;
    text-shadow: 1px 1px 6px rgba(0, 0, 0, 1);
    letter-spacing: -1px;
    font-size: 40px;
    font-size: 3.1rem;
}

.front .section .copy .caption h3 {
    font-family: "ProximaNovaA-Regular";
    font-size: 1.3rem;
    font-size:17px;
    line-height: 22px;
}

.front #solutions .caption h3 {
    text-shadow: 1px 1px 3px rgba(45, 45, 45, 1);
}    

.front #you .copy .caption h3 {
    color:#FFFFFF;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
}    

.front #service .copy {
    padding: 80px 200px;
    width: 100%;
    margin: 0 auto;
}

.front #service .copy h1 {
    text-shadow: 1px 1px 3px rgba(45, 45, 45, 1);
}    

.front #service .copy .caption, .front #service .copy .links {
    position:relative;
}

.front #service .copy .caption {
    text-shadow: 1px 1px 3px rgba(45, 45, 45, 1);
}    

.front #tbd .copy {
    max-width: 100%;
    padding: 70px 0 0 100px;
}

.front #tbd .copy .title {
    max-width:500px;
    padding-top: 0px;
}
.front #tbd .copy .body {
 max-width: 400px;
    padding-top: 0px;
}
.front #tbd .links,  .front #knowledge .links, .front #you .links, .front #worldwide .links{
    position: relative;
}

.front #knowledge .copy {
    padding: 0 100px 0 100px;
    max-width: 100%;
}

.front #knowledge .copy .title{
    padding-top: 280px;
    max-width: 50%;
    text-shadow: 1px 1px 1px #6B6B6B;
}

.front #knowledge .copy .title h1 {
    line-height: 45px;
}

.front #knowledge .copy .body .caption {
    max-width: 50%;
}
.front #you .copy .title, .front #you .copy .body .caption {
    max-width:50%;
    position: relative;
}


/* HOME PAGE SLIDER */

.flex-control-nav {
    bottom: 20px;
    z-index: 100;
}

.region-highlighted #home-slides .slide img.background {
    min-width: 1400px;
    left: 0;
    margin-left: 0;
}

.front .copy .title {
    z-index: 2;
    position: relative;
    padding-top:36px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
}
.front #provider ul.links li:last-child{
    padding-right:0;
}
.front #provider .colorize {
    opacity: 0;
    width: 100%;
    height: 500px;
    position: absolute;
    top: 0;
    left: 0;
    background-image:url('/sites/default/files/uploads/02-industries-01-colorized.jpg');
    transition:2s all;
    background-size: cover;
}

.front #worldwide .airplane {
    width: 100%;
    height: 800px;
    position: absolute;
    top: -150px;
    left: 1000px;
    background-image:url('/sites/default/files/uploads/03-global-02.png');
    transition:2s all;
    background-size: cover;
}

.front #worldwide {
    transition: 1s all;    
}    

.front #tbd .device {
    width: 100%;
    height: 500px;
    position: absolute;
    top: 500px;
    left: 0px;
    background-image:url('/sites/default/files/uploads/04-sage-02.png');
    transition:2s all;
    background-size: cover;
}

.front #knowledge .charts {
    width: 100%;
    height: 800px;
    position: absolute;
    top: 0px;
    left: 0px;
    transition:3s all;
    opacity:0;
    background-size: cover;
}
.front #knowledge .charts1 {background-image:url('/sites/default/files/uploads/knowledge-head1.png');}
.front #knowledge .charts2 {background-image:url('/sites/default/files/uploads/knowledge-head2.png');-webkit-transition-delay: 1s;transition-delay: 1s;}
.front #knowledge .charts3 {background-image:url('/sites/default/files/uploads/knowledge-head3.png');-webkit-transition-delay: 2s;transition-delay: 2s;}
.front #knowledge .charts4 {background-image:url('/sites/default/files/uploads/knowledge-head4.png');-webkit-transition-delay: 3s;transition-delay: 3s;}
.front #knowledge .charts5 {background-image:url('/sites/default/files/uploads/knowledge-head5.png');-webkit-transition-delay: 4s;transition-delay: 4s;}

.front #service .colorize {
    opacity: 0;
    width: 100%;
    height: 500px;
    position: absolute;
    top: 0;
    left: 0;
    background-image:url('/sites/default/files/uploads/06-service-01-colorized-new.jpg');
    transition:2s all;
    background-size: cover;
}
.front #you .colorize{
 opacity: 0;
    width: 100%;
    height: 850px;
    position: absolute;
    top: 0;
    left: 0;
    transition:2s all;
    background-image:url('/sites/default/files/uploads/07-customer-01-colorized-newest.jpg'); 
    background-size: cover;
}
.front #main-wrapper{
    margin-top:0;
}
.front #solutions .copy{
 position:relative;   
}
/* MEDIA QUERIES */

@media (min-width: 1400px){
    .header-image img {
        margin-left: -50%;
    }
}

@media (max-width: 1600px){
    .front #solutions {
        min-height: 550px;
    }
    // .front .region-highlighted {
    //     padding-top: 210px;
    // }
}
@media (min-width: 1600px){
.front #knowledge, .front #knowledge .charts, .front #worldwide  {
height: 1000px;
min-height: 1000px;
}
.front #worldwide .airplane{
    height: 1100px;
    top:-140px;
}
}
@media (max-width:1200px){
 .front #worldwide .airplane {
  height: 686px;
  top: -125px;
  left: 1000px;
}
.front #worldwide .copy .title {
  padding-top: 400px;
}
.front #worldwide {
  min-height: 750px;
}
.front #worldwide .copy {
  padding: 0px 0 0 50px;
}
.front #tbd .copy {
  padding: 100px 0 0 50px;
}
.front #knowledge .copy {
  padding: 0 100px 0 50px;
}
.front #knowledge {
  height: 700px;
  min-height: 700px;
}
.front #knowledge .charts {
  height: 720px;
}
.front #service .copy {
  padding: 80px 150px;
}
.front #you .colorize {
  height: 700px;
}
.front #you {
  min-height: 700px;
  height: 700px;
}
.front #register .copy {
  padding: 50px 100px;

}
.front #solutions .title h1 {
  line-height: 47px;
  font-size: 37px;
}
h1 {
  font-size: 37px;
}
// .front .region-highlighted {
//   padding-top: 150px;
// }
.front #solutions {
  min-height: 500px;
}
.front #solutions .links li a {
  width: 122px;
  height: 122px;
}
.front #solutions .links li {
  width: 130px;
  height: 130px;
  margin: 20px;
}
.front #solutions .links li a {
  padding-top: 53px;
  font-size: 15px;
}
.front #solutions .links {
  width: 510px;
}
}
@media (max-width:980px){
.front #solutions .title h1 {
  line-height: 40px;
  font-size: 32px;
}
.front #solutions .links li a {
  width: 100px;
  height: 100px;
}
.front #solutions .links li {
  width: 108px;
  height: 108px;
  margin: 0 15px;
}
.front #solutions .links li a {
  padding-top: 42px;
  font-size: 14px;
}
.front #solutions .links {
  width: 414px;
}
.front #provider {
  min-height: 400px;
  height:400px;
  background-position: right;
}
.front #provider .colorize {
  height: 400px;
}
.front #provider .copy {
  padding-top: 20px;
}
.front .section .copy .links li a {
  font-size: 15px;
  line-height: 20px;
}
.front #worldwide {
  min-height: 600px;
}
.front #worldwide .airplane {
  height: 600px;
  top: -110px;
  left: 1000px;
}
.front #worldwide .copy .title {
  padding-top: 200px;
}
.front #worldwide .copy .caption{
 max-width:50%;   
}
h1 {
  font-size: 30px;
}

.front #tbd .copy {
  padding: 50px 0 0 50px;
}
.front #knowledge {
  height: 650px;
  min-height: 650px;
}
.front #knowledge .charts {
  height: 650px;
}
.front #knowledge .copy .title {
padding-top: 175px!important;
}

.front .copy .title {
  padding-top: 0;
}
.front .section.small .title h1 {
  font-size: 23px;
}
.front .section .copy .caption h3 {
  font-size: 15px;
  line-height: 20px;
}
.front #register, .front #service .colorize, .front #service, .front #tbd .device, .front #tbd {
  height: 400px;
  min-height:400px;
}

.front #register .copy, .front #service .copy {
  padding: 50px;
}
}
@media(max-width:767px){
    .front #service .colorize{display:none;}
    #home-slides{top:0px!important;}
.front #knowledge .charts {
  display: none;
}
.front #tbd .device{top:0;}
    .front #worldwide .airplane {
  height: 500px;
  top: -80px;
  left: 0px;
}

.front #knowledge .copy .title {
  padding-top: 100px;
  max-width: 100%;
}
.front #knowledge .copy {
  text-align: center;
}
.front #knowledge .copy .body .caption {
  max-width: 100%;
}
.front #knowledge .links{
      text-align: -webkit-center;
        text-align: center;
          width: 180px;
  margin: 0 auto;
}
.front #worldwide .copy .title {
  padding-top: 150px;
}
.front #worldwide .copy .title h1, .front #knowledge .copy .title h1, h1 {
  line-height: 35px;
}
.front .section.small .title h1 {
  font-size: 20px;
  margin-top: 10px;
    line-height: 28px;
}
.front #provider .copy {
  padding-top: 70px;
}
.front #you {
  min-height: 600px;
  height: 600px;
  background-position: right;
}
.front #you .colorize {
  height: 600px;
}
.front .section .copy .caption h3 {
  font-size: 14px;
  line-height: 20px;
}
// .front .footer-wrapper {
//   height: 550px;
//   padding:  0;
// }
#search-field input, #search-wrapper{width:100%;}
}
@media(max-width:599px){
.front #worldwide .copy, .front #tbd .copy, .front #you .copy {
  padding-left: 20px;
  padding-right:20px;
    text-align: center;
}    
.front #worldwide .airplane {
  height: 400px;
  top: -50px;
}
.front #solutions .title h1 {
  line-height: 35px;
  font-size: 28px;
}
.front #worldwide .copy .title h1, .front #knowledge .copy .title h1, h1 {
  line-height: 30px;
  font-size: 24px;
}
.front #worldwide .copy .title {
  padding-top: 100px;
  max-width: 100%;
}
.front #knowledge .copy {
  padding: 0 20px;
}
.front #register .copy, .front #service .copy {
  padding: 50px 20px;
}
.front #worldwide .copy .caption {
  max-width: 100%;
}
.front #tbd .links, .front #knowledge .links, .front #you .links, .front #worldwide .links {
  position: relative;
  text-align: center;
  text-align: -webkit-center;
}
.front #you .copy .title, .front #you .copy .body .caption {
  max-width: 100%;
  position: relative;
}
.front .section.small .title h1 {
  font-size: 17px;
  margin-top: 10px;
  line-height: 23px;
}
.front #solutions .links li a {
  width: 78px;
  height: 78px;
}
.front #solutions .links li {
  width: 86px;
  height: 86px;
  margin: 0 15px;
}
.front #solutions .links li a {
  padding-top: 31px;
  font-size: 12px;
}
.front #solutions .links {
   width: 354px;

}
.search-activate #search-button span, #toggle-nav .text {
  font-size: 13px;
}
.scroll-down {
  left: 45%;
}
.front #solutions .links li {
    margin: 0px 5px;
}
.front #solutions .links {
    width: 320px;
}
}

