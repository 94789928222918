@import "_slider.scss";
@import "_slider-video.scss";
@import "_section.scss";
@import "_solutions.scss";
@import "_provider.scss";
@import "_worldwide.scss";
@import "_tbd.scss";
@import "_knowledge.scss";
@import "_service.scss";
@import "_you.scss";

.front{
  .region-highlighted{
    max-width: 1400px;
    margin: 0px auto;
  }
}
