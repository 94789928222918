/* @partial page-theme */

///////////////////////////////////////////////////////////////////////////////
// General page elements layout and theming that do not chnage across pages.
// Background images, logos, etc.
///////////////////////////////////////////////////////////////////////////////

/* Body
   ========================================================================== */

html {
  position: relative;
}

body {
  background-color: $body-bgrd-color;
}

/* Page
   ========================================================================== */

#page {
  position: relative;
  overflow: hidden;
}

/* Main / Content
   ========================================================================== */

#main {
  .not-front & {
    padding-top: $vert-gutter;
  }
}

#content {
}

/* Messages
   ========================================================================== */

.messages {
  margin: $vert-gutter 0;
  padding: 10px 10px 10px 50px; /* LTR */
  background-image: inline-image('message-24-ok.png');
  background-position: 8px 8px; /* LTR */
  background-repeat: no-repeat;
  border: 1px solid #bbee77;
}

.messages--status {
  @extend .messages;
  @extend %ok;
}

.messages--warning {
  @extend .messages;
  @extend %warning;

  background-image: inline-image('message-24-warning.png');
  border-color: #eedd55;
}

.messages--error {
  @extend .messages;
  @extend %error;

  background-image: inline-image('message-24-error.png');
  border-color: #ed541d;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

/* Core/module installation error messages. */
.messages--error p.error {
  color: #333333;
}

/* System status report. */
.ok,
%ok {
  background-color: #f8fff0;
  color: #234600;
}

.warning,
%warning {
  background-color: #fffce5;
  color: #884400;
}

.error,
%error {
  background-color: #fef5f1;
  color: #8c2e0b;
}

/* First Sidebar
   ========================================================================== */

.region-sidebar-first {
}

/* Second Sidebar
   ========================================================================== */

.region-sidebar-second {
}

/* Page Bottom
   ========================================================================== */
.region-bottom {
}

/* Instant Click Bar
   ========================================================================== */

#instantclick-bar {
  background: $primary-color;
}

/* Link color overrides
   ========================================================================== */
a {
  color: $body-font-color;

  // &:visited {
  //   color: $body-font-color;
  // }

  &:hover,
  &.active,
  &.active-trail {
    color: $link-color;
  }
}

.breadcrumb {
  padding-left: 12px;
  padding-right: 12px;
  text-transform: uppercase;
  color: $link-color;

  .breadcrumb-separator {
    color: $body-font-color;
  }

  a {
    text-transform: uppercase;
  }
}

/* Search
   ============================================================================== */

.search-activate {
  position: absolute;
  top: 47px;
  right: 0;
  z-index: 20;

  &:hover {
    #search-button {
      color: $body-font-color;
    }
  }

  #search-button {
    display: block;

    span {
      text-transform: uppercase;
      float: left;
      margin: 0;
      font-size: 20px;
      padding-right: 5px;
      padding-top: 3px;

      .show-nav & {
        color: $white;
      }
    }

    .show-nav & {
      .search-icon {
        color: #ffffff;
      }
    }
  }

  #search-field {
    display: none;
  }
}

.search-active {
  z-index: 102;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  overflow: hidden;

  .js.opacity & {
    display: block;

    /*
     *@include translateY(-100%);
     *@include single-transition(transform, 0.3s, ease-in-out);
     */
    @include opacity(0);
    @include single-transition(opacity, 0.3s, ease-in-out);
  }

  .js.opacity .show-search & {
    overflow: visible;

    /*
     *@include translateY(0);
     */
    @include opacity(1);
  }

  #search-wrapper {
    overflow: hidden;
    max-height: 0;
    padding: 0;
    height: 10000px;

    @include transition-property(max-height, padding);
    @include transition-duration(0s);
    @include transition-delay(0.3s);

    .show-search & {
      max-height: 10000px;
      padding: $vert-gutter * 5 $vert-gutter;

      @include transition-property(max-height, padding);
      @include transition-duration(0s);
      @include transition-delay(0s);
    }

    .logo-small {
      width: 100px;
    }

    #search-field {
      position: relative;
      padding-top: 10px;
      width: 60%;

      .search-glass {
        position: absolute;
        top: 40px;
        left: 10px;
      }

      input[type='text'] {
        padding-left: 50px;
        border: none;
        box-shadow: none;
        width: 100%;
        height: 80px;

        @include h2;

        margin-top: 0;
        margin-bottom: 0;
        color: $page-title-color;
      }

      #start-searching {
        position: absolute;
        right: 0;
        top: 10px;
        height: 80px;
        border: none;
        box-shadow: none;
        width: 80px;

        @include h2;

        color: white;
        background: $page-title-color;
        margin-bottom: 0;
        margin-top: 0;
      }

      #advanced-search {
        position: absolute;
        right: 95px;
        top: 42px;
      }
    }

    #search-results {
      width: 60%;
      background: rgba(255, 255, 255, 0.6);

      .loading {
        margin: 0 auto;
        width: 32px;
        padding-top: $vert-gutter * 2;
        padding-bottom: $vert-gutter * 2;
      }

      .result {
        padding-top: $vert-gutter / 8;
        padding-bottom: $vert-gutter / 8;
        padding-left: 50px;
        padding-right: 80px;

        @include single-transition(background-color, 0.05s, ease, 0s);

        .category,
        .title {
          h3 {
            margin-top: 0;
            margin-bottom: 0;
            line-height: 26px;
            font-weight: 100;
          }
        }

        .category {
          h3 {
            .in {
              color: #cccccc;
              text-transform: lowercase;
            }

            color: $link-color;
          }
        }

        &.active {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

.scale-container {
  background: white;

  @include single-transition(transform, 0.4s, ease-in-out);

  .show-search & {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
}

/*
 * Language switcher
 ****/
.language-wrapper {
  float: left;

  #current-lang,
  .language-switcher-locale-url {
    a {
      text-transform: uppercase;
      font-weight: bold;
      color: $page-title-color;
      font-size: $h4-font-size;

      &:hover {
        color: $link-color;
      }
    }
  }

  #current-lang {
    margin-right: $vert-gutter / 3;
    float: left;
  }

  .language-switcher-locale-url {
    top: 0;
    display: none;
    z-index: 40;
    margin: 0;

    @include horizontal-list;

    margin: 0;

    & > li {
      margin-right: 1em;
    }
  }

  .lang-open & {
    margin: -10px 0 0 -10px;
    padding: 10px;
    background: white;

    @include box-shadow(00005px $body-font-color);

    .language-switcher-locale-url {
      display: block;
    }
  }
}

.secondary-menu {
  .lang-open & {
    display: none;
  }
}
