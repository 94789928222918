.region-highlighted{
  #home-slides{
    background-color: #000000;
    // position: absolute;
    // left: 0px;
    // right: 0px;
    // top: 0px;
    // z-index: 0;

    .slide-copy {
      position: absolute;
      right: 0;
      top: 0;
      left: 0;
      bottom: 0;
      max-width: 100%;
      padding: 0 5%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &.has-alt-layout {
        justify-content: flex-start;
      }

      .slide-inner {
        max-width: 35%;
        background-color: rgba(0,0,0,0.6);
        padding: 50px;
      }

      h2 {
        margin-top: 0;
        margin-bottom: 15px;
        font-size: 26px;
        line-height: 31px;
      }

      h3 {
        
        margin-top: ($vert-gutter/2);
        margin-bottom: 0;
        color: #ffffff;
      }

      .slide-body {
        font-size: 16px;
        margin-top: 20px;
        color: #ffffff;
        a {
          background: transparent;
          border: none;
          display: inline;
          padding: 0;
          text-transform: none;
          font-size: 15px;
          font-family: "ProximaNovaA-Regular";
          &:after {
            display: none;
          }
        }
      }

      a {
        @include border-style-button;
        margin-top: $vert-gutter;
        padding: 10px 80px 7px 12px;
        font-family: "TradeGothicLTStd-Extended";
        font-size: 15px;
      }
    }

    .slides {
      li {
        padding: 0;
        margin: 0;
      }
    }

    .slick-slide {
      background: #ccc;

      img.background{
        /* Set rules to fill background */
        min-height: 100%;
        display: block;

        /* Set up proportionate scaling */
        width: 100%;
        height: auto;

        /* Seet up positioning */
        position: relative;
        top: 0;
        left: 0;
        min-width: 1400px;
        left: 50%;
        margin-left: -700px;   /* 50% */
      }
    }
  }
}

.home-slides-nav {
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  .slick-prev,
  .slick-next {
    box-shadow: none;
    background: none;
    border: none;
    color: #ffffff;

    &:before {
      position: relative;
      top: -2px;
      font-size: 42px;
      line-height: 30px;
    }
  }

  .slick-prev {
    order: 1;
  }

  .slick-dots {
    order: 2
  }

  .slick-next {
    order: 3;
  }

  .slick-dots {
    display: block;
    width: auto;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    li {
      position: relative;
      display: inline-block;
      width: auto;
      height: auto;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;

      button {
        font-size: 0;
        line-height: 0;
        width: 30px;
        height: 30px;
        padding: 0;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: none;
        border: 1px solid transparent;
        border-radius: 50%;
        transition: all 0.2s ease-out;

        &:hover,
        &:focus {
            outline: none;
            border-color: #ffffff;
        }

        span {
          border: 1px solid $yellow;
          padding: 2px;
          display: inline-block;
          border-radius: 50%;
        }

        // &:hover:before,
        // &:focus:before {
        //     opacity: 1;
        // }
        
        // &:before {
        //     font-size: 6px;
        //     line-height: 20px;
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 20px;
        //     height: 20px;
        //     content: none;
        //     text-align: center;
        //     opacity: .25;
        //     border-radius: 50%;
        //     color: black;
        //     -webkit-font-smoothing: antialiased;
        //     -moz-osx-font-smoothing: grayscale;
        // }
      }
    }

    li.slick-active {
      button {
        border-color: #ffffff;
      }
    }
  }
  .slick-dots li.slick-active button:before {
      opacity: .75;
      color: black
  }
}
