.front{
  #worldwide{
    @include breakpoint($tablet-portrait-min){
      min-height: 904px;
      margin-top: -55px;
    }

    .copy{
      text-align: left;

      @include breakpoint($tablet-portrait-min){
        padding-top: 600px;
        max-width: 960px;
      }
    }
  }
}
